import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { DateTime } from "luxon";

const KmChart = ({ dataToReverse }) => {
  console.log("dataToReverse", dataToReverse);
  var graphData = JSON.parse(JSON.stringify(dataToReverse)).reverse();

  var data = graphData.map(d => {
    return {
      date: DateTime.fromJSDate(new Date(d.createdAt)).toFormat("dd/MM/yyyy"),
      km: d.km,
      releve: d.releve
    };
  });

  return (
    <ResponsiveContainer width="100%" height={525}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="km" stroke="#ff7300" yAxisId={0} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default KmChart;
