import axios from "axios";
import jwt_decode from "jwt-decode";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { AUTH_TYPES } from "../reducers/authReducers";

export const authActions = (state, dispatch) => {
  function loginUser(userData, recaptchaRef) {
    dispatch({ type: AUTH_TYPES.LOAD_CURRENT_USER, payload: true });
    // fetch api
    // recaptchaRef.current.reset();
    axios
      .post(api_url + "auth/login", userData)
      .then(res => {
        // Save to localStorage
        const { token } = res.data;
        // Set token to ls
        localStorage.setItem("jwtToken", token);
        // Set token to Auth header
        tools.setAuthToken(token);
        // Set current user
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: jwt_decode(token)
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          AUTH_TYPES.LOAD_CURRENT_USER
        );
      });
  }

  function logoutUser() {
    // Remove token from localStorage
    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    tools.setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch({ type: AUTH_TYPES.DELETE_CURRENT_USER, payload: {} });
    tools.filterAlert("info", "Vous avez été correctement déconnecté.");
  }

  return {
    loginUser,
    logoutUser
  };
};
