import React from 'react';
import useStore from '../../../context/useStore';
import { DateTime } from 'luxon';
import {
    PDFViewer,
    Page,
    Text,
    Image,
    View,
    Document,
    StyleSheet,
    Font,
} from '@react-pdf/renderer';

Font.register({
    family: 'Lato Bold',
    src: `/Lato-Bold.ttf`,
});
const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 15,
    },
    leftColumn: {
        flexDirection: 'column',
        width: 400,
    },
    textSmall: {
        fontSize: 9,
    },
    textXSmall: {
        fontSize: 7,
        paddingBottom: 2,
    },
    textLib: {
        fontSize: 9,
        fontFamily: 'Lato Bold',
        paddingBottom: 1,
    },
    detailLeftColumn: {
        flexDirection: 'column',
        width: '10%',
    },
    detailRightColumn: {
        flexDirection: 'column',
        textAlign: 'left',
        width: '40%',
    },
    detailLeftColumnWide: {
        flexDirection: 'column',
        textAlign: 'left',
        width: '25%',
    },
    detailRightColumnWide: {
        flexDirection: 'column',
        textAlign: 'left',
        width: '25%',
    },
    tableCell: {
        flexDirection: 'column',
        width: '50%',
        textAlign: 'left',
        padding: 2,
    },

    tableCellCenter: {
        flexDirection: 'column',
        width: '50%',
        textAlign: 'center',
        justifyContent: 'space-around',

        padding: 2,
    },
    tableSeparator: {
        flexDirection: 'column',
        width: '1px',
        backgroundColor: 'black',
    },
});

const QuotationPdf = ({ quotation, client, date }) => {
    const [state, dispatch] = useStore();
    var constantData = state.constant.items;
    var user = state.auth.user;
    var vehicle = quotation.vehicle;

    function formatNumber(num) {
        console.log(num);
        var n = num + '';
        var nf = num;
        n = n.split('.');
        if (n[1] && n[1].length == 1) {
            nf = n[0] + '.' + n[1] + '0';
        }
        return nf;
    }

    return (
        <PDFViewer style={{ width: '100%', height: '100%', border: 0 }}>
            <Document>
                <Page size="A4">
                    <View style={{ flexDirection: 'column', width: '100%' }}>
                        <Image src="/header_pdf.png" style={{ width: '100%' }} />
                    </View>
                    <View style={styles.container}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flexDirection: 'column', width: '30%' }}>
                                <Image src="/logo.png" style={{ width: '100%' }} />
                            </View>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    width: '70%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontSize: 11,
                                        padding: 10,
                                        textDecoration: 'underline',
                                    }}
                                >
                                    CONDITIONS PARTICULIERES DE LOCATION LONGUE DUREE DE VEHICULE A
                                    MOTEUR
                                </Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    width: '50%',
                                    paddingTop: 10,
                                }}
                            >
                                <Text style={{ fontSize: 10, textDecoration: 'underline' }}>
                                    Entre d'une part, le locataire :
                                </Text>
                                <Text style={{ fontSize: 9, paddingTop: 5 }}>{client.name}</Text>
                                {client.address != '' && (
                                    <Text style={{ fontSize: 9 }}>{client.address}</Text>
                                )}
                                {client.city != '' && (
                                    <Text style={{ fontSize: 9 }}>{client.city}</Text>
                                )}
                                {client.siret != '' && (
                                    <Text style={{ fontSize: 9 }}>SIRET : {client.siret}</Text>
                                )}
                            </View>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    width: '50%',
                                    paddingTop: 10,
                                }}
                            >
                                <Text style={{ fontSize: 10, textDecoration: 'underline' }}>
                                    Et d'autre part, le loueur :
                                </Text>
                                <Text style={{ fontSize: 9, paddingTop: 5 }}>SAS LEASECAR</Text>
                                <Text style={{ fontSize: 9 }}>103 Avenue Jacques Prévert</Text>
                                <Text style={{ fontSize: 9 }}>97420 LE PORT</Text>
                            </View>
                        </View>
                        <Text
                            style={{
                                fontSize: 12,
                                paddingTop: 10,
                                paddingBottom: 5,
                                textDecoration: 'underline',
                            }}
                        >
                            {' '}
                            Devis établi le {DateTime.fromISO(date).toFormat('dd/MM/yyyy')}
                        </Text>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ fontSize: 10, paddingTop: 10, paddingBottom: 5 }}>
                                Il a été convenu que le locataire loue à la SAS LEASECAR, aux
                                conditions générales jointes et qu’il reconnait accepter, le
                                véhicule ci-après désigné :
                            </Text>
                        </View>
                        <View
                            style={{
                                borderWidth: 1,
                                borderColor: 'black',
                                padding: 2,
                            }}
                        >
                            <View
                                style={{
                                    flexDirection: 'row',
                                }}
                            >
                                <View style={styles.detailLeftColumn}>
                                    <Text style={styles.textLib}>Véhicule</Text>
                                </View>
                                <View style={styles.detailRightColumn}>
                                    <Text style={styles.textSmall}>
                                        {vehicle.brand.name + ' ' + vehicle.brandModel.name}
                                    </Text>
                                </View>
                                <View style={styles.detailLeftColumn}>
                                    <Text style={styles.textLib}>Type</Text>
                                </View>
                                <View style={styles.detailRightColumn}>
                                    <Text style={styles.textSmall}>
                                        {
                                            constantData.VEHICLE_TYPES.find(
                                                (t) => t.id == vehicle.type
                                            ).name
                                        }
                                    </Text>
                                </View>
                            </View>

                            <View style={{ paddingTop: 2, flexDirection: 'row' }}>
                                <View style={styles.detailLeftColumn}>
                                    <Text style={styles.textLib}>Finition</Text>
                                </View>
                                <View style={styles.detailRightColumn}>
                                    <Text style={styles.textSmall}>
                                        {
                                            vehicle.brandModel.finishes.find(
                                                (f) => f._id == vehicle.finish
                                            ).name
                                        }
                                    </Text>
                                </View>
                                <View style={styles.detailLeftColumn}>
                                    <Text style={styles.textLib}>CO2</Text>
                                </View>
                                <View style={styles.detailRightColumn}>
                                    <Text style={styles.textSmall}>{vehicle.co2} g/km</Text>
                                </View>
                            </View>
                            <View style={{ paddingTop: 2, flexDirection: 'row' }}>
                                <View style={styles.detailLeftColumn}>
                                    <Text style={styles.textLib}>Options</Text>
                                </View>
                                <View style={styles.detailRightColumn}>
                                    <Text style={styles.textSmall}>
                                        {vehicle.optionsNotIncluded.map(
                                            (f, k) =>
                                                f.name +
                                                (k < vehicle.optionsNotIncluded.length - 1
                                                    ? ', '
                                                    : '')
                                        )}
                                    </Text>
                                </View>
                                <View style={styles.detailLeftColumn}>
                                    <Text style={styles.textLib}>QUANTITÉ</Text>
                                </View>
                                <View style={styles.detailRightColumn}>
                                    <Text style={styles.textSmall}>{quotation.quantity}</Text>
                                </View>
                            </View>
                        </View>
                        <View>
                            <Text style={{ fontSize: 10, paddingTop: 10, paddingBottom: 5 }}>
                                Aux conditions particulières ci-dessous énoncées :
                            </Text>
                        </View>
                        <View
                            style={{
                                borderWidth: 1,
                                borderColor: 'black',
                                padding: 2,
                            }}
                        >
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.detailLeftColumnWide}>
                                    <Text style={styles.textLib}>Durée de la location</Text>
                                </View>
                                <View style={styles.detailRightColumnWide}>
                                    <Text style={styles.textSmall}>{quotation.dur} mois</Text>
                                </View>
                                <View style={styles.detailLeftColumnWide}>
                                    <Text style={styles.textLib}>Kilométrage total autorisé</Text>
                                </View>
                                <View style={styles.detailRightColumnWide}>
                                    <Text style={styles.textSmall}>{quotation.km} Km</Text>
                                </View>
                            </View>
                            <View style={{ paddingTop: 2, flexDirection: 'row' }}>
                                <View
                                    style={{
                                        flexDirection: 'column',
                                        width: '50%',
                                    }}
                                >
                                    <Text style={styles.textSmall}>
                                        (à compter de la signature du procès-verbal de livraison)
                                    </Text>
                                </View>
                                <View style={styles.detailLeftColumnWide}>
                                    <Text style={styles.textLib}>Kilométrage supplémentaire</Text>
                                </View>
                                <View style={styles.detailRightColumnWide}>
                                    <Text style={styles.textSmall}>
                                        {quotation.repurchaseData.length > 0 &&
                                            quotation.repurchaseData.find(
                                                (r) => r.id == quotation.repurchase
                                            ) &&
                                            formatNumber(
                                                quotation.repurchaseData.find(
                                                    (r) => r.id == quotation.repurchase
                                                ).kmPrice
                                            ) + ' € TTC/KM'}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ marginTop: 10, borderWidth: 1, borderColor: 'black' }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'black',
                                }}
                            >
                                <View style={styles.tableCellCenter}>
                                    <Text style={styles.textLib}>Prestations</Text>
                                </View>
                                <View style={styles.tableSeparator}></View>
                                <View style={styles.tableCellCenter}>
                                    <Text style={styles.textLib}>Loyer mensuel unitaire H.T</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.tableCell}>
                                    <Text style={styles.textLib}>Loyer financier + entretien</Text>
                                </View>
                                <View style={styles.tableSeparator}></View>
                                <View style={styles.tableCellCenter}>
                                    <Text style={styles.textLib}>
                                        {formatNumber(quotation.baseRentHT)} €
                                    </Text>
                                </View>
                            </View>

                            {quotation.replacementVehicleCost > 0 && (
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={styles.tableCell}>
                                        <Text style={styles.textLib}>Véhicules relais</Text>
                                        <Text style={styles.textSmall}>
                                            (véhicule de catégorie {quotation.vehicleCategory})
                                        </Text>
                                    </View>
                                    <View style={styles.tableSeparator}></View>
                                    <View style={styles.tableCellCenter}>
                                        <Text style={styles.textLib}>
                                            {formatNumber(quotation.replacementVehicleCost)} €
                                        </Text>
                                    </View>
                                </View>
                            )}
                            {quotation.insuranceHT != '0' && (
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={styles.tableCell}>
                                        <Text style={styles.textLib}>
                                            Assurance tous risques, assistance
                                        </Text>
                                        <Text style={styles.textSmall}>
                                            (franchise dommages, vol, incendie :{' '}
                                            {quotation.franchiseDommage} € ; bris de glace : 90 €)
                                        </Text>
                                    </View>
                                    <View style={styles.tableSeparator}></View>
                                    <View style={styles.tableCellCenter}>
                                        <Text style={styles.textLib}>
                                            {formatNumber(quotation.insuranceHT)}
                                            {' €'}
                                        </Text>
                                    </View>
                                </View>
                            )}
                            {quotation.insuranceFinLoss != '0' && (
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={styles.tableCell}>
                                        <Text style={styles.textLib}>
                                            Garantie perte financière
                                        </Text>
                                        <Text style={styles.textSmall}></Text>
                                    </View>
                                    <View style={styles.tableSeparator}></View>
                                    <View style={styles.tableCellCenter}>
                                        <Text style={styles.textLib}>
                                            {formatNumber(quotation.insuranceFinLoss)}
                                            {' €'}
                                        </Text>
                                    </View>
                                </View>
                            )}
                            {quotation.nbTires != '0' && (
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={styles.tableCell}>
                                        <Text style={styles.textLib}>Pneumatiques</Text>
                                        <Text style={styles.textSmall}>
                                            ({quotation.nbTires} pneumatiques sont inclus dans le
                                            contrat)
                                        </Text>
                                    </View>
                                    <View style={styles.tableSeparator}></View>
                                    <View style={styles.tableCellCenter}>
                                        <Text style={styles.textLib}>
                                            {quotation.nbTires == '0'
                                                ? 'NON SOUSCRIT'
                                                : formatNumber(
                                                      quotation.benefits.find(
                                                          (b) => b.name == 'Pneus'
                                                      ).priceHT
                                                  )}
                                            {' €'}
                                        </Text>
                                    </View>
                                </View>
                            )}
                            {quotation.benefits.map((b, k) => {
                                if (b.name == 'Pneus') {
                                    return;
                                } else {
                                    return (
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={styles.tableCell}>
                                                <Text style={styles.textLib}>{b.name}</Text>
                                            </View>
                                            <View style={styles.tableSeparator}></View>
                                            <View style={styles.tableCellCenter}>
                                                <Text style={styles.textLib}>
                                                    {formatNumber(b.priceHT)} €
                                                </Text>
                                            </View>
                                        </View>
                                    );
                                }
                            })}

                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderTopWidth: 1,
                                    borderTopColor: 'black',
                                }}
                            >
                                <View style={styles.tableCell}>
                                    <Text style={styles.textLib}>Total HT</Text>
                                </View>
                                <View style={styles.tableSeparator}></View>
                                <View style={styles.tableCellCenter}>
                                    <Text style={styles.textLib}>
                                        {formatNumber(quotation.totalRentHT)} €
                                    </Text>
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.tableCell}>
                                    <Text style={styles.textLib}>TVA à 8,5%</Text>
                                </View>
                                <View style={styles.tableSeparator}></View>
                                <View style={styles.tableCellCenter}>
                                    <Text style={styles.textLib}>
                                        {formatNumber(
                                            Math.round(
                                                (quotation.totalRentTTC - quotation.totalRentHT) *
                                                    100
                                            ) / 100
                                        )}{' '}
                                        €
                                    </Text>
                                </View>
                            </View>

                            <View
                                style={{
                                    flexDirection: 'row',
                                    borderTopWidth: 1,
                                    borderTopColor: 'black',
                                }}
                            >
                                <View style={styles.tableCell}>
                                    <Text style={styles.textLib}>Total TTC</Text>
                                    {quotation.firstRentInput != '0' && (
                                        <Text style={styles.textSmall}>
                                            (1er loyer majoré de la somme de{' '}
                                            {formatNumber(quotation.firstRentInput)} €)
                                        </Text>
                                    )}
                                </View>
                                <View style={styles.tableSeparator}></View>
                                <View style={styles.tableCellCenter}>
                                    <Text style={styles.textLib}>
                                        {formatNumber(quotation.totalRentTTC)} €
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 20 }}>
                            <View style={{ flexDirection: 'column', width: '60%' }}>
                                <Text key={'mentions'} style={styles.textXSmall}>
                                    {quotation.mentions.map((m, k) => m.name + '. ')}
                                </Text>
                                <Text style={{ fontSize: 9, paddingTop: 20 }}>
                                    A{'                            '}, le
                                </Text>
                                <Text style={{ fontSize: 9, paddingTop: 5 }}>
                                    Pour LEASECAR, {user.firstname + ' ' + user.lastname}
                                </Text>
                            </View>
                            <View style={{ flexDirection: 'column', width: '40%' }}>
                                <View
                                    style={{
                                        padding: 5,
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 8,
                                            textAlign: 'right',
                                        }}
                                    >
                                        Pour {client.name},
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: 7,
                                            textAlign: 'right',
                                        }}
                                    >
                                        Signature et cachet, précédés de la mention « lu et
                                        approuvée », du nom et de la qualité du signataire
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'column', width: '100%' }}>
                        <Image src="/footer-leasecar.jpeg" style={{ width: '100%' }} />
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default QuotationPdf;
