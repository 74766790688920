import React, { useEffect } from "react";
import Loader from "../../common/Loader";

import Control from "../../common/Control";

const ValidQuotationsList = ({
  changeQuotation,
  quotations,
  quotationData,
  disabled
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <h1>Devis validés</h1>
        </div>

        {quotationData ? (
          quotationData.map((s, k) => (
            <div className="col-3" key={"vql-" + k}>
              <ul className="list-unstyled" key={"sim" + k}>
                <li>
                  <h4>{s.reference}</h4>
                </li>
                {s.quotations.map((q, kk) => {
                  if (q.status == 2) {
                    return (
                      <li key={"liq" + kk}>
                        <Control
                          type="checkbox"
                          label={q.reference}
                          name={q._id}
                          value={q._id}
                          change={e => changeQuotation(e, q)}
                          disabled={disabled}
                          checked={
                            quotations.find(qs => qs.quotation == q._id)
                              ? true
                              : false
                          }
                        />
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          ))
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default ValidQuotationsList;
