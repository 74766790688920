import React, { useContext, useState } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Loader from "../../common/Loader";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [value, setValue] = useState("");

  const recaptchaRef = React.createRef();
  if (state.auth.isAuthenticated) {
    window.location.href = "/";
  }

  function onChange(value) {
    setValue(value);
  }

  return (
    <div
      className="card m-auto col-6 px-0"
      style={{
        maxWidth: 400,
        position: "relative",
        top: 150,
        boxShadow: "0px 3px 7px -2px grey"
      }}
    >
      <div className="text-center pl-4 pr-4 pt-4 pb-0">
        <img className="img-fluid" src="./logo.png" />
        <h2>MATCH!</h2>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label htmlFor="username">Email</label>
          <input
            type="text"
            className="form-control"
            name="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <small className="form-text text-danger">{state.error.email}</small>
        </div>
        <div className="form-group">
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            className="form-control"
            name="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <small className="form-text text-danger">
            {state.error.password}
          </small>
        </div>
        {/* <ReCAPTCHA
          sitekey="6LdWyqwUAAAAABoC2l7GG9IuExGifnVjwAlYspd6"
          onChange={onChange}
          size="normal"
          ref={recaptchaRef}
        />
        <small className="form-text text-danger">{state.error.recaptcha}</small> */}
      </div>
      <div className="card-footer text-center">
        {state.auth.isLoading ? (
          <Loader />
        ) : (
          (
            <button
              type="button"
              className="btn btn-primary m-auto"
              onClick={() =>
                actions.loginUser(
                  {
                    email,
                    password,
                    // value
                  },
                  // recaptchaRef
                )
              }
            >
              Connexion
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default Login;
