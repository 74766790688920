import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import Control from "../../common/Control";
import vehicleTools from "../../../helpers/vehicleTools";
import contractTools from "../../../helpers/contractTools";
import tools from "../../../helpers/tools";
import CalcVRBox from "../../common/CalcVRBox";
import CalcMaintenanceBox from "../../common/CalcMaintenanceBox";
import InputGroupWithResetValue from "../../common/InputGroupWithResetValue";
import Loader from "../../common/Loader";
import { withRouter } from "react-router-dom";
import GenerateContractModal from "./GenerateContractModal";
import ItemList from "../../common/ItemList";
import DynamicList from "../../common/DynamicList";
import { toast } from "react-toastify";

const ContractFinalValues = ({
  quotation,
  rlVehicleChange,
  rlChange,
  index,
  setQuotationStatus,
  deleteQuotation,
  match,
  setQuotationOpened,
  toggleOverlay,
  overlayOpened,
  navQuotation,
  totalQuotation,
  hideKeys,
  openPdf,
  setQuotationSelected,
  isCreate,
  duplicateQuotation,
  changeLineOrder,
  tableWidth,
}) => {
  const [state, dispatch] = useStore();
  const [firstLoad, setFirstLoad] = useState(true);
  const [overlayHeight, setOverlayHeight] = useState(0);
  const [mentionSel, setMentionSel] = useState("");
  var tireData = state.tire.items;

  var constantData = state.constant.items;
  var { vehicle } = quotation;
  const [locks, setLocks] = useState({
    insuranceHT: quotation._id ? true : false,
    insuranceFinLoss: quotation._id ? true : false,
  });
  let parameters = [];
  let funding = [
    {
      name: "financeRate",
      symbol: "%",
      label: "Taux de financement",
      defaultValue: constantData.FINANCE_RATE_DEFAULT,
    },
    { name: "firstRentInput", label: "Apport 1er Loyer" },
  ];

  var basePrices = vehicleTools.calcVehiclePrices(vehicle, true);
  var pricePublicTTC = vehicle.priceTTC;
  var investAmtHT = quotation.investAmtHT
    ? quotation.investAmtHT
    : vehicleTools.calcInvestAmtHT(quotation);

  var priceNetTTC = basePrices.priceTotalTTC;
  var financeRentCalc = vehicleTools.calcFinanceRent(quotation);
  var insuranceHTCalc = vehicleTools.calcInsuranceHT(quotation);
  var insuranceFinLossCalc = vehicleTools.calcInsuranceFinLoss(quotation);
  var totalRentHTCalc = vehicleTools.calcTotalRentHTCalc(quotation);
  var totalRentTTCCalc = vehicleTools.calcPriceTTC(quotation.totalRentHT);
  var applicationFeeCalc = vehicleTools.calcApplicationFee(
    investAmtHT,
    constantData.APPLICATION_FEES
  );
  var baseRentHT =
    financeRentCalc +
    parseFloat(quotation.maintenanceValueFinal) +
    parseFloat(quotation.marginTTC);
  baseRentHT = isNaN(baseRentHT) ? 0 : Math.round(baseRentHT * 100) / 100;
  var totalBenefits = 0;
  quotation.benefits.forEach((b) => {
    totalBenefits += parseFloat(b.priceHT);
  });

  useEffect(() => {
    if (quotation._id) {
      setLocks({ insuranceHT: true, insuranceFinLoss: true });
    } else {
      setLocks({ insuranceHT: false, insuranceFinLoss: false });
    }
  }, [quotation]);
  useEffect(() => {
    if (
      (quotation.financeRent === "0" ||
        quotation.financeRent != financeRentCalc) &&
      !firstLoad
    ) {
      rlChange({ target: { name: "financeRent", value: financeRentCalc } });
    }
  }, [financeRentCalc]);
  useEffect(() => {
    if (
      (quotation.insuranceHT === "0" ||
        quotation.insuranceHT != insuranceHTCalc) &&
      !locks.insuranceHT &&
      !firstLoad
    ) {
      rlChange({ target: { name: "insuranceHT", value: insuranceHTCalc } });
    }
  }, [insuranceHTCalc]);
  useEffect(() => {
    if (
      (quotation.insuranceFinLoss === "0" ||
        quotation.insuranceFinLoss != insuranceFinLossCalc) &&
      !locks.insuranceFinLoss &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "insuranceFinLoss", value: insuranceFinLossCalc },
      });
    }
  }, [insuranceFinLossCalc]);
  useEffect(() => {
    if (
      (quotation.totalRentHT === "0" ||
        quotation.totalRentHT != totalRentHTCalc) &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "totalRentHT", value: totalRentHTCalc },
      });
    }
  }, [totalRentHTCalc]);
  useEffect(() => {
    if (
      (quotation.totalRentTTC === "0" ||
        quotation.totalRentTTC != totalRentTTCCalc) &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "totalRentTTC", value: totalRentTTCCalc },
      });
    }
  }, [totalRentTTCCalc]);
  useEffect(() => {
    if (
      (quotation.applicationFee === "0" ||
        quotation.applicationFee != applicationFeeCalc) &&
      !firstLoad
    ) {
      rlChange({
        target: { name: "applicationFee", value: applicationFeeCalc },
      });
    }
  }, [applicationFeeCalc]);

  useEffect(() => {
    if (
      (quotation.baseRentHT === "0" || quotation.baseRentHT != baseRentHT) &&
      !firstLoad
    ) {
      console.log("BASE RENT");
      rlChange({
        target: { name: "baseRentHT", value: baseRentHT },
      });
    }
  }, [baseRentHT]);

  if (quotation.vehicle.pricePublicTTC === "0") {
    rlVehicleChange({
      target: { name: "pricePublicTTC", value: pricePublicTTC },
    });
  }
  if (
    quotation.vehicle.investAmtHT === "0" ||
    quotation.vehicle.investAmtHT != investAmtHT
  ) {
    rlVehicleChange({ target: { name: "investAmtHT", value: investAmtHT } });
  }
  if (
    quotation.vehicle.priceNetTTC === "0" ||
    quotation.vehicle.priceNetTTC != priceNetTTC
  ) {
    rlVehicleChange({ target: { name: "priceNetTTC", value: priceNetTTC } });
  }
  if (quotation.baseRentHT === "0" || quotation.baseRentHT != baseRentHT) {
    rlChange({ target: { name: "baseRentHT", value: baseRentHT } });
  }
  if (quotation.marginTTCRate > 0 && quotation.marginTTC == 0) {
    rlChange({
      target: { name: "marginTTCRate", value: quotation.marginTTCRate },
    });
  }

  useEffect(() => {
    setFirstLoad(false);
    setOverlayHeight(document.getElementById("trrl" + index).clientHeight);
  });

  function resetDefault(v, d) {
    rlChange({ target: { name: v, value: d } });
  }
  function addMention() {
    quotation.mentions.push({ name: mentionSel });
    rlChange({ target: { name: "mentions", value: quotation.mentions } });
  }
  function updateLocks(name) {
    console.log(name);
    var ulocks = JSON.parse(JSON.stringify(locks));
    ulocks[name] = !ulocks[name];
    setLocks(ulocks);
    console.log(locks);
  }

  var shouldShowOverlay = overlayOpened == index && overlayOpened != null;
  var shouldHideRow = overlayOpened != null && overlayOpened != index;

  var style = { position: "relative" };
  var overOverlay = { position: "relative", zIndex: 10 };
  hideKeys.includes(index)
    ? (style.display = "none")
    : (style.display = "table-row");

  var content = (
    <tr
      id={"trrl" + index}
      style={style}
      className={"big-table  " + (shouldShowOverlay ? "tr-front" : "tr-mask ")}
    >
      <td style={overOverlay}>
        <button
          className="btn btn-default"
          title="Remonter dans la liste"
          onClick={() => changeLineOrder("down", index)}
        >
          <i className="fa fa-arrow-up" />
        </button>
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              setQuotationSelected((x) => [...x, quotation]);
            } else {
              setQuotationSelected((x) =>
                x.filter((xx) => xx._id !== quotation._id)
              );
            }
          }}
        />
        <button
          className="btn btn-default"
          title="Descendre dans la liste"
          onClick={() => changeLineOrder("up", index)}
        >
          <i className="fa fa-arrow-down" />
        </button>
      </td>
      <td>
        <button
          style={{ position: "relative" }}
          className="btn btn-success"
          onClick={() => toggleOverlay(index, true)}
        >
          <span
            style={{ position: "absolute", left: -8, top: -8 }}
            className="badge badge-primary"
          >
            {index + 1}
          </span>
          {quotation.reference}
          {quotation.comment && quotation.comment != "" && (
            <span
              style={{ position: "absolute", right: -8, top: -8 }}
              className="badge badge-secondary"
              onClick={(e) => {
                e.stopPropagation();
                toast.info(quotation.comment, { autoClose: 15000 });
              }}
            >
              <i className="fa fa-comment" title={quotation.comment}></i>
            </span>
          )}
        </button>
        <span
          className="badge badge-primary"
          title={quotation.vehicle.optionsNotIncluded
            .map(
              (qopt) =>
                qopt.name +
                " - " +
                qopt.priceHT +
                "€ " +
                (qopt.isInclVR ? " (*VR)" : "")
            )
            .join("\n")}
        >
          Options {quotation.vehicle.optionsNotIncluded.length}
        </span>
      </td>
      <td style={overOverlay}>
        {match.params.id != "create" && quotation._id ? (
          <>
            {quotation.status != 2 && quotation.status != 3 ? (
              <>
                <button
                  className="btn btn-primary btn-sm mr-2"
                  onClick={() => setQuotationStatus(index, 2)}
                >
                  Valider ce devis
                </button>
                <button
                  className="btn btn-success btn-sm mr-2"
                  onClick={openPdf}
                >
                  <i className="fa fa-file-pdf mr-2" />
                  Voir les CP
                </button>
                <button
                  className="btn btn-danger btn-sm mr-2"
                  onClick={deleteQuotation}
                >
                  <i className="fa fa-times" />
                </button>
                <button
                  title="dupliquer ce devis"
                  className="btn bt-default btn-sm"
                  onClick={() => duplicateQuotation(index)}
                >
                  <i className="fa fa-copy" />
                </button>
              </>
            ) : quotation.status == 2 ? (
              <>
                <span className="text-success btn-sm mr-2">Devis validé</span>
                <button
                  className="btn btn-default btn-sm mr-2"
                  onClick={() => setQuotationStatus(index, 1)}
                >
                  Repasser en brouillon
                </button>
                <button
                  className="btn btn-success btn-sm mr-2"
                  onClick={openPdf}
                >
                  <i className="fa fa-file-pdf mr-2" />
                  Voir les CP
                </button>
                <button
                  type="button"
                  className="btn btn-success btn-sm mr-2"
                  data-toggle="modal"
                  data-target="#genContractModal"
                  onClick={setQuotationOpened}
                >
                  Générer des contrats{" "}
                  <span className="badge badge-primary">
                    {parseInt(quotation.quantity) - quotation.contracts.length}
                  </span>
                </button>
              </>
            ) : (
              <>
                <span
                  style={{ fontSize: 14 }}
                  className="text-danger py-1 mr-2"
                >
                  <span className="badge badge-danger mr-2">
                    {quotation.contracts.length} Contrats
                  </span>
                </span>
                {quotation.contracts.length < quotation.quantity && (
                  <button
                    type="button"
                    className="btn btn-success btn-sm mr-2"
                    data-toggle="modal"
                    data-target="#genContractModal"
                    onClick={setQuotationOpened}
                  >
                    Générer des contrats{" "}
                    <span className="badge badge-primary">
                      {parseInt(quotation.quantity) -
                        quotation.contracts.length}
                    </span>
                  </button>
                )}
                {/* <Link
                  style={{ zIndex: 2 }}
                  to={"/contracts/" + quotation.contract}
                  className="btn btn-success btn-sm mr-2"
                  onClick={() => setQuotationStatus(index, 1)}
                >
                  Voir les CP
                </Link> */}
                <button
                  className="btn btn-success btn-sm mr-2"
                  onClick={openPdf}
                >
                  <i className="fa fa-file-pdf mr-2" />
                  Voir les CP
                </button>
              </>
            )}
          </>
        ) : (
          <>
            Enregistrez la simulation
            <button
              className="btn btn-danger btn-sm mr-2"
              onClick={deleteQuotation}
            >
              <i className="fa fa-times" />
            </button>
          </>
        )}
      </td>
      <td>
        <Control
          type="number"
          name="quantity"
          value={quotation.quantity}
          change={rlChange}
        />
      </td>
      <td>
        <Control
          size="small"
          disabled={true}
          name="priceHT"
          value={Math.round(pricePublicTTC * 100) / 100}
          change={rlVehicleChange}
          suffix="€"
          inputClassName="text-right"
        />
        {vehicle.optionsNotIncluded.length > 0 && (
          <span style={{ color: "#9a9a9a" }}>
            Options :
            {Math.round(
              vehicle.optionsNotIncluded.reduce(
                (a, b) => a + b.priceHT * (b.isInclTVA ? 1.085 : 1),
                0
              ) * 100
            ) / 100}
            €
          </span>
        )}
      </td>
      <td>
        <div className="input-group">
          <input
            className="form-control text-center"
            type="text"
            name="discountPct"
            value={vehicle.discountPct}
            onChange={rlVehicleChange}
          />
          <div className="input-group-append">
            <span className="input-group-text">%</span>
          </div>
        </div>
        <div className="input-group">
          <input
            className="form-control text-center"
            type="text"
            name="discount"
            value={vehicle.discount}
            onChange={rlVehicleChange}
          />
          <div className="input-group-append">
            <span className="input-group-text">€</span>
          </div>
        </div>
      </td>
      <td>
        <Control
          value={priceNetTTC ? Math.round(priceNetTTC * 100) / 100 : 0}
          disabled={true}
          suffix="€"
          inputClassName="text-right"
        />
      </td>
      <td>
        <Control
          value={investAmtHT}
          disabled={true}
          suffix="€"
          inputClassName="text-right"
        />
      </td>
      <td>
        {/* <InputGroupWithResetValue
          props={{
            name: "marginTTCRate",
            symbol: "%",
            inputClassName: "text-center"
          }}
          data={quotation}
          resetDefault={resetDefault}
          inputChange={rlChange}
          error={state.error["marginTTCRate"]}
        /> */}
        <Control
          name="marginTTCRate"
          value={quotation.marginTTCRate}
          type="number"
          change={rlChange}
          error={state.error}
          defferChange={true}
          suffix={"%"}
        />
        <Control
          name="marginTTC"
          value={quotation.marginTTC}
          type="number"
          change={rlChange}
          error={state.error}
          defferChange={true}
          suffix={"€"}
        />
        {/* <InputGroupWithResetValue
          props={{
            name: "marginTTC",
            symbol: "€",
            inputClassName: "text-center"
          }}
          data={quotation}
          resetDefault={resetDefault}
          inputChange={rlChange}
          error={state.error["marginTTC"]}
        /> */}
      </td>
      <td>
        <Control
          value={baseRentHT}
          disabled={true}
          suffix="€"
          inputClassName="text-right"
        />
        {/* <InputGroupWithResetValue
          props={{
            name: "financeRent",
            defaultValue: financeRentCalc
          }}
          data={quotation}
          disabled={true}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value
              }
            })
          }
          inputChange={rlChange}
        /> */}
      </td>
      <td>
        <Control
          name="replacementVehicleCost"
          suffix={"€"}
          change={rlChange}
          value={quotation.replacementVehicleCost}
        />
      </td>
      <td style={{ position: "relative" }}>
        {/* <InputGroupWithResetValue
          props={{
            name: "insuranceHT",
            defaultValue: insuranceHTCalc,
            symbol: "€"
          }}
          data={quotation}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value
              }
            })
          }
          noBtn={true}
          inputChange={rlChange}
        /> */}
        <Control
          name="insuranceHT"
          value={quotation.insuranceHT}
          change={rlChange}
          suffix="€"
          inputClassName="text-center"
        />
        {locks.insuranceHT ? (
          <i
            title="Dévérouiller cette valeur pour permettre son calcul automatique"
            className="fa fa-lock input-lock"
            style={{ color: "#ff401b" }}
            onClick={() => updateLocks("insuranceHT")}
          ></i>
        ) : (
          <i
            title="Vérouiller cette valeur pour empêcher son calcul automatique"
            className="fa fa-lock-open input-lock"
            style={{ color: "#4f79ff" }}
            onClick={() => updateLocks("insuranceHT")}
          ></i>
        )}
      </td>
      <td style={{ position: "relative" }}>
        {/* <InputGroupWithResetValue
          props={{
            name: "insuranceFinLoss",
            defaultValue: insuranceFinLossCalc,
            symbol: "€"
          }}
          data={quotation}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value
              }
            })
          }
          noBtn={true}
          inputChange={rlChange}
        /> */}
        <Control
          name="insuranceFinLoss"
          value={quotation.insuranceFinLoss}
          change={rlChange}
          suffix="€"
          inputClassName="text-center"
        />
        {locks.insuranceFinLoss ? (
          <i
            title="Dévérouiller cette valeur pour permettre son calcul automatique"
            className="fa fa-lock input-lock"
            style={{ color: "#ff401b" }}
            onClick={() => updateLocks("insuranceFinLoss")}
          ></i>
        ) : (
          <i
            title="Vérouiller cette valeur pour empêcher son calcul automatique"
            className="fa fa-lock-open input-lock"
            style={{ color: "#4f79ff" }}
            onClick={() => updateLocks("insuranceFinLoss")}
          ></i>
        )}
      </td>
      <td>
        <Control
          name="totalBenefits"
          disabled={true}
          value={totalBenefits}
          suffix="€"
          inputClassName="text-right"
        />
      </td>
      <td>
        <Control
          inputClassName="text-right"
          name="totalRentHT"
          value={totalRentHTCalc}
          disabled={true}
          change={rlChange}
          suffix="€"
        />
      </td>

      <td>
        <Control
          inputClassName="text-right"
          name="totalRentTTC"
          disabled={true}
          value={totalRentTTCCalc}
          suffix="€"
        />
      </td>
      <td
        style={{ height: overlayHeight, width: tableWidth + "%" }}
        className={
          "backdrop " +
          (shouldHideRow ||
          (quotation.contracts &&
            quotation.contracts.length >= quotation.quantity)
            ? "d-block"
            : "d-none")
        }
      />
    </tr>
  );

  return (
    <>
      {content}
      <div
        style={{
          position: "fixed",
          background: "white",
          bottom: 0,
          left: 0,
          zIndex: "99",
          width: "100%",
          boxShadow: "0 0 20px -6px grey",
          maxHeight: "100%",
          overflowX: "scroll",
          paddingBottom: "20%",
        }}
        id={"col" + index}
        className={
          "custom-panel " + (shouldShowOverlay ? "d-block fadeIn" : "d-none")
        }
      >
        <div className="col-12 text-center p-2 overlay-header no-print">
          {index > 0 && (
            <button
              className="btn btn-secondary"
              onClick={() => navQuotation(false)}
            >
              <i className="fa fa-chevron-left" />
            </button>
          )}
          {quotation.reference}
          {index < totalQuotation - 1 && (
            <button
              className="btn btn-secondary"
              onClick={() => navQuotation(true)}
            >
              <i className="fa fa-chevron-right" />
            </button>
          )}
          <button
            className="btn btn-danger close-btn"
            onClick={() => toggleOverlay(index, false)}
          >
            <i className="fa fa-times" />
          </button>
        </div>

        <table className="table ov-table-print" id="quotable">
          <thead className="head-sm">
            <tr>
              <th>Sélect.</th>
              <th>Véhicule</th>
              <th>Actions</th>
              <th>Quantité</th>
              <th>Prix public TTC (hors CG/BM)</th>
              <th>Remise sur prix TTC</th>
              <th>Prix net TTC</th>
              <th>Montant invest HT</th>
              <th>Taux marge</th>
              <th>Loyer de base HT</th>
              <th>Véhicule relais</th>
              <th>Assurance HT</th>
              <th>Assurance PF</th>
              <th>Autres prestations</th>
              <th>Total loyer HT</th>
              <th>Total loyer TTC</th>
            </tr>
          </thead>
          <tbody>{content}</tbody>
        </table>

        <div
          className="card-body pt-0"
          style={{ borderBottom: "1px solid #dfdfdf" }}
        >
          <div className="row simulation">
            <div className="col-2 p-2">
              <h4>Financement</h4>
              {constantData.isLoading ? (
                <Loader />
              ) : (
                funding.map((x, k) => (
                  <InputGroupWithResetValue
                    key={k}
                    props={x}
                    data={quotation}
                    resetDefault={resetDefault}
                    inputChange={rlChange}
                    error={state.error[x.name]}
                  />
                ))
              )}
              <InputGroupWithResetValue
                props={{
                  name: "applicationFee",
                  defaultValue: applicationFeeCalc,
                  label: "Frais de dossier",
                  symbol: "€",
                }}
                data={quotation}
                resetDefault={(name, value) =>
                  rlChange({
                    target: {
                      name,
                      value,
                    },
                  })
                }
                inputChange={rlChange}
              />
              {constantData.isLoading ? (
                <Loader />
              ) : (
                <>
                  <Control
                    label="Loyer financier"
                    disabled={true}
                    name="financeRent"
                    value={financeRentCalc}
                    suffix="€"
                  />
                </>
              )}
            </div>
            <div className="col-2 p-2">
              <h4>Valeur de rachat</h4>
              <CalcVRBox
                quotation={quotation}
                rlChange={rlChange}
                num={index + 1}
              />
            </div>
            <div className="col-2 p-2">
              <h4>Coût de maintenance</h4>
              <CalcMaintenanceBox
                quotation={quotation}
                rlChange={rlChange}
                num={index + 1}
              />
            </div>
            <div className="col-2 p-2">
              <h4>Prestations</h4>
              <Control
                label="Nombre de pneus"
                name="nbTires"
                type="number"
                change={rlChange}
                value={quotation.nbTires}
              />
              <Control
                label={
                  "Prix d'un pneu (par défaut : " +
                  (tireData.find((t) => t.id == quotation.vehicle.tire)
                    ? tireData.find((t) => t.id == quotation.vehicle.tire).price
                    : 0) +
                  "€)"
                }
                name="customTirePrice"
                type="number"
                change={rlChange}
                value={quotation.customTirePrice}
              />
              <Control
                label="Catégorie du véhicule"
                name="vehicleCategory"
                value={quotation.vehicleCategory}
                change={rlChange}
              />
              <Control
                label="Franchise dommages, vol, incendie"
                value={quotation.franchiseDommage}
                change={rlChange}
                name="franchiseDommage"
                suffix={"€"}
              />
              <DynamicList
                uniqueKey={quotation._id}
                items={quotation.benefits}
                element="benefits"
                fields={[
                  { name: "name", type: "text", label: "Nature" },
                  {
                    name: "priceHT",
                    type: "number",
                    label: "Prix HT",
                    suffix: "€",
                  },
                ]}
                change={rlChange}
                btnLabel="Ajouter une prestation"
              />
            </div>
            <div className="col-4 p-2">
              <h4 className="mt-3">Mentions</h4>
              <DynamicList
                uniqueKey={quotation._id}
                items={quotation.mentions}
                element="mentions"
                change={rlChange}
                fields={[{ name: "name", type: "text", label: "Texte" }]}
              />
              <div className="form-inline">
                <Control
                  type="select"
                  style={{ maxWidth: 200 }}
                  datas={constantData.QUOTATION_MENTIONS}
                  name="mentionSel"
                  dataIndex="name"
                  dataLabel="name"
                  value={mentionSel}
                  change={(e) => setMentionSel(e.target.value)}
                />

                <button
                  style={{
                    height: 30,
                    padding: "0 10px",
                  }}
                  className="btn btn-outline-info"
                  onClick={addMention}
                >
                  <i className="fa fa-plus mr-2"></i>Ajouter une mention
                </button>
              </div>
            </div>

            <div className="col-4 p-2">
              <Control
                label="Commentaire"
                value={quotation.comment}
                change={rlChange}
                name="comment"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ContractFinalValues);
