import React from "react";
import useStore from "../../../context/useStore";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";
import vehicleTools from "../../../helpers/vehicleTools";

Font.register({
  family: "Lato Bold",
  src: `/Lato-Bold.ttf`
});
const styles = StyleSheet.create({
  container: {},
  header: {
    fontSize: 10
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    borderBottomColor: "#d2d2d2",
    borderBottomStyle: "solid",
    borderBottomWidth: 1
  },
  rowHead: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    marginTop: 5,
    fontFamily: "Lato Bold",
    borderBottomColor: "black",
    borderBottomStyle: "solid",
    borderBottomWidth: 1
  },
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    fontSize: 9,
    borderColor: "#d2d2d2",
    borderStyle: "solid",
    borderWidth: 1,
    padding: 3
  },
  columnHead: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    fontSize: 10,
    fontFamily: "Lato Bold",
    borderColor: "black",
    borderStyle: "solid",
    borderWidth: 1,
    padding: 3,
    textAlign: "center"
  },
  textSmall: {
    fontSize: 9
  },
  textBig: {
    fontFamily: "Lato Bold"
  }
});

const QuotationRecapPdf = ({ quotations, constantData }) => {
  return (
    <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
      <Document>
        <Page orientation="landscape" size="A4" style={{ padding: 30 }}>
          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Image src="/logo.png" style={{ width: "70%" }} />

              <Text style={styles.textSmall}>Tel : 0262 21 20 20</Text>
              <Text style={styles.textSmall}>Fax : 0262 46 28 99</Text>
            </View>
            <View
              style={{
                flexDirection: "column",
                width: "50%",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Text>Récapitulatif des propositions</Text>
            </View>
          </View>

          <View style={styles.rowHead}>
            <Text style={styles.columnHead}>Véhicule</Text>
            <Text style={styles.columnHead}>Type</Text>
            <Text style={styles.columnHead}>Loyer financier + entretien</Text>
            <Text style={styles.columnHead}>Véhicule relais</Text>
            <Text style={styles.columnHead}>Assurance tous risques</Text>
            <Text style={styles.columnHead}>Garantie perte financière</Text>
            <Text style={styles.columnHead}>Pneumatiques</Text>
            <Text style={styles.columnHead}>Autres prestations</Text>
            <Text style={styles.columnHead}>Total HT</Text>
            <Text style={styles.columnHead}>TVA 8.5%</Text>
            <Text style={styles.columnHead}>Total TTC</Text>
          </View>

          {quotations.map(q => (
            <View style={{ marginBottom: 10 }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%"
                }}
              >
                <Text style={styles.column}>{q.reference}</Text>
                <Text style={styles.column}>
                  {
                    constantData.VEHICLE_TYPES.find(v => v.id == q.vehicle.type)
                      .name
                  }
                </Text>
                <Text style={styles.column}>{q.baseRentHT} €</Text>
                <Text style={styles.column}>{q.replacementVehicleCost} €</Text>
                <Text style={styles.column}>{q.insuranceHT} €</Text>
                <Text style={styles.column}>{q.insuranceFinLoss} €</Text>
                <Text style={styles.column}>
                  ({q.nbTires} pneus){" "}
                  {q.nbTires != "0" &&
                    q.benefits.find(b => b.name == "Pneus").priceHT + " €"}
                </Text>
                <Text style={styles.column}>
                  {q.benefits.filter(b => b.name != "Pneus").length > 0
                    ? Math.round(
                        q.benefits
                          .filter(b => b.name != "Pneus")
                          .reduce((s, b) => {
                            return {
                              priceHT:
                                parseFloat(s.priceHT) + parseFloat(b.priceHT)
                            };
                          }).priceHT * 100
                      ) / 100
                    : 0}{" "}
                  €
                </Text>

                <Text style={styles.column}>{q.totalRentHT} €</Text>
                <Text style={styles.column}>
                  {Math.round((q.totalRentTTC - q.totalRentHT) * 100) / 100} €
                </Text>
                <Text style={styles.column}>{q.totalRentTTC} €</Text>
              </View>
              {q.benefits.filter(b => b.name != "Pneus").length > 0 && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                    padding: "2px 0"
                  }}
                >
                  <Text style={{ fontSize: 10 }}>Autres prestations : </Text>
                  <View style={{ flexDirection: "row" }}>
                    {q.benefits
                      .filter(b => b.name != "Pneus")
                      .map((b, k) => {
                        return (
                          <Text style={{ fontSize: 9 }}>
                            {b.name + " : " + b.priceHT + " € "}
                          </Text>
                        );
                      })}
                  </View>
                </View>
              )}
            </View>
          ))}
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default QuotationRecapPdf;
