export const FINANCE_TYPES = {
  LOAD_FINANCE: "LOAD_FINANCE",
  GET_FINANCES: "GET_FINANCES",
  GET_FINANCE: "GET_FINANCE",
  UPDATE_FINANCE_DATA: "UPDATE_FINANCE_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    reference: "",
    financier: "",
    status: 1,
    financeType: "",
    totalEnvelop: 0,
    quotations: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FINANCE_TYPES.LOAD_FINANCE:
      return {
        ...state,
        isLoading: action.payload
      };
    case FINANCE_TYPES.GET_FINANCES:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.count,
        item: initialState.item,
        isLoading: false
      };

    case FINANCE_TYPES.GET_FINANCE:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };

    case FINANCE_TYPES.UPDATE_FINANCE_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
