import React from "react";

const Control = ({
  label,
  type = "text",
  value,
  change,
  name,
  suffix = false,
  k = -1,
  error = false,
  disabled = false,
  checked = false,
  datas = false,
  dataIndex = "id",
  dataLabel = "name",
  className = "",
  required = false,
  inputClassName = "",
  defferChange = false,
  style = {},
}) => {
  function actionChange(e) {
    if (defferChange) {
      var value = window.prompt(
        "Modifier une valeur réglementée",
        e.target.value
      );
      if (value) {
        if (type == "number") {
          value = value.replace(",", ".");
          e.target.value = parseFloat(value) ? parseFloat(value) : 0;
        } else {
          e.target.value = value;
        }
      }
    }
    if (k != -1) {
      change(e, k);
    } else {
      change(e);
    }
  }

  if (type == "checkbox") {
    return (
      <div className={"custom-control custom-switch " + className}>
        <input
          type="checkbox"
          className={"custom-control-input " + inputClassName}
          disabled={disabled ? "disabled" : false}
          name={name}
          id={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
          onChange={k != -1 ? (e) => change(e, k) : change}
          checked={checked ? "checked" : false}
        />
        <label
          className="custom-control-label"
          htmlFor={
            k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name
          }
        >
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
      </div>
    );
  } else if (type == "select") {
    return (
      <div className={"form-group " + className}>
        <label htmlFor={k != -1 ? name + "-" + k : name}>
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>

        <select
          className={"form-control " + inputClassName}
          disabled={disabled ? "disabled" : false}
          value={value ? value : ""}
          name={name}
          id={k != -1 ? name + "-" + k : name}
          onChange={k != -1 ? (e) => change(e, k) : change}
          style={style}
        >
          <option value="">...</option>
          {datas.map((data, k) => (
            <option
              key={name + "-" + (data.id ? data.id : Math.random())}
              value={data[dataIndex]}
            >
              {data[dataLabel]}
            </option>
          ))}
        </select>
        <small className="form-text text-danger">{error[name]}</small>
      </div>
    );
  } else if (type == "date") {
    return (
      <div className={"form-group " + className}>
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            className={"form-control " + inputClassName}
            type="date"
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : name}
            name={name}
            value={value}
            onChange={actionChange}
            title={value}
          />
          {suffix && (
            <div className="input-group-append">
              <span
                className="input-group-text"
                style={disabled ? { color: "#9a9a9a" } : {}}
              >
                {suffix}
              </span>
            </div>
          )}
        </div>
        {error && (
          <small className="form-text text-danger">{error[name]}</small>
        )}
      </div>
    );
  } else {
    return (
      <div className={"form-group " + className}>
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            className={"form-control " + inputClassName}
            type="text"
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : name}
            name={name}
            value={value}
            onChange={actionChange}
            title={value}
          />
          {suffix && (
            <div className="input-group-append">
              <span
                className="input-group-text"
                style={disabled ? { color: "#9a9a9a" } : {}}
              >
                {suffix}
              </span>
            </div>
          )}
        </div>
        {error && (
          <small className="form-text text-danger">{error[name]}</small>
        )}
      </div>
    );
  }
};

export default Control;
