exports.metas = [
  {
    pathname: "vehicles",
    title: "Flotte",
    child: "Édition d'un véhicule"
  },
  {
    pathname: "brands",
    title: "Marques",
    child: "Édition d'une marque"
  },
  {
    pathname: "brandmodels",
    title: "Modèles et finitions",
    child: "Édition d'un modèle"
  },
  {
    pathname: "repurchases",
    title: "Valeurs de rachat",
    child: "Édition d'une valeur de rachat"
  },
  {
    pathname: "users",
    title: "Utilisateurs",
    child: "Édition d'un utilisateur"
  },
  {
    pathname: "constants",
    title: "Éditions des constantes dynamiques",
    child: ""
  },
  {
    pathname: "clients",
    title: "Clients",
    child: "Édition d'un client"
  },
  {
    pathname: "quotations",
    title: "Devis",
    child: "Édition d'un devis"
  },
  {
    pathname: "contracts",
    title: "Contrats",
    child: "Édition d'un contrat"
  },
  {
    pathname: "maintenances",
    title: "Entretien",
    child: "Édition d'un tableau d'entretien"
  },
  {
    pathname: "finances",
    title: "Financement",
    child: "Édition d'un financement"
  },
  {
    pathname: "catalogs",
    title: "Catalogue",
    child: "Édition d'un véhicule catalogue"
  },
  {
    pathname: "tires",
    title: "Pneus",
    child: "Liste des pneus"
  }
];
