import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import Loader from "../../common/Loader";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import tools from "../../../helpers/tools";

const BrandModelDetail = (props) => {
  const [state, dispatch] = useStore();

  var brandModelData = state.brandModel.item;
  var brandData = state.brand.items;
  var constantData = state.constant.items;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);

  useEffect(() => {
    b_actions.getAllBrands();
    if (props.match.params.id != "create") {
      bm_actions.getBrandModel(props.match.params.id);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA, dispatch);
  }

  function addFinish() {
    var finishes = brandModelData.finishes;
    finishes.push({
      name: "",
      fuelType: "",
    });
    dispatch({
      type: BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA,
      payload: { key: "finishes", value: finishes },
    });
  }

  function changeFinish(e, k) {
    var finishes = brandModelData.finishes;
    finishes[k][e.target.name] = e.target.value;
    dispatch({
      type: BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA,
      payload: { key: "finishes", value: finishes },
    });
  }

  function removeFinish(k) {
    var finishes = brandModelData.finishes;
    finishes.splice(k, 1);
    dispatch({
      type: BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA,
      payload: { key: "finishes", value: finishes },
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un modèle</h1>
          <div className="form-group">
            <label htmlFor="brand">Marque</label>

            <select
              className="form-control"
              name="brand"
              id="brand"
              onChange={inputChange}
              value={brandModelData.brand}
            >
              <option value="">...</option>
              {brandData.map((brand, k) => (
                <option key={"brand-" + brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
            <small className="form-text text-danger">{state.error.brand}</small>
          </div>
          <div className="form-group">
            <label htmlFor="name">Modèle</label>

            <input
              type="text"
              className="form-control"
              name="name"
              id="name"
              onChange={inputChange}
              value={brandModelData.name}
            />
            <small className="form-text text-danger">{state.error.name}</small>
          </div>

          <div className="form-group mb-2">
            <label className="d-block">Finitions</label>
            {brandModelData.finishes.map((finish, k) => (
              <div key={"finish-" + k} className="form-inline mb-2">
                <div style={{ width: "50%" }}>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    name="name"
                    className="form-control mr-2"
                    value={finish.name}
                    onChange={(e) => changeFinish(e, k)}
                  />
                  <small className="form-text text-danger">
                    {state.error["finishes_" + k + "_name"]}
                  </small>
                </div>

                <div>
                  <select
                    name="fuelType"
                    className="form-control mr-2"
                    value={finish.fuelType}
                    onChange={(e) => changeFinish(e, k)}
                  >
                    <option value="">...</option>
                    {constantData.FUEL_TYPES.map((fuelType) => (
                      <option
                        key={"fueltype-" + fuelType.id}
                        value={fuelType.id}
                      >
                        {fuelType.name}
                      </option>
                    ))}
                  </select>
                  <small className="form-text text-danger">
                    {state.error["finishes_" + k + "_fuelType"]}
                  </small>
                </div>

                <button
                  className="btn btn-danger"
                  style={{ height: 38 }}
                  onClick={() => removeFinish(k)}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            ))}
            <button className="btn btn-secondary" onClick={addFinish}>
              <i className="fa fa-plus mr-2" /> Ajouter une finition
            </button>
          </div>

          {state.brandModel.isLoading ? (
            <Loader />
          ) : props.match.params.id == "create" ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => bm_actions.createBrandModel(brandModelData)}
            >
              Créer
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => bm_actions.updateBrandModel(brandModelData)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(BrandModelDetail);
