import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { userActions } from "../../../context/actions/userActions";
import Loader from "../../common/Loader";
import { USER_TYPES } from "../../../context/reducers/userReducers";
import tools from "../../../helpers/tools";

const UserDetail = props => {
  const [state, dispatch] = useStore();

  var userData = state.user.item;
  var constantData = state.constant.items;

  var actions = userActions(state, dispatch, props);

  useEffect(() => {
    if (props.match.params.id != "create") {
      actions.getUser(props.match.params.id);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, USER_TYPES.UPDATE_USER_DATA, dispatch);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un utilisateur</h1>
          <div className="form-group">
            <label htmlFor="lastname">Nom</label>

            <input
              type="text"
              className="form-control"
              name="lastname"
              id="lastname"
              onChange={inputChange}
              value={userData.lastname}
            />
            <small className="form-text text-danger">{state.error.lastname}</small>
          </div>
          <div className="form-group">
            <label htmlFor="firstname">Prénom</label>

            <input
              type="text"
              className="form-control"
              name="firstname"
              id="firstname"
              onChange={inputChange}
              value={userData.firstname}
            />
            <small className="form-text text-danger">{state.error.firstname}</small>
          </div>
          <div className="form-group">
            <label htmlFor="phone">Téléphone</label>

            <input
              type="text"
              className="form-control"
              name="phone"
              id="phone"
              onChange={inputChange}
              value={userData.phone}
            />
            <small className="form-text text-danger">{state.error.phone}</small>
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>

            <input
              type="text"
              className="form-control"
              name="email"
              id="email"
              onChange={inputChange}
              value={userData.email}
            />
            <small className="form-text text-danger">{state.error.email}</small>
          </div>
          <div className="form-group">
            <label htmlFor="password">
              Mot de passe{" "}
              <span className="text-muted">
                (laissez vide pour ne pas modifier)
              </span>
            </label>

            <input
              type="text"
              className="form-control"
              name="password"
              id="password"
              onChange={inputChange}
              value={userData.password}
            />
            <small className="form-text text-danger">{state.error.password}</small>
          </div>
          <div className="form-group">
            <label htmlFor="role">Rôle</label>

            <select
              className="form-control"
              id="role"
              name="role"
              value={userData.role}
              onChange={inputChange}
            >
              <option value="">...</option>
              {constantData.ROLES.map((role, k) => (
                <option key={"role-" + k} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
            <small className="form-text text-danger">{state.error.role}</small>
          </div>
          {state.user.isLoading ? (
            <Loader />
          ) : props.match.params.id == "create" ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.createUser(userData)}
            >
              Créer
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => actions.updateUser(userData)}
            >
              Enregistrer
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(UserDetail);
