import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { repurchaseActions } from "../../../context/actions/repurchaseActions";
import Loader from "../../common/Loader";
import SelectDateModal from "../Maintenance/SelectDateModal";
import { DateTime } from "luxon";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";

const RepurchaseList = (props) => {
  const [state, dispatch] = useStore();
  const [selectId, setSelectId] = useState(null);

  var repurchaseData = state.repurchase.items;
  var constantData = state.constant.items;

  var actions = repurchaseActions(state, dispatch, props);

  function createNewPeriod(date) {
    actions.addPeriodRepurchase(selectId, date);
  }

  return (
    <CustomList getAction={actions.getRepurchases}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Valeurs de rachat</h1>
              {state.repurchase.isLoading ? (
                <Loader />
              ) : (
                  <Link className="btn btn-primary" to="/repurchases/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un tableau
                  </Link>
                )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Marque</th>
                    <th>Modèles</th>
                    <th>Carburants</th>
                    <th>Début validité</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {repurchaseData.map((repurchase, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>
                        {repurchase.brand ? (
                          repurchase.brand.name
                        ) : (
                            <span className="text-danger">
                              Erreur, la marque est absente{" "}
                            </span>
                          )}
                      </td>
                      <td>
                        {repurchase.brandModels.length > 0 ? (
                          repurchase.brandModels.map(
                            (bm, k) =>
                              bm.name +
                              (k == repurchase.brandModels.length - 1
                                ? ""
                                : ", ")
                          )
                        ) : (
                            <span className="text-danger">
                              Erreur, aucun modèle trouvé
                            </span>
                          )}
                      </td>
                      <td>
                        {repurchase.fuelTypes.map(
                          (ft, k) =>
                            constantData.FUEL_TYPES.find((f) => f.id == ft)
                              .name +
                            (k == repurchase.fuelTypes.length - 1 ? "" : ", ")
                        )}
                      </td>
                      <td>
                        {DateTime.fromJSDate(
                          new Date(repurchase.validFrom)
                        ).toFormat("dd/MM/yyyy")}
                        {repurchase.validTo &&
                          repurchase.validTo != "" &&
                          DateTime.fromJSDate(
                            new Date(repurchase.validTo)
                          ).toFormat("dd/MM/yyyy")}
                      </td>
                      <td>
                        <Link
                          className="btn btn-secondary mr-2"
                          to={"/repurchases/" + repurchase.id}
                        >
                          Modifier
                        </Link>

                        <button
                          type="button"
                          className="btn btn-primary mr-2"
                          data-toggle="modal"
                          data-target="#selectDateModal"
                          onClick={() => setSelectId(repurchase.id)}
                        >
                          Ajouter une nouvelle période
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.repurchase.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />


            </div>
          </div>
          <SelectDateModal createNewPeriod={createNewPeriod} />
        </div>
      )}
    </CustomList>
  );
};

export default RepurchaseList;
