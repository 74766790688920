export const CONSTANT_TYPES = {
  LOAD_CONSTANT: "LOAD_CONSTANT",
  GET_CONSTANTS: "GET_CONSTANTS",
  UPDATE_CONSTANT_DATA: "UPDATE_CONSTANT_DATA"
};

const initialState = {
  isLoading: false,
  items: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONSTANT_TYPES.LOAD_CONSTANT:
      return {
        ...state,
        isLoading: action.payload
      };
    case CONSTANT_TYPES.GET_CONSTANTS:
      return {
        ...state,
        items: action.payload,
        isLoading: false
      };
    case CONSTANT_TYPES.UPDATE_CONSTANT_DATA:
      return {
        ...state,
        items: { ...state.items, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
