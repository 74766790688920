import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { BRANDMODEL_TYPES } from "../reducers/brandModelReducers";

export const brandModelActions = (state, dispatch, props) => {
  function getBrandModels(search = {search: null}) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      brandModel: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "brandmodels/search", search)
      .then(res => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODELS,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function getBrandModel(id) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "brandmodels/" + id)
      .then(res => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODEL,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function getBrandModelByBrand(id) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "brandmodels/brands/" + id)
      .then(res => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODELS,
          payload: { data: res.data, count: 0 }
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function createBrandModel(brandModelData) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "brandmodels", brandModelData)
      .then(res => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODEL,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          brandModelData.name + " a été correctement créé."
        );
        props.history.push("/brandmodels/" + res.data.id);
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }
  function updateBrandModel(brandModelData) {
    dispatch({ type: BRANDMODEL_TYPES.LOAD_BRANDMODEL, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "brandmodels/" + brandModelData.id, brandModelData)
      .then(res => {
        dispatch({
          type: BRANDMODEL_TYPES.GET_BRANDMODEL,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          brandModelData.name + " a été correctement mis à jour."
        );
        if (brandModelData.finishes.length < res.data.finishes.length) {
          tools.filterError(
            dispatch,
            {
              other:
                "Une ou plusieurs finitions n'ont pas pu être supprimées car elles sont utilisées dans des véhicules de flotte ou catalogue."
            },
            BRANDMODEL_TYPES.LOAD_BRANDMODEL
          );
        }
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          BRANDMODEL_TYPES.LOAD_BRANDMODEL
        );
      });
  }

  return {
    createBrandModel,
    updateBrandModel,
    getBrandModels,
    getBrandModel,
    getBrandModelByBrand
  };
};
