import React, { useState } from "react";
import useStore from "../../../context/useStore";
import vehicleTools from "../../../helpers/vehicleTools";
import financeTools from "../../../helpers/financeTools";
import { toast } from "react-toastify";
import OverlayContent from "../../common/OverlayContent";
import PaymentTablePdf from "./PaymentTablePdf";

const PaymentTable = ({ quotations, finances }) => {
  const [state, dispatch] = useStore();
  const [pdfOpen, setPdfOpen] = useState(false);
  var constantData = state.constant.items;

  var qs = [];
  console.log(quotations, finances);
  finances.quotations.forEach((f) => {
    quotations.forEach((q) => {
      q.quotations.forEach((sq) => {
        if (sq._id == f.quotation) {
          qs.push({ ...sq, periods: f.periods });
        }
      });
    });
  });
  console.log("QS", qs);
  var rows = [];

  const getLine = (q, k) => {
    let vehiclePrices = vehicleTools.calcVehiclePrices(q.vehicle, true);
    let priceHT = Math.round(vehiclePrices.priceHT * 100) / 100;
    let vehicleTVA = priceHT * (q.vehicle.type == 2 ? 0 : 0.085);
    let content = [];
    for (var i = 0; i < parseInt(q.quantity); i++) {
      content.push(
        <tr key={"qline" + k + i}>
          <td>{q.reference}</td>
          <td>
            {
              constantData.VEHICLE_TYPES.find((v) => v.id == q.vehicle.type)
                .name
            }
          </td>
          <td>{priceHT}</td>
          <td>{vehicleTools.calcOptHT(q)}</td>
          <td>{q.vehicle.inroadCost}</td>
          <td>{q.vehicle.greyCardCost}</td>
          <td>{q.vehicle.malusBonusEco}</td>
          <td>
            {Math.round((vehicleTVA + vehicleTools.calcTvaMarOpt(q)) * 100) /
              100}{" "}
          </td>
          <td>{Math.round(q.vehicle.priceNetTTC * 100) / 100}</td>
          <td>{q.vehicle.investAmtHT}</td>
          <td>
            <table className="table">
              <thead>
                <tr>
                  <th>Période</th>
                  <th>VR</th>
                  <th>Mens.</th>
                </tr>
              </thead>
              <tbody>
                {q.periods.map((p, pk) => (
                  <tr key={"ln" + k + pk}>
                    <td>
                      <span className="badge badge-primary">
                        {p.num} - {p.duration != "" ? p.duration + "m" : ""}
                      </span>
                    </td>
                    {/* <td>{p.monthPayment}</td> */}
                    {pk < q.periods.length - 1 ? (
                      <td>
                        VR ={" "}
                        {p.duration == q.dur ? q.repurchaseValueFinal : p.vr}
                        {/*financeTools.calcVRAtPeriod(q, k)*/} €
                      </td>
                    ) : (
                      <td></td>
                    )}
                    <td>{p.monthPaymentReal} €/m</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      );
    }

    return content;
  };

  return (
    <>
      {pdfOpen && (
        <OverlayContent close={() => setPdfOpen(false)}>
          <PaymentTablePdf quotations={qs} constantData={constantData} />
        </OverlayContent>
      )}
      <button className="btn btn-success" onClick={() => setPdfOpen(true)}>
        Imprimer le tableau
      </button>
      <table className="table">
        <thead>
          <tr>
            <th>Véhicule</th>
            <th>Type</th>
            <th>Prix net HT</th>
            <th>Options HT</th>
            <th>MAR HT</th>
            <th>CG + Carburant</th>
            <th>Bonus/Malus</th>
            <th>TVA 8.5%</th>
            <th>Prix net TTC</th>
            <th>Montant invest HT</th>
            <th>Paliers</th>
          </tr>
        </thead>
        <tbody>{qs.map((q, k) => getLine(q, k))}</tbody>
      </table>
    </>
  );
};

export default PaymentTable;
