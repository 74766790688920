import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { financeActions } from "../../../context/actions/financeActions";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";

const FinanceList = (props) => {
  const [state, dispatch] = useStore();
  var financeData = state.finance.items;
  var constantData = state.constant.items;
  var actions = financeActions(state, dispatch, props);

  return (
    <CustomList getAction={actions.getFinances}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Financements</h1>
              {state.finance.isLoading ? (
                <Loader />
              ) : (
                  <Link className="btn btn-primary" to="/finances/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un financement
                  </Link>
                )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Référence</th>
                    <th>Financeur</th>
                    <th>Véhicule(s)</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {financeData.map((f, k) => {
                    let financier = constantData.FINANCIERS.find(
                      (x) => x.id == f.financier
                    );
                    return (
                      <tr key={"brand-row-" + k}>
                        <td>{f.reference}</td>
                        <td>
                          {financier ? (
                            financier.name
                          ) : (
                              <span className="text-danger">À renseigner</span>
                            )}
                        </td>
                        <td>
                          {f.quotations.map((q, kk) => (
                            <span
                              className="badge badge-primary mr-2"
                              key={"ref" + kk}
                            >
                              {q.reference}
                            </span>
                          ))}
                        </td>

                        <td>
                          <Link
                            className="btn btn-secondary mr-2"
                            to={"/finances/" + f.id}
                          >
                            Modifier
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <CustomPagination
                total={state.finance.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />


            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default FinanceList;
