import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { tireActions } from "../../../context/actions/tireActions";
import { TIRE_TYPES } from "../../../context/reducers/tireReducers";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import tools from "../../../helpers/tools";

const TireDetail = (props) => {
  const [state, dispatch] = useStore();

  const [tireEdit, setTireEdit] = useState(null);

  var actions = tireActions(state, dispatch, props);
  var tireData = state.tire.items;
  var tireItem = state.tire.item;

  useEffect(() => {
    actions.getTires();
  }, []);

  function inputChange(e) {
    tools.inputChange(e, TIRE_TYPES.UPDATE_TIRE_DATA, dispatch);
  }
  console.log(tireEdit);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Pneus</h1>
          <div className="form-inline">
            <Control
              label="Nom"
              name="name"
              className="mr-2"
              value={tireItem.name}
              change={inputChange}
              error={state.error}
              required={true}
            />
            <Control
              label="Prix"
              name="price"
              type="number"
              suffix="€"
              value={tireItem.price}
              change={inputChange}
              error={state.error}
              required={true}
            />
            <button
              className="btn btn-success ml-2"
              onClick={() => actions.createTire(tireItem)}
            >
              <i className="fa fa-plus mr-2" />
              Ajouter ce pneu
            </button>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prix unitaire</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tireData.map((t, k) => (
                <tr>
                  {tireEdit && tireEdit.id == t.id ? (
                    <>
                      <td>
                        <Control
                          name="name"
                          value={tireEdit.name}
                          change={(e) =>
                            setTireEdit({ ...tireEdit, name: e.target.value })
                          }
                        />
                      </td>
                      <td>
                        <Control
                          type="number"
                          name="price"
                          value={tireEdit.price}
                          change={(e) =>
                            setTireEdit({ ...tireEdit, price: e.target.value })
                          }
                        />
                      </td>
                    </>
                  ) : (
                    <>
                      <td>{t.name}</td>
                      <td>{t.price}</td>
                    </>
                  )}

                  <td>
                    {tireEdit && tireEdit.id == t.id ? (
                      <button
                        className="btn btn-success mr-2"
                        onClick={() => actions.updateTire(tireEdit)}
                      >
                        Enregistrer
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary mr-2"
                        onClick={() => setTireEdit(t)}
                      >
                        Modifier
                      </button>
                    )}

                    <button
                      className="btn btn-danger"
                      onClick={() => actions.deleteTire(t.id)}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TireDetail;
