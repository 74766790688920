import React from "react";
import useStore from "../../../context/useStore";
import PeriodSelector from "./PeriodSelector";

const FinanceTab = ({
  quotation,
  selectedTab,
  index,
  inputPeriodChange,
  addPeriod,
  removePeriod,
  disabled
}) => {
  const [state, dispatch] = useStore();
  var financeData = state.finance.item;

  return (
    <div
      className={"tab-pane fade " + (selectedTab == index ? "show active" : "")}
      id={quotation._id}
      role="tabpanel"
      aria-labelledby={"tab" + quotation._id}
    >
      <div className="row">
        {quotation.periods.map((p, k) => (
          <div className="col-6" key={k}>
            <PeriodSelector
              period={p}
              disabled={disabled}
              k={k}
              totalPeriods={quotation.periods.length}
              quotation={quotation}
              inputPeriodChange={e => inputPeriodChange(e, index, k)}
              removePeriod={() => removePeriod(index, k)}
            />
          </div>
        ))}
        <div className="col-12">
          <button
            className="btn btn-secondary m-3"
            onClick={() => addPeriod(index)}
          >
            <i className="fa fa-plus mr-2" />
            Ajouter une période
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinanceTab;
