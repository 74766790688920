import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { REPURCHASE_TYPES } from "../reducers/repurchaseReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const repurchaseActions = (state, dispatch, props) => {
  function getRepurchases(search = {search: null}) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      repurchase: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "repurchases/search", search)
      .then(res => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASES,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function getRepurchasesFromVehicle(search = "") {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "repurchases" + search)
      .then(res => {
        if (res.data.length == 0) {
          tools.filterError(
            dispatch,
            { other: "Aucune valeur de rachat disponible pour ce véhicule" },
            REPURCHASE_TYPES.LOAD_REPURCHASE
          );
        } else {
          dispatch({
            type: REPURCHASE_TYPES.GET_REPURCHASES_VEHICLE,
            payload: res.data
          });
        }
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function getRepurchase(id) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "repurchases/" + id)
      .then(res => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASE,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function createRepurchase(repurchaseData) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "repurchases", repurchaseData)
      .then(res => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASE,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          repurchaseData.name + " a été correctement créé."
        );
        props.history.push("/repurchases/" + res.data.id);
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function addPeriodRepurchase(id, date) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "repurchases/addperiod/" + id, { date: date })
      .then(res => {
        window.location.href = "/repurchases/" + res.data.id;
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }
  function updateRepurchase(repurchaseData) {
    dispatch({ type: REPURCHASE_TYPES.LOAD_REPURCHASE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "repurchases/" + repurchaseData.id, repurchaseData)
      .then(res => {
        dispatch({
          type: REPURCHASE_TYPES.GET_REPURCHASE,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          repurchaseData.name + " a été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          REPURCHASE_TYPES.LOAD_REPURCHASE
        );
      });
  }

  return {
    createRepurchase,
    updateRepurchase,
    getRepurchases,
    getRepurchase,
    addPeriodRepurchase,
    getRepurchasesFromVehicle
  };
};
