import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { CONTRACT_TYPES } from "../reducers/contractReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const contractActions = (state, dispatch, props) => {
  function getContracts(search = { search: null }) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      contract: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "contracts/search", search)
      .then(res => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACTS,
          payload: res.data
        });
      })
      .catch(err => {

        if (err.response && err.response.data) {

          tools.filterError(
            dispatch,
            err.response.data,
            CONTRACT_TYPES.LOAD_CONTRACT
          );
        }
      });
  }
  function getContract(id) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "contracts/" + id)
      .then(res => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACT,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CONTRACT_TYPES.LOAD_CONTRACT
        );
      });
  }
  function createContract(contractData) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "contracts", contractData)
      .then(res => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACT,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          contractData.name + " a été correctement créé."
        );
        props.history.push("/contracts/" + res.data.id);
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CONTRACT_TYPES.LOAD_CONTRACT
        );
      });
  }
  function updateContract(contractData) {
    dispatch({ type: CONTRACT_TYPES.LOAD_CONTRACT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "contracts/" + contractData.id, contractData)
      .then(res => {
        dispatch({
          type: CONTRACT_TYPES.GET_CONTRACT,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          contractData.name + " a été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CONTRACT_TYPES.LOAD_CONTRACT
        );
      });
  }

  return {
    createContract,
    updateContract,
    getContracts,
    getContract
  };
};
