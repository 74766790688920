import React from "react";
import Control from "../../common/Control";

const QuotationFilter = ({ filters, setFilters, constants }) => {
  function changeFilter(e) {
    var fil = JSON.parse(JSON.stringify(filters));
    fil[e.target.name] = e.target.value;
    setFilters(fil);
    console.log(fil);
  }

  return (
    <div className="form-inline">
      <Control
        className="mr-2 mb-2"
        label="Marque : "
        name="brand"
        value={filters.brand}
        change={changeFilter}
        inputClassName="p-1 inp-sm ml-1"
      />
      <Control
        className="mr-2 mb-2"
        label="Modèle : "
        name="brandModel"
        value={filters.brandModel}
        change={changeFilter}
        inputClassName="p-1 inp-sm ml-1"
      />
      <Control
        className="mr-2 mb-2"
        label="Finition : "
        name="finish"
        value={filters.finish}
        change={changeFilter}
        inputClassName="p-1 inp-sm ml-1"
      />
      <Control
        className="mr-2 mb-2"
        label="Durée : "
        name="duration"
        value={filters.duration}
        change={changeFilter}
        inputClassName="p-1 inp-sm ml-1"
      />
      <Control
        className="mr-2 mb-2"
        label="Kilométrage : "
        name="km"
        value={filters.km}
        change={changeFilter}
        inputClassName="p-1 inp-sm ml-1"
      />
      <Control
        className="mr-2 mb-2"
        label="État : "
        name="status"
        type="select"
        datas={constants.QUOTATION_STATUS}
        value={filters.status}
        change={changeFilter}
        inputClassName="p-1 inp-sm ml-1"
      />
    </div>
  );
};

export default QuotationFilter;
