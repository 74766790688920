import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { vehicleActions } from "../../../context/actions/vehicleActions";
import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import Loader from "../../common/Loader";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import Control from "../../common/Control";

const VehicleList = (props) => {
  const [state, dispatch] = useStore();

  var actions = vehicleActions(state, dispatch);
  var b_actions = brandActions(state, dispatch);
  var bm_actions = brandModelActions(state, dispatch);
  var constantData = state.constant.items;
  const [specialSearch, setSpecialSearch] = useState({
    brand: "",
    brandModel: "",
    finish: "",
    immat: "",
  });

  useEffect(() => {
    b_actions.getAllBrands();
  }, []);

  useEffect(() => {
    if (specialSearch.brand != "") {
      bm_actions.getBrandModelByBrand(specialSearch.brand);
    }
  }, [specialSearch.brand]);

  function changeSearch(e) {
    var { name, value } = e.target;
    var sh = JSON.parse(JSON.stringify(specialSearch));
    sh[name] = value;
    console.log(sh);
    setSpecialSearch(sh);
  }

  return (
    <CustomList getAction={actions.getVehicles}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!props.modal && <h1>Flotte</h1>}
              {state.vehicle.isLoading && <Loader />}
              <div className="row my-3">
                <div className="col-12 col-md-4">
                  <Control
                    label="Marque"
                    type="select"
                    name="brand"
                    value={specialSearch.brand}
                    change={changeSearch}
                    datas={state.brand.items.sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Control
                    label="Modèle"
                    type="select"
                    name="brandModel"
                    value={specialSearch.brandModel}
                    change={changeSearch}
                    datas={state.brandModel.items.sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Control
                    label="Finition"
                    type="select"
                    name="finish"
                    value={specialSearch.finish}
                    change={changeSearch}
                    datas={
                      state.brandModel.items.find(
                        (bm) => bm.id == specialSearch.brandModel
                      )
                        ? state.brandModel.items
                            .find((bm) => bm.id == specialSearch.brandModel)
                            .finishes.sort((a, b) => {
                              if (a.name > b.name) return 1;
                              if (a.name < b.name) return -1;
                              return 0;
                            })
                        : []
                    }
                    dataIndex="_id"
                    dataLabel="name"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Control
                    label="Immatriculation"
                    name="immat"
                    change={changeSearch}
                    value={specialSearch.immat}
                  />
                </div>
                <div className="col d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-secondary mb-3 mt-4"
                    onClick={() => actions.getVehicles(specialSearch)}
                  >
                    Rechercher
                  </button>
                </div>
              </div>
              {/* <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              /> */}

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Affectation</th>
                    <th>Immatriculation</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Finition</th>
                    <th>Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.vehicle.items.map((vehicle, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>
                        {
                          constantData.VEHICLE_ASSIGNMENTS.find(
                            (c) => c.id === vehicle.type
                          ).name
                        }
                      </td>
                      <td>{vehicle.immat}</td>
                      <td>
                        {vehicle.brand ? (
                          vehicle.brand.name
                        ) : (
                          <span className="text-danger">
                            Aucune marque pour ce véhicule !
                          </span>
                        )}
                      </td>
                      <td>
                        {vehicle.brandModel ? (
                          vehicle.brandModel.name
                        ) : (
                          <span className="text-danger">
                            Aucun modèle pour ce véhicule !
                          </span>
                        )}
                      </td>
                      <td>
                        {vehicle.brandModel &&
                        vehicle.brandModel.finishes &&
                        vehicle.brandModel.finishes.find(
                          (f) => f._id == vehicle.finish
                        ) ? (
                          vehicle.brandModel.finishes.find(
                            (f) => f._id == vehicle.finish
                          ).name
                        ) : (
                          <span className="text-danger">
                            Aucune finition pour ce véhicule !
                          </span>
                        )}
                      </td>
                      <td>
                        {
                          constantData.VEHICLE_TYPES.find(
                            (vt) => vt.id == vehicle.type
                          ).name
                        }
                      </td>
                      <td>
                        {!props.modal ? (
                          <Link
                            className="btn btn-secondary"
                            to={"/vehicles/" + vehicle.id}
                          >
                            Modifier
                          </Link>
                        ) : (
                          <a
                            href="#"
                            className="btn btn-secondary"
                            onClick={() => props.selectVehicle(vehicle)}
                          >
                            Choisir
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.vehicle.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default VehicleList;
