import React, { useState } from "react";
import useStore from "../../context/useStore";
import CatalogDetail from "../pages/Catalog/CatalogDetail";
import CatalogList from "../pages/Catalog/CatalogList";

const CatalogModal = props => {
  const [state, dispatch] = useStore();

  var [method, setMethod] = useState(1);

  function selectCatalog(catalog) {
    var v = { ...catalog };
    if (method == 2) {
      v.brand = state.brand.items.find(brand => brand.id == v.brand);
      v.brandModel = state.brandModel.items.find(
        brandModel => brandModel.id == v.brandModel
      );
      // catalog.finish = catalog.brandModel.finishes.find(
      //   finish => finish._id == catalog.finish
      // );
    }
    document.getElementById("close-catalog-modal").click();

    props.selectVehicle(v);
  }

  var content =
    method == 1 ? (
      <CatalogList modal={true} selectCatalog={selectCatalog} />
    ) : (
      <CatalogDetail modal={true} selectCatalog={selectCatalog} />
    );

  return (
    <>
      <div
        className="modal fade p-0"
        id="catalogModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sélection du véhicule
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className={"nav-link " + (method == 1 && "active")}
                    href="#"
                    onClick={() => setMethod(1)}
                  >
                    Rechercher
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={"nav-link " + (method == 2 && "active")}
                    href="#"
                    onClick={() => setMethod(2)}
                  >
                    Créer
                  </a>
                </li>
              </ul>
              <div className="mt-4">{content}</div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="close-catalog-modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogModal;
