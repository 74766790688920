import React, { Component, useContext, useState, useEffect } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";
import StoreContext from "./context/StoreContext";
import tools from "./helpers/tools";
import jwt_decode from "jwt-decode";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStore from "./context/useStore";
import { authActions } from "./context/actions/authActions";
import { AUTH_TYPES } from "./context/reducers/authReducers";
import PrivateRoute from "./components/common/PrivateRoute";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import Login from "./components/pages/Login/Login";
import Header from "./components/common/layout/Header";
import BrandList from "./components/pages/Brand/BrandList";
import BrandDetail from "./components/pages/Brand/BrandDetail";
import BrandModelList from "./components/pages/BrandModel/BrandModelList";
import BrandModelDetail from "./components/pages/BrandModel/BrandModelDetail";
import RepurchaseList from "./components/pages/Repurchase/RepurchaseList";
import RepurchaseDetail from "./components/pages/Repurchase/RepurchaseDetail";
import { constantActions } from "./context/actions/constantActions";
import { ClipLoader } from "react-spinners";
import UserList from "./components/pages/User/UserList";
import UserDetail from "./components/pages/User/UserDetail";
import VehicleList from "./components/pages/Vehicle/VehicleList";
import vehicleDetail from "./components/pages/Vehicle/VehicleDetail";
import BreadCrumb from "./components/common/layout/BreadCrumb";
import QuotationList from "./components/pages/Quotation/QuotationList";
import QuotationDetail from "./components/pages/Quotation/QuotationDetail";
import ContractList from "./components/pages/Contract/ContractList";
import ContractDetail from "./components/pages/Contract/ContractDetail";
import ConstantDynamic from "./components/pages/ConstantDynamic/ConstantDynamic";
import ClientList from "./components/pages/Client/CilentList";
import ClientDetail from "./components/pages/Client/ClientDetail";
import VehicleModal from "./components/common/VehicleModal";
import ClientModal from "./components/common/ClientModal";
import FinanceList from "./components/pages/Finance/FinanceList";
import FinanceDetail from "./components/pages/Finance/FinanceDetail";
import MaintenanceList from "./components/pages/Maintenance/MaintenanceList";
import MaintenanceDetail from "./components/pages/Maintenance/MaintenanceDetail";
import CatalogList from "./components/pages/Catalog/CatalogList";
import CatalogDetail from "./components/pages/Catalog/CatalogDetail";
import Loader from "./components/common/Loader";
import TireDetail from "./components/pages/Tire/TireDetail";

// Components
var isFirstLoad = true;

const App = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);

  isFirstLoad &&
    !state.constant.items &&
    !state.constant.isLoading &&
    c_actions.getConstants();

  if (localStorage.jwtToken && isFirstLoad) {
    isFirstLoad = false;
    // Set auth token header auth
    tools.setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    dispatch({ type: AUTH_TYPES.SET_CURRENT_USER, payload: decoded });

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      actions.logoutUser();
      // Clear current Profile
      // store.dispatch(clearCurrentProfile())
      // Redirect to login

      window.location.href = "/login";
    }
  }
  if (!localStorage.jwtToken && isFirstLoad) {
    console.log("ok");
    //window.location.href = "/login";
  }

  return state.constant.items ? (
    <Router>
      <>
        <Header />
        <BreadCrumb />
        <ToastContainer />
        <main
          role="main"
          className="container-fluid pb-5"
          style={{ minHeight: "100%" }}
        >
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/brands" component={BrandList} />
            <PrivateRoute exact path="/brands/:id" component={BrandDetail} />
            <PrivateRoute
              exact
              path="/brandmodels"
              component={BrandModelList}
            />
            <PrivateRoute
              exact
              path="/brandmodels/:id"
              component={BrandModelDetail}
            />
            <PrivateRoute
              exact
              path="/repurchases"
              component={RepurchaseList}
            />
            <PrivateRoute
              exact
              path="/repurchases/:id"
              component={RepurchaseDetail}
            />
            <PrivateRoute exact path="/users" component={UserList} />
            <PrivateRoute exact path="/users/:id" component={UserDetail} />
            <PrivateRoute exact path="/vehicles" component={VehicleList} />
            <PrivateRoute
              exact
              path="/vehicles/:id"
              component={vehicleDetail}
            />
            <PrivateRoute exact path="/catalogs" component={CatalogList} />
            <PrivateRoute
              exact
              path="/catalogs/:id"
              component={CatalogDetail}
            />
            <PrivateRoute exact path="/quotations" component={QuotationList} />
            <PrivateRoute
              exact
              path="/quotations/:id"
              component={QuotationDetail}
            />
            <PrivateRoute exact path="/contracts" component={ContractList} />
            <PrivateRoute
              exact
              path="/contracts/:id"
              component={ContractDetail}
            />
            <PrivateRoute exact path="/constants" component={ConstantDynamic} />
            <PrivateRoute exact path="/clients" component={ClientList} />
            <PrivateRoute exact path="/clients/:id" component={ClientDetail} />
            <PrivateRoute exact path="/vehiclemodal" component={VehicleModal} />
            <PrivateRoute exact path="/clientmodal" component={ClientModal} />
            <PrivateRoute exact path="/finances" component={FinanceList} />
            <PrivateRoute
              exact
              path="/finances/:id"
              component={FinanceDetail}
            />
            <PrivateRoute
              exact
              path="/maintenances"
              component={MaintenanceList}
            />
            <PrivateRoute
              exact
              path="/maintenances/:id"
              component={MaintenanceDetail}
            />
            <PrivateRoute exact path="/tires" component={TireDetail} />
          </Switch>
        </main>
      </>
    </Router>
  ) : (
    <div className="container text-center py-5">
      <Loader />
    </div>
  );
};

export default App;
