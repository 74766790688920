import React from "react";
import { BounceLoader } from "react-spinners";

const Loader = () => (
  <div className="d-flex justify-content-center w-100">
    <BounceLoader color={"#ff8a00"} />
  </div>
);

export default Loader;
