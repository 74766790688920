import axios from "axios";
import { api_url } from "../../config/config";
import { USER_TYPES } from "../reducers/userReducers";
import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const userActions = (state, dispatch, props) => {
  function getUsers() {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "users")
      .then(res => {
        dispatch({
          type: USER_TYPES.GET_USERS,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER)
      });
  }
  function getUser(id) {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "users/" + id)
      .then(res => {
        dispatch({type: USER_TYPES.GET_USER,payload: res.data});
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER)
      });
  }
  function createUser(userData) {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "users", userData)
      .then(res => {
        dispatch({
          type: USER_TYPES.GET_USER,
          payload: res.data
        });
        tools.filterAlert("success", userData.firstname + " " + userData.lastname + " a été correctement créé.")
        props.history.push("/users/" + res.data.id);
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER)
      });
  }
  function updateUser(userData) {
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "users/" + userData.id, userData)
      .then(res => {
        dispatch({
          type: USER_TYPES.GET_USER,
          payload: res.data
        });
        tools.filterAlert("success", userData.firstname + " " + userData.lastname + " a été correctement mis à jour.")
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER)
      });
  }
  function deleteUser(id) {
    if (
      !window.confirm(
        "Êtes-vous certain(e) de vouloir supprimer cette entrée ?"
      )
    )
      return false;
    dispatch({ type: USER_TYPES.LOAD_USER, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .delete(api_url + "users/" + id)
      .then(res => {
        getUsers();
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, USER_TYPES.LOAD_USER)
      });
  }

  return {
    createUser,
    updateUser,
    getUsers,
    getUser,
    deleteUser
  };
};
