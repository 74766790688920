import React, { useState } from "react";
import useStore from "../../context/useStore";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const ClientQuotationModal = props => {
  const [state, dispatch] = useStore();



  function selectClient(client) {
    document.getElementById("close-client-quotation-modal").click();
    props.selectClient(client);
  }



  return (
    <>

      <div
        className="modal fade"
        id="clientQuotationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "100%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Des dossiers existent déjà pour ce client, vous pouvez ouvrir un dossier existant ou fermer cette fenêtre
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <table className="table">
                <thead>
                  <th>Référence</th>
                  <th>Nombre de devis</th>
                  <th>Date de création</th>
                  <th>Ouvrir</th>
                </thead>
                <tbody>

                  {
                    props.content.map((c, ck) => (
                      <tr key={"lst" + ck}>
                        <td>{
                          c.reference
                        }</td>
                        <td>{c.quotations.length} devis</td>
                        <td>créé le {DateTime.fromISO(c.createdAt).toFormat("dd/MM/yyyy")}</td>
                        <td>

                          <a className="btn btn-primary" href={"/quotations/" + c.id}>Ouvrir</a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

            </div>
            <div className="modal-footer">
              <button
                id="close-client-quotation-modal"
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientQuotationModal;
