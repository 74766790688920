import React from "react";
import useStore from "../../context/useStore";
import { Route, Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [state, dispatch] = useStore();

  return state.auth.isLoading ? (
    <ClipLoader />
  ) : (
    <Route
      {...rest}
      render={props =>
        state.auth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
