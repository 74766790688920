import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import Loader from "../../common/Loader";
import CustomList from "../../common/CustomList"
import CustomPagination from "../../common/CustomPagination"
import CustomSearch from "../../common/CustomSearch"

const BrandModelList = () => {
  const [state, dispatch] = useStore();

  var actions = brandModelActions(state, dispatch);

  return <CustomList getAction={actions.getBrandModels}>
    {(activePage, handlePageParamsChange, search) => (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Modèles et finitions</h1>
            {state.brandModel.isLoading ? (
              <Loader />
            ) : (
                <Link className="btn btn-primary" to="/brandmodels/create">
                  <i className="fa fa-plus mr-2" /> Ajouter un modèle
                </Link>
              )}
            <CustomSearch
              search={search}
              handlePageParamsChange={handlePageParamsChange}
            />

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Marque</th>
                  <th>Modèle</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {state.brandModel.items.map((brandModel, k) => (
                  <tr key={"brand-row-" + k}>
                    <td>{brandModel.brand.name}</td>
                    <td>{brandModel.name}</td>
                    <td>
                      <Link
                        className="btn btn-secondary"
                        to={"/brandmodels/" + brandModel.id}
                      >
                        Modifier
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <CustomPagination
              total={state.brandModel.total}
              activePage={activePage}
              handlePageParamsChange={handlePageParamsChange}
            />


          </div>
        </div>
      </div>
    )}
  </CustomList>
};

export default BrandModelList;
