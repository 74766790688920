import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { FINANCE_TYPES } from "../reducers/financeReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const financeActions = (state, dispatch, props) => {
  function getFinances(search = {search: null}) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });
    
    search = {
      finance: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "finances/search", search)
      .then(res => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCES,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }
  function getFinance(id) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "finances/" + id)
      .then(res => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCE,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }
  function createFinance(financeData) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "finances", financeData)
      .then(res => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCE,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          res.data.reference + " a été correctement créé."
        );
        props.history.push("/finances/" + res.data.id);
      })
      .catch(err => {
        console.log(err);
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }

  function updateFinance(financeData) {
    dispatch({ type: FINANCE_TYPES.LOAD_FINANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "finances/" + financeData.id, financeData)
      .then(res => {
        dispatch({
          type: FINANCE_TYPES.GET_FINANCE,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          financeData.reference + " a été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          FINANCE_TYPES.LOAD_FINANCE
        );
      });
  }

  return {
    createFinance,
    updateFinance,
    getFinances,
    getFinance
  };
};
