export const MAINTENANCE_TYPES = {
  LOAD_MAINTENANCE: "LOAD_MAINTENANCE",
  GET_MAINTENANCES: "GET_MAINTENANCES",
  GET_MAINTENANCES_VEHICLE: "GET_MAINTENANCES_VEHICLE",
  GET_MAINTENANCE: "GET_MAINTENANCE",
  UPDATE_MAINTENANCE_DATA: "UPDATE_MAINTENANCE_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    name: "",
    brand: "",
    brandModels: [],
    tableValues: {
      km: [],
      dur: [],
      values: {}
    },
    fuelTypes: [],
    isArchived: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MAINTENANCE_TYPES.LOAD_MAINTENANCE:
      return {
        ...state,
        isLoading: action.payload
      };
    case MAINTENANCE_TYPES.GET_MAINTENANCES:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.count,
        item: initialState.item,
        isLoading: false
      };
    case MAINTENANCE_TYPES.GET_MAINTENANCES_VEHICLE:
      return {
        ...state,
        items: action.payload,
        item: initialState.item,
        isLoading: false
      };

    case MAINTENANCE_TYPES.GET_MAINTENANCE:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };

    case MAINTENANCE_TYPES.UPDATE_MAINTENANCE_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
