import React, { useEffect, useState } from "react";
import useStore from "../../context/useStore";
import { upkeepActions } from "../../context/actions/upkeepActions";
import Control from "./Control";
import ItemList from "./ItemList";
import tools from "../../helpers/tools";
import KmChart from "./KmChart";
import Loader from "./Loader";

const UpkeepList = ({ vehicle }) => {
  const [state, dispatch] = useStore();
  const [km, setKm] = useState("");

  const upkeepData = state.upkeep.items;
  console.log("upkeepData", upkeepData);
  var v_actions = upkeepActions(state, dispatch);

  useEffect(() => {
    v_actions.getVehicleUpkeeps(vehicle);
  }, []);

  return !upkeepData ? (
    <Loader />
  ) : (
    <div>
      <h2>Entretiens</h2>
      <h4>
        Total :{" "}
        {upkeepData.length > 0 &&
          tools.beautiFyNumber(upkeepData[upkeepData.length - 1].km)}{" "}
        Km
      </h4>
      <Control
        label="Nombre de kilomètres"
        name="km"
        value={km}
        change={e => setKm(e.target.value)}
        suffix="km"
      />
      <button
        className="btn btn-secondary mb-4"
        onClick={() =>
          v_actions.createUpkeep({
            km,
            type: 1,
            vehicle: vehicle
          })
        }
      >
        <i className="fa fa-plus mr-2" /> Enregistrer
      </button>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="graph-tab"
            data-toggle="tab"
            href="#graph"
            role="tab"
            aria-controls="graph"
            aria-selected="true"
          >
            Graphique
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link "
            id="table-tab"
            data-toggle="tab"
            href="#table"
            role="tab"
            aria-controls="table"
            aria-selected="true"
          >
            Tableau
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className="tab-pane fade show active"
          id="graph"
          role="tabpanel"
          aria-labelledby="graph-tab"
        >
          {upkeepData.length > 0 && <KmChart dataToReverse={upkeepData} />}
        </div>
        <div
          className="tab-pane fade"
          id="table"
          role="tabpanel"
          aria-labelledby="table-tab"
        >
          <ItemList
            items={upkeepData}
            fields={[
              { name: "createdAt", type: "Date", label: "Créé le" },
              { name: "km", type: "String", label: "Km" }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default UpkeepList;
