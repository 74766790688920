import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import useStore from '../../../context/useStore';
import { quotationActions } from '../../../context/actions/quotationActions';
import { tireActions } from '../../../context/actions/tireActions';
import Loader from '../../common/Loader';
import { DateTime } from 'luxon';
import { QUOTATION_TYPES } from '../../../context/reducers/quotationReducers';
import tools from '../../../helpers/tools';
import vehicleTools from '../../../helpers/vehicleTools';
import CatalogModal from '../../common/CatalogModal';
import ClientModal from '../../common/ClientModal';
import InputGroupWithResetValue from '../../common/InputGroupWithResetValue';
import Control from '../../common/Control';
import QuotationFinalValues from './QuotationFinalValues';
import GenerateContractModal from './GenerateContractModal';
import QuotationSort from './QuotationSort';
import QuotationFilter from './QuotationFilter';
import OverlayContent from '../../common/OverlayContent';
import QuotationPdf from './QuotationPdf';
import QuotationRecapPdf from './QuotationRecapPdf';
import Axios from 'axios';
import { api_url } from '../../../config/config';
import ClientQuotationModal from '../../common/ClientQuotationModal';

const QuotationDetail = (props) => {
    const [state, dispatch] = useStore();
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [dur, setDur] = useState('0');
    const [km, setKm] = useState('0');
    const [quotationOpened, setQuotationOpened] = useState({ q: null, i: null });
    const [overlayOpened, setOverlayOpened] = useState(null);
    const [sort, setSort] = useState([]);
    const [filters, setFilters] = useState({
        brand: '',
        brandModel: '',
        duration: '',
        km: '',
        status: '',
        finish: '',
    });
    const [hideKeys, setHideKeys] = useState([]);
    const [pdfOpen, setPdfOpen] = useState(false);
    const [recapOpen, setRecapOpen] = useState(false);
    const [quotationSelected, setQuotationSelected] = useState([]);
    const [tableWidth, setTableWidth] = useState(100);
    const [openClientQuotationModal, setOpenClientQuotationModal] = useState(false);
    const [clientQuotations, setClientQuotations] = useState([]);

    var authData = state.auth;
    var quotationData = state.quotation.item;
    var constantData = state.constant.items;

    const [clientData, setClientData] = useState([]);
    var tireData = state.tire.items;
    var actions = quotationActions(state, dispatch, props);
    var t_actions = tireActions(state, dispatch, props);

    useEffect(() => {
        if (quotationData.client) {
            if (!selectedClient) {
                setSelectedClient(clientData.find((c) => quotationData.client == c.id));
            }
        }
    }, [quotationData, clientData]);
    useEffect(() => {
        if (selectedClient && props.match.params.id == 'create') {
            Axios.get(api_url + 'quotations/client/' + selectedClient.id)
                .then((res) => {
                    if (res.data.length > 0) {
                        setOpenClientQuotationModal(true);
                        setClientQuotations(res.data);
                        window.$('#clientQuotationModal').modal('show');
                    }
                })
                .catch((err) => {
                    if (err.response && err.response.data) console.log(err.response.data);
                });
        }
    }, [selectedClient]);
    useEffect(() => {
        var sortQuotations = tools.sortQuotations(sort, quotationData.quotations);
        inputChange({ target: { name: 'quotations', value: sortQuotations } });
    }, [sort]);
    useEffect(() => {
        setHideKeys(tools.filterQuotations(filters, quotationData.quotations));
    }, [filters]);
    useEffect(() => {
        if (props.match.params.id != 'create') {
            actions.getQuotation(props.match.params.id);
        }
        t_actions.getTires();
        Axios.get(api_url + 'clients')
            .then((res) => {
                setClientData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    useEffect(() => {
        if (selectedClient && quotationData.client !== selectedClient.id) {
            inputChange({ target: { name: 'client', value: selectedClient.id } });
        }

        if (
            selectedVehicle &&
            (!quotationData.vehicle || quotationData.vehicle.id !== selectedVehicle.id)
        ) {
            inputChange({ target: { name: 'vehicle', value: selectedVehicle } });
        }
    }, [selectedClient, selectedVehicle]);
    useEffect(() => {
        dispatch({
            type: QUOTATION_TYPES.RESET_QUOTATION,
        });
    }, []);
    useEffect(() => {
        if (overlayOpened != null) {
            document.getElementById('trrl' + overlayOpened).scrollIntoView();
        }
    }, [overlayOpened]);

    function inputChange(e) {
        tools.inputChange(e, QUOTATION_TYPES.UPDATE_QUOTATION_DATA, dispatch);
    }
    function rlChange(e, k) {
        console.log('rlChange', e.target.name, e.target.value);
        var { quotations } = quotationData;
        if (
            (e.target.name == 'repurchaseValueFinal' || e.target.name == 'baseRentHT') &&
            e.target.value == 0
        ) {
            return false;
        }
        if (e.target.name == 'marginTTCRate') {
            if (
                parseFloat(e.target.value) < parseFloat(constantData.MARGIN_RATE_MIN) &&
                authData.user.role != 'SUPER_ADMIN'
            ) {
                e.target.value = constantData.MARGIN_RATE_MIN;
            }
            quotations[k]['marginTTC'] = vehicleTools.calcMargin(
                quotations[k].vehicle['investAmtHT'],
                e.target.value
            );
        }
        if (e.target.name == 'marginTTC') {
            var marginRate = vehicleTools.calcMarginRate(
                quotations[k].vehicle['investAmtHT'],
                e.target.value
            );

            if (parseFloat(marginRate) < parseFloat(constantData.MARGIN_RATE_MIN)) {
                if (authData.user.role != 'SUPER_ADMIN') {
                    e.target.value = vehicleTools.calcMargin(
                        quotations[k].vehicle['investAmtHT'],
                        quotations[k].marginTTCRate
                    );
                } else {
                    quotations[k]['marginTTCRate'] = marginRate;
                }
            } else {
                quotations[k]['marginTTCRate'] = marginRate;
            }
        }
        if (e.target.name == 'replacementVehicleCost' && parseFloat(e.target.value) < 0) {
            e.target.value = 0;
        }
        if (
            e.target.name == 'financeRate' &&
            parseFloat(e.target.value) < parseFloat(constantData.FINANCE_RATE_MIN)
        ) {
            e.target.value = constantData.FINANCE_RATE_MIN;
        }
        var { quotations } = quotationData;
        quotations[k][e.target.name] = e.target.value;
        if (e.target.name == 'customTirePrice') {
            var tirePrice =
                Math.round(
                    ((parseFloat(e.target.value) * parseFloat(quotations[k].nbTires)) /
                        parseFloat(quotations[k].dur)) *
                        100
                ) / 100;
            if (quotations[k].benefits.find((bn) => bn.name == 'Pneus')) {
                quotations[k].benefits.find((bn) => bn.name == 'Pneus').priceHT = tirePrice;
            } else {
                quotations[k].benefits.push({ name: 'Pneus', priceHT: tirePrice });
            }
        }
        if (e.target.name == 'nbTires') {
            if (tireData.find((t) => t.id == quotations[k].vehicle.tire)) {
                var tirePrice = tireData.find((t) => t.id == quotations[k].vehicle.tire).price;
                quotations[k].customTirePrice = tirePrice;
                tirePrice =
                    Math.round(
                        ((parseFloat(tirePrice) * parseFloat(e.target.value)) /
                            parseFloat(quotations[k].dur)) *
                            100
                    ) / 100;
                if (quotations[k].benefits.find((bn) => bn.name == 'Pneus')) {
                    quotations[k].benefits.find((bn) => bn.name == 'Pneus').priceHT = tirePrice;
                } else {
                    quotations[k].benefits.push({ name: 'Pneus', priceHT: tirePrice });
                }
            } else {
                quotations[k][e.target.name] = 0;
            }
        }

        inputChange({ target: { name: 'quotations', value: quotations } });
    }
    function rlVehicleChange(e, k) {
        var { quotations } = quotationData;
        quotations[k].vehicle[e.target.name] = e.target.value != '' ? e.target.value : 0;
        var rmOptions = quotations[k].vehicle.optionsNotIncluded.reduce((a, b) => {
            if (b.isInclRM) {
                return a + parseFloat(b.priceHT) * 1.085;
            } else {
                return a;
            }
        }, 0);
        if (e.target.name == 'discount') {
            quotations[k].vehicle['discountPct'] =
                Math.round(
                    (parseFloat(e.target.value) /
                        (quotations[k].vehicle.pricePublicTTC + rmOptions)) *
                        100 *
                        100
                ) / 100;
        }
        if (e.target.name == 'discountPct') {
            quotations[k].vehicle['discount'] =
                Math.round(
                    ((parseFloat(e.target.value) *
                        (quotations[k].vehicle.pricePublicTTC + rmOptions)) /
                        100) *
                        100
                ) / 100;
        }
        if (e.target.name == 'priceNetTTC' && isNaN(e.target)) {
            return false;
        }
        inputChange({ target: { name: 'quotations', value: quotations } });
    }
    function setQuotationStatus(k, status, quantity = 0, caracs = []) {
        var sid = props.match.params.id;
        var qid = quotationData.quotations[k]._id;

        if (status == 3) {
            if (!parseInt(quantity) > 0) return false;
        }

        actions.updateQuotationStatus(sid, qid, status, parseInt(quantity), caracs);
    }
    function deleteQuotation(k) {
        if (
            window.confirm(
                'Êtes-vous sûr de vouloir supprimer cette entrée ? Pour que la suppression soit définitive, cliquez sur OK puis sur le bouton enregistrer'
            )
        ) {
            var { quotations } = quotationData;
            quotations = quotations.filter((q, i) => k !== i);
            setOverlayOpened(null);
            inputChange({ target: { name: 'quotations', value: quotations } });
        }
    }
    function addRollingLaw() {
        var { quotations } = quotationData;

        var finishName = selectedVehicle.brandModel.finishes.find(
            (b) => b._id == selectedVehicle.finish
        ).name;

        let quotationVehicle = {
            ...selectedVehicle,
            pricePublicTTC: '0',
            investAmtHT: '0',
            priceNetTTC: '0',
        };
        // quotationVehicle.discountHT = quotationVehicle.discount
        if (quotationVehicle.type == 1) {
            quotationVehicle.discount = Math.round(quotationVehicle.discount * 1.085 * 100) / 100;
        }

        var newQuotation = {
            vehicle: quotationVehicle,
            quantity: 1,
            reference:
                selectedVehicle.brand.name +
                ' ' +
                selectedVehicle.brandModel.name +
                ' ' +
                (constantData.VEHICLE_TYPES.find((c) => c.id == selectedVehicle.type).id == 1
                    ? 'VP'
                    : 'VU') +
                ' ' +
                finishName +
                ' ' +
                dur +
                '/' +
                km,
            status: 1,
            insuranceRate: null,
            firstRentInput: '0',
            vehicleCategory: 'B',
            franchiseDommage: '0',
            nbTires: '0',
            customTirePrice: '0',
            baseRentHT: '0',
            replacementVehicleCost: parseFloat(constantData.REPLACEMENT_VEHICULE_COST_DEFAULT),
            marginTTCRate: parseFloat(constantData.MARGIN_RATE_DEFAULT),
            marginTTC: '0',
            discount: '0',
            financeRate: null,
            applicationFee: null,
            state: '1',
            dur: dur,
            km: km,
            rateVR: '0',
            repurchase: null,
            repurchaseData: [],
            kmPrice: '-1',
            repurchaseValueFinal: '0',
            maintenance: null,
            rateMaintenance: '0',
            discountMaintenance: '0',
            maintenanceValueFinal: '-1',
            maintenanceData: [],
            financeRent: '0',
            insuranceHT: '0',
            insuranceFinLoss: '0',
            totalRentHT: '0',
            totalRentTTC: '0',
            benefits: [],
            mentions: constantData.QUOTATION_MENTIONS.map((q) => {
                return {
                    name: q.name,
                };
            }),
            comment: '',
        };

        quotations.push(newQuotation);
        setOverlayOpened(quotations.length - 1);
        inputChange({ target: { name: 'quotations', value: quotations } });
    }
    function toggleOverlay(index, toggle) {
        if (toggle) {
            setOverlayOpened(index);
        } else {
            setOverlayOpened(null);
        }
    }
    function navQuotation(direction) {
        setOverlayOpened(overlayOpened + (direction ? 1 : -1));
    }
    function save(type) {
        if (missingData()) return false;
        if (type == 'create') {
            actions.createQuotation(state.quotation.item);
        }
        if (type == 'update') {
            actions.updateQuotation(state.quotation.item);
        }
    }
    function missingData() {
        var isError = false;
        state.quotation.item.quotations.forEach((q, k) => {
            if (parseFloat(q.repurchaseValueFinal) <= 0) {
                alert(
                    'La valeur de rachat est manquante pour le dossier N°' +
                        (k + 1) +
                        ' - ' +
                        q.reference
                );
                isError = true;
            }
            if (parseFloat(q.maintenanceValueFinal) < 0 || q.maintenanceValueFinal + '' === '') {
                alert(
                    'La valeur de maintenance est manquante (' +
                        q.maintenanceValueFinal +
                        ') pour le dossier N°' +
                        (k + 1) +
                        ' - ' +
                        q.reference
                );
                isError = true;
            }
            if (parseInt(q.totalRentHT) <= 0) {
                alert('Le loyer semble erroné pour le dossier N°' + (k + 1) + ' - ' + q.reference);
                isError = true;
            }
        });
        return isError;
    }
    function openPdf(quotation) {
        setPdfOpen(quotation);
    }
    function openPdfQuotationList() {
        var qs = [];
        quotationData.quotations.forEach((q) => {
            if (quotationSelected.find((qq) => qq._id == q._id)) {
                qs.push(q);
            }
        });

        setRecapOpen(qs);
    }
    function duplicateQuotation(index) {
        var { quotations } = quotationData;
        var copy = JSON.parse(JSON.stringify(quotations[index]));
        copy.id = null;
        copy.status = 1;
        quotations.push(copy);
        inputChange({ target: { name: 'quotations', value: quotations } });
    }
    function changeLineOrder(dir, index) {
        var quots = JSON.parse(JSON.stringify(quotationData));
        var newIndex = dir == 'up' ? index + 1 : index - 1;
        newIndex =
            newIndex > quotationData.quotations.length - 1
                ? quotationData.quotations.length - 1
                : newIndex;
        newIndex = newIndex < 0 ? 0 : newIndex;
        quots.quotations = array_move(quots.quotations, index, newIndex);

        inputChange({ target: { name: 'quotations', value: quots.quotations } });
    }
    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    }

    return (
        <div className="container-fluid" style={{ marginBottom: 800 }}>
            <div className="row">
                <div className="col-12">
                    <h1>Simulateur de dossier</h1>

                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="card p-4">
                                <h2>
                                    Identification{' '}
                                    {quotationData.reference && '(' + quotationData.reference + ')'}
                                </h2>

                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="customer">
                                                Client<span className="text-danger ml-1">*</span>
                                            </label>
                                            <div>
                                                <ClientModal
                                                    selectedClient={selectedClient}
                                                    selectClient={setSelectedClient}
                                                />

                                                <small className="form-text text-danger">
                                                    {state.error.client}
                                                </small>
                                                <ClientQuotationModal
                                                    content={clientQuotations}
                                                    openClientQuotationModal={
                                                        openClientQuotationModal
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12  no-print">
                            <div
                                className="card p-4"
                                style={{ background: 'transparent', border: 0 }}
                            >
                                <div className="row">
                                    <div className="col-2">
                                        <h4>Trier</h4>
                                        <QuotationSort sort={sort} setSort={setSort} />
                                    </div>
                                    <div className="col-10">
                                        <h4>Filtrer</h4>
                                        <QuotationFilter
                                            filters={filters}
                                            setFilters={setFilters}
                                            constants={constantData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card p-4" style={{ overflowX: 'scroll' }}>
                                <h2 className="d-flex flex-row align-items-center justify-content-between">
                                    <span>Lois de roulage</span>

                                    <div className="form-group">
                                        <label htmlFor="formControlRange" style={{ fontSize: 14 }}>
                                            Largeur du tableau : {tableWidth}%
                                        </label>
                                        <input
                                            min="100"
                                            max="200"
                                            step="1"
                                            value={tableWidth}
                                            onChange={(e) => setTableWidth(e.target.value)}
                                            type="range"
                                            className="form-control-range"
                                            id="formControlRange"
                                        />
                                    </div>
                                </h2>
                                <div className="accordion" id="quotation-accordion">
                                    <table
                                        className="table"
                                        id="quotable"
                                        style={{ width: tableWidth + '%' }}
                                    >
                                        <thead className="head-sm">
                                            <tr>
                                                <th>Sélect.</th>
                                                <th>Véhicule</th>
                                                <th>Actions</th>
                                                <th>Quantité</th>
                                                <th>Prix public TTC (hors CG/BM)</th>
                                                <th>Remise sur prix TTC</th>
                                                <th>Prix net TTC</th>
                                                <th>Montant invest HT</th>
                                                <th>Marge TTC</th>
                                                <th>Loyer de base HT</th>
                                                <th>Véhicule relais</th>
                                                <th>Assurance HT</th>
                                                <th>Assurance PF</th>
                                                <th>Autres prestations</th>
                                                <th>Total loyer HT</th>
                                                <th>Total loyer TTC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {quotationData.quotations.length > 0 ? (
                                                quotationData.quotations.map((rl, k) => (
                                                    <QuotationFinalValues
                                                        key={'qfv' + k}
                                                        index={k}
                                                        quotation={rl}
                                                        setQuotationStatus={setQuotationStatus}
                                                        rlVehicleChange={(e) =>
                                                            rlVehicleChange(e, k)
                                                        }
                                                        deleteQuotation={() => deleteQuotation(k)}
                                                        rlChange={(e) => rlChange(e, k)}
                                                        setQuotationOpened={() =>
                                                            setQuotationOpened({ q: rl, i: k })
                                                        }
                                                        toggleOverlay={toggleOverlay}
                                                        overlayOpened={overlayOpened}
                                                        navQuotation={navQuotation}
                                                        totalQuotation={
                                                            quotationData.quotations.length
                                                        }
                                                        hideKeys={hideKeys}
                                                        tireData={tireData}
                                                        openPdf={() => openPdf(rl)}
                                                        setQuotationSelected={setQuotationSelected}
                                                        isCreate={props.match.params.id == 'create'}
                                                        duplicateQuotation={duplicateQuotation}
                                                        changeLineOrder={changeLineOrder}
                                                        tableWidth={tableWidth}
                                                    />
                                                ))
                                            ) : state.quotation.isLoading ? (
                                                <tr className="text-center col-12">
                                                    <td colSpan="13">
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <p className="text-muted">
                                                    Aucune loi de roulage n'a encore été ajoutée
                                                </p>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 p-0  no-print"
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                width: '100%',
                                zIndex: 99,
                                boxShadow: 'grey 0px 0px 20px -6px',
                            }}
                        >
                            <div className="card p-2">
                                <div className="m-auto">
                                    <div className="mr-2 d-inline-block">
                                        <Control
                                            name="dur"
                                            id="dur"
                                            label="Durée"
                                            value={dur}
                                            change={(e) => setDur(e.target.value)}
                                        />
                                    </div>
                                    <div className="mr-2 d-inline-block">
                                        <Control
                                            name="km"
                                            id="km"
                                            label="Kilométrage"
                                            value={km}
                                            change={(e) => setKm(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group mr-2 d-inline-block">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-toggle="modal"
                                            data-target="#catalogModal"
                                        >
                                            {selectedVehicle
                                                ? selectedVehicle.brand.name +
                                                  ' ' +
                                                  selectedVehicle.brandModel.name
                                                : 'Choisir un véhicule'}
                                        </button>
                                        <small className="form-text text-danger">
                                            {state.error.vehicle}
                                        </small>
                                    </div>
                                    <button
                                        className={
                                            'btn btn-secondary mr-2 ' +
                                            (dur != '0' && km != '0' && selectedVehicle
                                                ? ''
                                                : 'disabled')
                                        }
                                        onClick={
                                            dur != '0' && km != '0' && selectedVehicle
                                                ? addRollingLaw
                                                : () => {}
                                        }
                                    >
                                        <i className="fa fa-plus mr-2" />
                                        Ajouter une loi de roulage
                                    </button>
                                    {state.quotation.isLoading ? (
                                        <Loader />
                                    ) : props.match.params.id == 'create' ? (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => save('create')}
                                        >
                                            Créer
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => save('update')}
                                        >
                                            Enregistrer
                                        </button>
                                    )}
                                    {quotationSelected.length > 0 && (
                                        <button
                                            type="button"
                                            className="btn btn-success ml-2"
                                            onClick={openPdfQuotationList}
                                        >
                                            <i className="fa fa-file-pdf mr-2" />
                                            Voir les dossiers sélectionnés
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GenerateContractModal
                quotation={quotationOpened.q}
                setQuotationStatus={setQuotationStatus}
                index={quotationOpened.i}
            />
            <CatalogModal selectedVehicle={selectedVehicle} selectVehicle={setSelectedVehicle} />
            {pdfOpen && (
                <OverlayContent close={() => setPdfOpen(false)}>
                    <QuotationPdf
                        quotation={pdfOpen}
                        client={clientData.find((c) => quotationData.client == c.id)}
                        date={quotationData.updatedAt}
                    />
                </OverlayContent>
            )}
            {recapOpen && (
                <OverlayContent close={() => setRecapOpen(false)}>
                    <QuotationRecapPdf
                        quotations={recapOpen}
                        client={clientData.find((c) => quotationData.client == c.id)}
                        date={DateTime.local().toFormat('dd/MM/yyyy')}
                        constantData={constantData}
                    />
                </OverlayContent>
            )}
        </div>
    );
};

export default withRouter(QuotationDetail);
