import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import { repurchaseActions } from "../../../context/actions/repurchaseActions";
import Loader from "../../common/Loader";
import { REPURCHASE_TYPES } from "../../../context/reducers/repurchaseReducers";
import tools from "../../../helpers/tools";
import ExcelCopyModal from "../../common/ExcelCopyModal";
import { DateTime } from "luxon";
import AddColumnModal from "./AddColumnModal";
import AddRowModal from "./AddRowModal";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import Control from "../../common/Control";

var brandModelsFetched = false;

const RepurchaseDetail = (props) => {
  const [state, dispatch] = useStore();

  var repurchaseData = state.repurchase.item;
  var brandModelData = state.brandModel.items;
  var brandData = state.brand.items;
  var constantData = state.constant.items;
  var userRole = state.auth.user.role;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  var r_actions = repurchaseActions(state, dispatch, props);

  useEffect(() => {
    b_actions.getAllBrands();
    brandModelsFetched = false;
    dispatch({
      type: BRANDMODEL_TYPES.GET_BRANDMODELS,
      payload: {
        data: [],
      },
    });
    if (props.match.params.id != "create") {
      r_actions.getRepurchase(props.match.params.id);
    }
  }, []);

  if (
    brandModelData.length == 0 &&
    !state.brandModel.isLoading &&
    repurchaseData.brand &&
    !brandModelsFetched
  ) {
    bm_actions.getBrandModelByBrand(repurchaseData.brand);
    brandModelsFetched = true;
  }

  !state.brandModel.isLoading &&
    !brandModelData.length == 0 &&
    !state.brand.isLoading &&
    !brandData.length == 0 &&
    !repurchaseData.brand == "" &&
    !state.repurchase.isLoading &&
    !state.constant.isLoading &&
    parseRepurchaseName();

  function parseRepurchaseName() {
    var brandName = brandData.find((b) => b.id == repurchaseData.brand).name;
    var models = "";
    repurchaseData.brandModels.forEach((bmod) => {
      models += " / " + brandModelData.find((bmd) => bmd.id == bmod).name;
    });
    var fuelTypes = "";
    repurchaseData.fuelTypes.forEach((ft) => {
      fuelTypes += " | " + constantData.FUEL_TYPES.find((f) => f.id == ft).name;
    });
    var from = DateTime.fromJSDate(new Date(repurchaseData.validFrom)).toFormat(
      "dd/MM/yyyy"
    );
    var to =
      repurchaseData.validTo != "" && repurchaseData.validTo
        ? " - " +
          DateTime.fromJSDate(new Date(repurchaseData.validTo)).toFormat(
            "dd/MM/yyyy"
          )
        : "";
    var name = brandName + " " + models + " " + fuelTypes + " " + from + to;
    if (name != repurchaseData.name) {
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "name",
          value: name,
        },
      });
    }
  }
  function inputChange(e) {
    if (e.target.name == "brand") {
      dispatch({
        type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
        payload: {
          key: "brandModels",
          value: [],
        },
      });
      bm_actions.getBrandModelByBrand(e.target.value);
    }
    tools.inputChange(e, REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA, dispatch);
  }
  function toggleBrandModels(e, id) {
    var { brandModels } = repurchaseData;
    if (e.target.checked) {
      brandModels.push(id);
    } else {
      var index = brandModels.indexOf(id);
      brandModels.splice(index, 1);
    }
    dispatch({
      type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "brandModels",
        value: brandModels,
      },
    });
  }
  function toggleFuelTypes(e, id) {
    var { fuelTypes } = repurchaseData;
    if (e.target.checked) {
      fuelTypes.push(id);
    } else {
      var index = fuelTypes.indexOf(id);
      fuelTypes.splice(index, 1);
    }
    dispatch({
      type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "fuelTypes",
        value: fuelTypes,
      },
    });
  }
  function updateTable(excelData) {
    dispatch({
      type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "tableValues",
        value: excelData,
      },
    });
  }
  function tableValueChange(e, col, row) {
    var { tableValues } = repurchaseData;
    tableValues.values[col][row] = e.target.value;
    dispatch({
      type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "tableValues",
        value: tableValues,
      },
    });
  }
  function addColumn(value) {
    var { tableValues } = repurchaseData;
    tableValues.km.push(value);
    pushTableValues(tableValues);
  }
  function addRow(value) {
    var { tableValues } = repurchaseData;
    tableValues.dur.push(value);
    pushTableValues(tableValues);
  }
  function pushTableValues(tableValues) {
    !tableValues.values && (tableValues.values = {});
    tableValues.dur.forEach((dur) => {
      !tableValues.values[dur] && (tableValues.values[dur] = []);
      tableValues.km.forEach((km, k) => {
        !tableValues.values[dur][k] && (tableValues.values[dur][k] = "");
      });
    });
    dispatch({
      type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "tableValues",
        value: tableValues,
      },
    });
  }
  function deleteRow(key) {
    var { tableValues } = repurchaseData;
    delete tableValues.values[key];
    tableValues.dur = tableValues.dur.filter((d) => d !== key);
    dispatch({
      type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "tableValues",
        value: tableValues,
      },
    });
  }
  function deleteCol(key) {
    var { tableValues } = repurchaseData;

    for (var k in tableValues.values) {
      tableValues.values[k] = tableValues.values[k].filter(
        (entry, k) => k !== key
      );
    }
    tableValues.km = tableValues.km.filter((d, k) => k !== key);
    dispatch({
      type: REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "tableValues",
        value: tableValues,
      },
    });
  }

  function formatExcelData(excelData, orientation) {
    var table = {
      dur: [],
      km: [],
      values: {},
    };
    var ex = excelData.split("\n");
    var row = "km";
    var col = "dur";
    if (orientation == 2) {
      row = "dur";
      col = "km";
    }
    table[row] = ex[0].split("	");
    table[row].shift();

    for (var key in ex) {
      if (key == 0) continue;
      var colvals = ex[key].split("	");
      table[col].push(colvals[0]);

      table.values[colvals[0]] = colvals.slice(1);
    }

    if (orientation == 2) {
      var newTableValues = {};
      table[row].forEach((c, k) => {
        newTableValues[k] = [];
      });

      for (var key in table.values) {
        table.values[key].forEach((tv, keyy) => {
          newTableValues[keyy].push(tv);
        });
      }
      table.values = {};
      table[row].forEach((c, k) => {
        table.values[c] = newTableValues[k];
      });
    }

    updateTable(table);
  }

  var tableRows = [];
  for (let key in repurchaseData.tableValues.values) {
    var row = repurchaseData.tableValues.values[key];
    var tds = [];
    row.forEach((r, k) => {
      tds.push(
        <td key={"tdr-" + key + "-" + k}>
          <input
            type="text"
            className="form-control px-1 text-center"
            name={"val"}
            onChange={(e) => tableValueChange(e, key, k)}
            value={r}
          />
        </td>
      );
    });
    tableRows.push(
      <tr key={"trr-" + key}>
        <td className="font-weight-bold">
          <button
            className="btn btn-default px-1 py-0 mr-1 btn-round"
            onClick={() => deleteRow(key)}
          >
            <i style={{ color: "red" }} className="fa fa-minus" />
          </button>
          {key}
        </td>
        {tds}
      </tr>
    );
  }
  tableRows.push(
    <tr key={"trmodal-666666"}>
      <td className="text-center">
        <button
          className="btn btn-secondary"
          data-toggle="modal"
          data-target="#addRowModal"
        >
          <i className="fa fa-plus" />
        </button>
      </td>
    </tr>
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'une valeur de rachat</h1>
          <div className="row">
            <div className="col-12">
              <Control
                label="Nom"
                type="text"
                name="name"
                disabled
                value={repurchaseData.name}
                change={inputChange}
                error={state.error}
              />
              <Control
                label="Marque"
                type="select"
                name="brand"
                change={inputChange}
                value={repurchaseData.brand}
                disabled={
                  (repurchaseData.nextPeriod ||
                    repurchaseData.previousPeriod) &&
                  userRole != "SUPER_ADMIN"
                    ? true
                    : false
                }
                datas={brandData}
                dataIndex="id"
                dataLabel="name"
                error={state.error}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="models">Modèles</label>
                    {brandModelData.map((brandModel, k) => (
                      <Control
                        key={brandModel.id}
                        label={brandModel.name}
                        type="checkbox"
                        name="brandModel"
                        change={toggleBrandModels}
                        checked={
                          repurchaseData.brandModels.find(
                            (bm) => bm == brandModel.id
                          )
                            ? true
                            : false
                        }
                        disabled={
                          (repurchaseData.nextPeriod ||
                            repurchaseData.previousPeriod) &&
                          userRole != "SUPER_ADMIN"
                            ? true
                            : false
                        }
                        k={brandModel.id}
                      />
                    ))}
                    <small className="form-text text-danger">
                      {state.error.brandModels}
                    </small>
                  </div>

                  <Control
                    label="Prix du kilomètre supplémentaire"
                    type="text"
                    name="kmPrice"
                    value={repurchaseData.kmPrice}
                    change={inputChange}
                    error={state.error}
                    suffix="€"
                  />
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="fuelType">Carburant</label>
                    {constantData.FUEL_TYPES.map((fuelType, k) => (
                      <Control
                        key={fuelType.id}
                        label={fuelType.name}
                        type="checkbox"
                        name="brandModel"
                        change={toggleFuelTypes}
                        checked={
                          repurchaseData.fuelTypes.find(
                            (ft) => ft == fuelType.id
                          )
                            ? true
                            : false
                        }
                        disabled={
                          (repurchaseData.nextPeriod ||
                            repurchaseData.previousPeriod) &&
                          userRole != "SUPER_ADMIN"
                            ? true
                            : false
                        }
                        k={fuelType.id}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <Control
                label="Valide du"
                type="date"
                name="validFrom"
                value={DateTime.fromJSDate(
                  new Date(repurchaseData.validFrom)
                ).toFormat("yyyy-MM-dd")}
                change={inputChange}
                error={state.error.validFrom}
                disabled={
                  (repurchaseData.nextPeriod ||
                    repurchaseData.previousPeriod) &&
                  userRole != "SUPER_ADMIN"
                    ? true
                    : false
                }
              />
              <Control
                label="Au"
                type="date"
                name="validTo"
                value={
                  repurchaseData.validTo
                    ? DateTime.fromJSDate(
                        new Date(repurchaseData.validTo)
                      ).toFormat("yyyy-MM-dd")
                    : ""
                }
                change={inputChange}
                error={state.error}
                disabled={
                  (repurchaseData.nextPeriod ||
                    repurchaseData.previousPeriod) &&
                  userRole != "SUPER_ADMIN"
                    ? true
                    : false
                }
              />

              <div className="row">
                <div className="col-6 text-left">
                  {repurchaseData.previousPeriod && (
                    <a
                      className="btn btn-secondary"
                      href={"/repurchases/" + repurchaseData.previousPeriod}
                    >
                      <i className="fa fa-chevron-left mr-2" />
                      Période précédente
                    </a>
                  )}
                </div>
                <div className="col-6 text-right">
                  {repurchaseData.nextPeriod && (
                    <a
                      className="btn btn-secondary"
                      href={"/repurchases/" + repurchaseData.nextPeriod}
                    >
                      Période suivante
                      <i className="fa fa-chevron-right ml-2" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <h3>Tableau des valeurs</h3>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th className="text-center">Durée/Km</th>
                    {repurchaseData.tableValues.km.map((km, k) => (
                      <th className="text-center" key={"km-" + km}>
                        <button
                          className="btn btn-default px-1 py-0 mr-1 btn-round"
                          onClick={() => deleteCol(k)}
                        >
                          <i style={{ color: "red" }} className="fa fa-minus" />
                        </button>
                        {km}
                      </th>
                    ))}
                    <th className="text-center">
                      <button
                        className="btn btn-secondary"
                        data-toggle="modal"
                        data-target="#addColumnModal"
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>{tableRows}</tbody>
              </table>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary mr-2"
            data-toggle="modal"
            data-target="#excelCopyModal"
          >
            Copier depuis excel
          </button>
          {state.repurchase.isLoading ? (
            <Loader />
          ) : props.match.params.id == "create" ? (
            <button
              type="button"
              className="btn btn-success mr-2"
              onClick={() => r_actions.createRepurchase(repurchaseData)}
            >
              Créer
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-success mr-2"
                onClick={() => r_actions.updateRepurchase(repurchaseData)}
              >
                Enregistrer
              </button>
            </>
          )}
        </div>
      </div>
      <ExcelCopyModal formatExcelData={formatExcelData} />
      <AddColumnModal addColumn={addColumn} />
      <AddRowModal addRow={addRow} />
    </div>
  );
};

export default withRouter(RepurchaseDetail);
