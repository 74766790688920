import React from "react";
import useStore from "../../../context/useStore";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import vehicleTools from "../../../helpers/vehicleTools";
import financeTools from "../../../helpers/financeTools";

Font.register({
  family: "Lato Bold",
  src: `/Lato-Bold.ttf`,
});
const styles = StyleSheet.create({
  container: {},
  header: {
    fontSize: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    borderBottomColor: "#d2d2d2",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  rowHead: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    marginTop: 5,
    fontFamily: "Lato Bold",
    borderBottomColor: "black",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    borderColor: "#d2d2d2",
    borderStyle: "solid",
    borderWidth: 1,
    padding: 3,
  },
  columnHead: {
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    fontFamily: "Lato Bold",
    borderColor: "black",
    borderStyle: "solid",
    borderWidth: 1,
    padding: 1,
    textAlign: "center",
  },
  textSmall: {
    fontSize: 7,
  },
  textBig: {
    fontFamily: "Lato Bold",
  },
});

const PaymentTablePdf = ({ quotations, constantData }) => {
  var totals = {
    priceHT: 0,
    optHT: 0,
    marHT: 0,
    cgCarb: 0,
    bm: 0,
    tva: 0,
    priceTTC: 0,
    investAmtHT: 0,
    p1Label: "",
    p1Value: 0,
    p2Label: "",
    p2Value: 0,
    p3Label: "",
    p3Value: 0,
  };

  var colsmw = Math.floor((100 - quotations[0].periods.length * 10) / 10) - 0.5;
  var collgw = Math.floor((100 - colsmw * 10) / quotations[0].periods.length);
  console.log("colsm", colsmw, "collg", collgw);

  const getLine = (q) => {
    let totalPriceHT = 0;
    let vehiclePrices = vehicleTools.calcVehiclePrices(q.vehicle, true);
    let priceHT = Math.round(vehiclePrices.priceHT * 100) / 100;
    let optHT = vehicleTools.calcOptHT(q);
    let vehicleTVA = priceHT * (q.vehicle.type == 2 ? 0 : 0.085);
    let allTVA =
      Math.round((vehicleTVA + vehicleTools.calcTvaMarOpt(q)) * 100) / 100;

    let content = [];
    for (var i = 0; i < q.quantity; i++) {
      totals.priceHT += priceHT;
      totals.optHT += optHT;
      totals.marHT += q.vehicle.inroadCost;
      totals.cgCarb += q.vehicle.greyCardCost;
      totals.bm += q.vehicle.malusBonusEco;
      totals.tva += allTVA;
      totals.priceTTC += q.vehicle.priceNetTTC;
      totals.investAmtHT += q.vehicle.investAmtHT;

      content.push(
        <View key={q._id + i} style={styles.row}>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {q.reference}
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {
              constantData.VEHICLE_TYPES.find((v) => v.id == q.vehicle.type)
                .name
            }
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {priceHT} €
          </Text>

          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {optHT} €
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {q.vehicle.inroadCost} €
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {q.vehicle.greyCardCost} €
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {q.vehicle.malusBonusEco} €
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {allTVA} €
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {Math.round(q.vehicle.priceNetTTC * 100) / 100} €
          </Text>
          <Text style={{ ...styles.column, width: colsmw + "%" }}>
            {q.vehicle.investAmtHT} €
          </Text>

          {q.periods.map((p, k) => {
            var pk = parseInt(k) + 1;
            if (
              (k < q.periods.length - 1 && q.periods.length > 1) ||
              q.periods.length == 1
            ) {
              totals["p" + pk + "Label"] =
                "VR " + (q.vehicle.type == 2 ? "HT" : "TTC") + " : ";
              totals["p" + pk + "Value"] += parseFloat(p.vr);
            }
            if (p.residualValue && k == q.periods.length - 1) {
              totals["p" + pk + "Label"] = "v.residuelle";
              totals["p" + pk + "Value"] += parseFloat(p.residualValue);
            }
            return (
              <View
                key={q._id + i + k}
                style={{ ...styles.column, width: collgw + "%" }}
              >
                <Text>
                  Durée : {p.duration != "" ? p.duration + " mois" : ""}
                </Text>
                {(k < q.periods.length - 1 && q.periods.length > 1) ||
                q.periods.length == 1 ? (
                  <Text>
                    VR {q.vehicle.type == 2 ? "HT" : "TTC"} :{parseFloat(p.vr)}{" "}
                    €
                  </Text>
                ) : (
                  <></>
                )}

                {p.residualValue && k == q.periods.length - 1 && (
                  <View>
                    <Text>{"v. residuelle : " + p.residualValue + " €"}</Text>
                  </View>
                )}
              </View>
            );
          })}
        </View>
      );
    }
    return content;
  };

  return (
    <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
      <Document>
        <Page orientation="landscape" size="A4" style={{ padding: 30 }}>
          <View style={{ flexDirection: "row", marginBottom: 15 }}>
            <View style={{ flexDirection: "column", width: "25%" }}>
              <Image src="/logo.png" style={{ width: "70%" }} />

              <Text style={styles.textSmall}>Tel : 0262 21 20 20</Text>
              <Text style={styles.textSmall}>Fax : 0262 46 28 99</Text>
            </View>
            <View
              style={{
                flexDirection: "column",
                width: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>Demande de financement</Text>
            </View>
          </View>

          <View style={styles.rowHead}>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              Véhicule
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              Type
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              Prix net HT
            </Text>

            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              Options HT
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              MAR HT
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              CG + Carburant
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              Bonus/Malus
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              TVA 8.5%
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              Prix net TTC
            </Text>
            <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
              Montant invest HT
            </Text>
            <Text style={{ ...styles.columnHead, width: collgw + "%" }}>
              Période 1
            </Text>
            {quotations[0].periods.length > 1 && (
              <Text style={{ ...styles.columnHead, width: collgw + "%" }}>
                Période 2
              </Text>
            )}
            {quotations[0].periods.length == 3 && (
              <Text style={{ ...styles.columnHead, width: collgw + "%" }}>
                Période 3
              </Text>
            )}
          </View>

          {quotations.map((q) => getLine(q))}
          {
            <View style={styles.row}>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                TOTAUX
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                -
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.priceHT * 100) / 100 + "€"}
              </Text>

              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.optHT * 100) / 100 + "€"}
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.marHT * 100) / 100 + "€"}
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.cgCarb * 100) / 100 + "€"}
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.bm * 100) / 100 + "€"}
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.tva * 100) / 100 + "€"}
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.priceTTC * 100) / 100 + "€"}
              </Text>
              <Text style={{ ...styles.columnHead, width: colsmw + "%" }}>
                {Math.round(totals.investAmtHT * 100) / 100 + "€"}
              </Text>
              <Text style={{ ...styles.columnHead, width: collgw + "%" }}>
                {totals.p1Label +
                  " " +
                  Math.round(totals.p1Value * 100) / 100 +
                  " €"}
              </Text>

              {quotations[0].periods.length > 1 && (
                <Text style={{ ...styles.columnHead, width: collgw + "%" }}>
                  {totals.p2Label +
                    " " +
                    (totals.p2Value > 0 ? totals.p2Value + " €" : "")}
                </Text>
              )}
              {quotations[0].periods.length == 3 && (
                <Text style={{ ...styles.columnHead, width: collgw + "%" }}>
                  {totals.p3Label +
                    " " +
                    (totals.p3Value > 0 ? totals.p3Value + " €" : "")}
                </Text>
              )}
            </View>
          }
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default PaymentTablePdf;
