import React from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";

const Dashboard = () => {
  const [state, dispatch] = useStore();
  var authData = state.auth;
  const isAdmin =
    authData.user.role == "ADMIN" || authData.user.role == "SUPER_ADMIN";

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12">
          <h2>CONSTANTES</h2>
          <div className="row">
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link to="/brands" className="btn btn-lg btn-default w-100">
                  Marques
                </Link>
                <Link to="/brands/create" className="btn btn-lg btn-primary">
                  <i className="fa fa-plus" />
                </Link>
              </div>
            </div>
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link
                  to="/brandmodels"
                  className="btn btn-lg btn-default w-100"
                >
                  Modèles et finitions
                </Link>
                <Link
                  to="/brandmodels/create"
                  className="btn btn-lg btn-primary"
                >
                  <i className="fa fa-plus" />
                </Link>
              </div>
            </div>
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link to="/tires" className="btn btn-lg btn-default w-100">
                  Pneus
                </Link>
                <Link to="/tires" className="btn btn-lg btn-primary">
                  <i className="fa fa-list" />
                </Link>
              </div>
            </div>
            {isAdmin && (
              <>
                <div className="col-4 text-left">
                  <div className="btn-group" style={{ width: "100%" }}>
                    <Link
                      to="/repurchases"
                      className="btn btn-lg btn-default w-100"
                    >
                      Valeurs de rachat
                    </Link>
                    <Link
                      to="/repurchases/create"
                      className="btn btn-lg btn-primary"
                    >
                      <i className="fa fa-plus" />
                    </Link>
                  </div>
                </div>
                <div className="col-4 text-left">
                  <div className="btn-group" style={{ width: "100%" }}>
                    <Link
                      to="/maintenances"
                      className="btn btn-lg btn-default w-100"
                    >
                      Maintenances
                    </Link>
                    <Link
                      to="/maintenances/create"
                      className="btn btn-lg btn-primary"
                    >
                      <i className="fa fa-plus" />
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h2>VÉHICULES</h2>
          <div className="row">
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link to="/catalogs" className="btn btn-lg btn-default w-100">
                  Catalogue
                </Link>
                <Link to="/catalogs/create" className="btn btn-lg btn-primary">
                  <i className="fa fa-plus" />
                </Link>
              </div>
            </div>
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link to="/vehicles" className="btn btn-lg btn-default w-100">
                  Flotte
                </Link>
                <Link to="/vehicles/create" className="btn btn-lg btn-primary">
                  <i className="fa fa-plus" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <h2>OUTILS</h2>
          <div className="row">
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link to="/quotations" className="btn btn-lg btn-default w-100">
                  Devis
                </Link>
                <Link
                  to="/quotations/create"
                  className="btn btn-lg btn-primary"
                >
                  <i className="fa fa-plus" />
                </Link>
              </div>
            </div>
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link to="/contracts" className="btn btn-lg btn-default w-100">
                  Contrats
                </Link>
                <Link to="/contracts" className="btn btn-lg btn-primary">
                  <i className="fa fa-list" />
                </Link>
              </div>
            </div>
            {isAdmin && (
              <div className="col-4 text-left">
                <div className="btn-group" style={{ width: "100%" }}>
                  <Link to="/finances" className="btn btn-lg btn-default w-100">
                    Financements
                  </Link>
                  <Link
                    to="/finances/create"
                    className="btn btn-lg btn-primary"
                  >
                    <i className="fa fa-plus" />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <h2>Personnes</h2>
          <div className="row">
            <div className="col-4 text-left">
              <div className="btn-group" style={{ width: "100%" }}>
                <Link to="/clients" className="btn btn-lg btn-default w-100">
                  Clients
                </Link>
                <Link to="/clients/create" className="btn btn-lg btn-primary">
                  <i className="fa fa-plus" />
                </Link>
              </div>
            </div>
            {isAdmin && (
              <div className="col-4 text-left">
                <div className="btn-group" style={{ width: "100%" }}>
                  <Link to="/users" className="btn btn-lg btn-default w-100">
                    Utilisateurs
                  </Link>
                  <Link to="/users/create" className="btn btn-lg btn-primary">
                    <i className="fa fa-plus" />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
