import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { CLIENT_TYPES } from "../reducers/clientReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";
import FileDownload from "js-file-download";

export const clientActions = (state, dispatch, props) => {
  function getClients(search = {search: null}) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });
    
    search = {
      client: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "clients/search", search)
      .then(res => {
        dispatch({ type: CLIENT_TYPES.GET_CLIENTS, payload: res.data });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function getClient(id) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "clients/" + id)
      .then(res => {
        dispatch({
          type: CLIENT_TYPES.GET_CLIENT,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function createClient(clientData) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "clients", clientData)
      .then(res => {
        dispatch({
          type: CLIENT_TYPES.GET_CLIENT,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          clientData.name + " a été correctement créé."
        );
        if (props.modal) {
          props.selectClient(res.data);
        } else {
          props.history.push("/clients/" + res.data.id);
        }
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function updateClient(clientData) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "clients/" + clientData.id, clientData)
      .then(res => {
        dispatch({
          type: CLIENT_TYPES.GET_CLIENT,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          clientData.name + " a été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }
  function getClientCGV(client) {
    dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios({
      url: api_url + "clients/" + client.id + "/cgv",
      method: "GET",
      responseType: "blob",
    })
      .then(res => FileDownload(res.data, client.name + "_CGV.docx"))
      .then(res => dispatch({ type: CLIENT_TYPES.LOAD_CLIENT, payload: false }))
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          CLIENT_TYPES.LOAD_CLIENT
        );
      });
  }

  return {
    createClient,
    updateClient,
    getClients,
    getClient,
    getClientCGV
  };
};
