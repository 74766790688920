import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { QUOTATION_TYPES } from "../reducers/quotationReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const quotationActions = (state, dispatch, props) => {
  function getQuotations(search = { search: null }) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      quotation: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "quotations/search", search)
      .then(res => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATIONS,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }
  function getValidQuotations() {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "quotations/valid")
      .then(res => {
        dispatch({
          type: QUOTATION_TYPES.GET_VALID_QUOTATIONS,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }
  function getQuotation(id) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "quotations/" + id)
      .then(res => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }
  function createQuotation(quotationData) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "quotations", quotationData)
      .then(res => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          quotationData.reference + " a été correctement créé."
        );
        props.history.push("/quotations/" + res.data.id);
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }

  function updateQuotation(quotationData) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "quotations/" + quotationData.id, quotationData)
      .then(res => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          quotationData.reference + " a été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }

  function updateQuotationStatus(sid, qid, status, quantity = 1, caracs = []) {
    dispatch({ type: QUOTATION_TYPES.LOAD_QUOTATION, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });
    console.log("cacars", caracs);
    axios
      .patch(
        api_url +
        "quotations/" +
        sid +
        "/quotation/" +
        qid +
        "/status/" +
        status,
        { quantity, caracs }
      )
      .then(res => {
        dispatch({
          type: QUOTATION_TYPES.GET_QUOTATION,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          "L'entrée a été correctement mise à jour."
        );
      })
      .catch(err => {
        console.log(err.response)
        alert(err.response)
        tools.filterError(
          dispatch,
          err.response.data,
          QUOTATION_TYPES.LOAD_QUOTATION
        );
      });
  }

  return {
    createQuotation,
    updateQuotation,
    getQuotations,
    getQuotation,
    updateQuotationStatus,
    getValidQuotations
  };
};
