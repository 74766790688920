import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { BRAND_TYPES } from "../reducers/brandReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const brandActions = (state, dispatch, props) => {
  function getAllBrands(search = {search: null}) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      brand: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .get(api_url + "brands")
      .then(res => {
        dispatch({type: BRAND_TYPES.GET_BRANDS,payload: {data:res.data}});
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND)
      });
  }
  function getBrands(search = {search: null}) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      brand: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "brands/search", search)
      .then(res => {
        dispatch({type: BRAND_TYPES.GET_BRANDS,payload: res.data});
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND)
      });
  }
  function getBrand(id) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "brands/" + id)
      .then(res => {
        dispatch({
          type: BRAND_TYPES.GET_BRAND,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND)
      });
  }
  function createBrand(brandData) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });
    
    axios
      .post(api_url + "brands", brandData)
      .then(res => {
        dispatch({
          type: BRAND_TYPES.GET_BRAND,
          payload: res.data
        });
        tools.filterAlert("success", brandData.name + " a été correctement créé.")
        props.history.push("/brands/" + res.data.id);
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND)
      });
  }
  function updateBrand(brandData) {
    dispatch({ type: BRAND_TYPES.LOAD_BRAND, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "brands/" + brandData.id, brandData)
      .then(res => {
        dispatch({
          type: BRAND_TYPES.GET_BRAND,
          payload: res.data
        });
        tools.filterAlert("success", brandData.name + " a été correctement mis à jour.")
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, BRAND_TYPES.LOAD_BRAND)
      });
  }

  return {
    createBrand,
    updateBrand,
    getBrands,
    getBrand,getAllBrands
  };
};
