import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import { tireActions } from "../../../context/actions/tireActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import { catalogActions } from "../../../context/actions/catalogActions";
import { ClipLoader } from "react-spinners";
import { CATALOG_TYPES } from "../../../context/reducers/catalogReducers";
import tools from "../../../helpers/tools";
import vehicleTools from "../../../helpers/vehicleTools";
import { DateTime } from "luxon";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import OptionsAndLayoutsNotIncluded from "./OptionsAndLayoutsNotIncluded";
import Autocomplete from "react-autocomplete";

const CatalogDetail = (props) => {
  const [state, dispatch] = useStore();
  const [tire, setTire] = useState("");
  const [isInit, setIsInit] = useState(false);

  var catalogData = state.catalog.item;
  var brandModelData = state.brandModel.items;
  var brandData = state.brand.items;
  var constantData = state.constant.items;
  var tireData = state.tire.items;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  var v_actions = catalogActions(state, dispatch, props);
  var t_actions = tireActions(state, dispatch, props);

  var rmOptions = catalogData.optionsNotIncluded.reduce((a, b) => {
    if (b.isInclRM) {
      return a + parseFloat(b.priceHT);
    } else {
      return a;
    }
  }, 0);

  useEffect(() => {
    b_actions.getAllBrands();
    t_actions.getTires();
    dispatch({
      type: BRANDMODEL_TYPES.GET_BRANDMODELS,
      payload: {
        data: [],
      },
    });
    if (props.match.params.id != "create" && !props.modal) {
      v_actions.getCatalog(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (!isInit && catalogData) {
      if (catalogData.discount > 0) {
        setIsInit(true);
        var discountTTC =
          Math.round(
            (parseFloat(catalogData.discount) +
              parseFloat(catalogData.discount) *
                (catalogData.type == 1 ? 0.085 : 0)) *
              100
          ) / 100;

        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "discountTTC",
            value: discountTTC,
          },
        });
      }

      if (catalogData.inroadCost > 0) {
        var inroadCostTTC =
          parseFloat(catalogData.inroadCost) *
          (catalogData.type == "1" ? 1.085 : 1);
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "inroadCostTTC",
            value: inroadCostTTC,
          },
        });
      }
    }
  }, [catalogData]);

  useEffect(() => {
    if (tireData.length > 0 && catalogData) {
      if (catalogData.tire) {
        if (!tireData.find((t) => t.id == catalogData.tire)) {
          alert("Le type de pneu de ce véhicule a été supprimé");
          return false;
        }
        setTire(tireData.find((t) => t.id == catalogData.tire).name);
      }
    }
  }, [tireData]);

  var {
    priceTTC,
    priceTotalHT,
    priceTotalTTC,
    discountPct,
  } = vehicleTools.calcVehiclePrices(catalogData);
  console.log("priceTTC", priceTTC);

  if (
    brandModelData.length == 0 &&
    catalogData.brand != "" &&
    !state.brandModel.isLoading &&
    catalogData.brand
  ) {
    bm_actions.getBrandModelByBrand(catalogData.brand);
  }

  useEffect(() => {
    console.log("RM CHANGED", rmOptions);

    inputChange({
      target: {
        name: "discountPct",
        value: catalogData.discountPct,
      },
    });
  }, [rmOptions]);
  function inputChange(e) {
    if (e.target.name == "brand" && e.target.value != "") {
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "brandModel",
          value: "",
        },
      });
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "finish",
          value: "",
        },
      });
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "fuelType",
          value: "",
        },
      });
      bm_actions.getBrandModelByBrand(e.target.value);
    }
    if (e.target.name == "discount") {
      tools.recalculateDiscount(
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
    }
    if (e.target.name == "discountPct") {
      tools.recalculateDiscount(
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
    }

    if (e.target.name == "discountTTC") {
      tools.recalculateDiscount(
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
    }
    if (e.target.name == "priceHT") {
      var cat = { ...catalogData, priceHT: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat);
      tools.recalculateDiscount(
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );

      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
    }
    if (e.target.name == "priceTTC") {
      var cat = { ...catalogData, priceTTC: e.target.value };
      var priceHT = vehicleTools.calcHTFromTTC(cat);
      tools.recalculateDiscount(
        e.target.name,
        e.target.value,
        dispatch,
        catalogData,
        rmOptions
      );
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "priceHT",
          value: priceHT,
        },
      });
    }
    if (e.target.name == "type") {
      var cat = { ...catalogData, type: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat);
      if (e.target.value == 1) {
        var opts = JSON.parse(JSON.stringify(catalogData.optionsNotIncluded));
        opts = opts.map((op) => {
          op.isInclTVA = true;
          return op;
        });
        console.log("opts", opts);
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "optionsNotIncluded",
            value: opts,
          },
        });
      }

      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
      if (e.target.value == 2) {
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "discountTTC",
            value: catalogData.discount,
          },
        });
      } else {
        var discountTTC = catalogData.discount + catalogData.discount * 0.085;
        dispatch({
          type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
          payload: {
            key: "discountTTC",
            value: discountTTC,
          },
        });
      }
    }
    if (e.target.type == "checkbox") {
      e.target.value = e.target.checked;
    }
    if (e.target.name == "inroadCost") {
      var irTTC =
        Math.round(
          (parseFloat(e.target.value) +
            (catalogData.type == "1"
              ? parseFloat(e.target.value) * 0.085
              : 0)) *
            100
        ) / 100;
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "inroadCostTTC",
          value: irTTC,
        },
      });
    }
    if (e.target.name == "inroadCostTTC") {
      var irHT = Math.round((parseFloat(e.target.value) / 1.085) * 100) / 100;
      dispatch({
        type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
        payload: {
          key: "inroadCost",
          value: irHT,
        },
      });
    }
    tools.inputChange(e, CATALOG_TYPES.UPDATE_CATALOG_DATA, dispatch);
  }
  function addOptionNotIncluded() {
    var { optionsNotIncluded } = catalogData;
    optionsNotIncluded.push({
      name: "",
      priceHT: 0,
      isInclVR: false,
      isInclRM: false,
      isInclTVA: true,
    });
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }
  function changeOptionNotIncluded(e, k) {
    var opt = JSON.parse(JSON.stringify(catalogData.optionsNotIncluded));
    if (e.target.type == "checkbox") {
      opt[k][e.target.name.split("-")[1]] = e.target.checked ? true : false;
    } else {
      opt[k][e.target.name.split("-")[1]] = e.target.value;
    }
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: opt,
      },
    });
  }
  function deleteOptionNotIncluded(k) {
    var { optionsNotIncluded } = catalogData;

    optionsNotIncluded = optionsNotIncluded.filter((option, key) => key !== k);
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {!props.modal && <h1>Edition d'un véhicule du catalogue</h1>}
          <div className="row">
            <div className="col-4">
              <div className="card p-4">
                <h2>Identification</h2>
                <div className="custom-control custom-switch mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name={"isHidden"}
                    id={"isHidden"}
                    onChange={inputChange}
                    checked={catalogData.isHidden ? "checked" : false}
                  />
                  <label htmlFor={"isHidden"} className="custom-control-label">
                    Est caché ?
                  </label>
                </div>
                {state.brand.isLoading ? (
                  <Loader />
                ) : (
                  <Control
                    label="Marque"
                    type="select"
                    name="brand"
                    value={catalogData.brand}
                    change={inputChange}
                    error={state.error}
                    datas={brandData}
                    required={true}
                    disabled={
                      state.brand.isLoading || state.brandModel.isLoading
                        ? "disabled"
                        : false
                    }
                  />
                )}

                {state.brandModel.isLoading ? (
                  <Loader />
                ) : (
                  <Control
                    label="Modèles"
                    type="select"
                    name="brandModel"
                    required={true}
                    value={catalogData.brandModel}
                    change={inputChange}
                    error={state.error}
                    datas={brandModelData}
                  />
                )}

                <div className="form-group">
                  <label htmlFor="finish">
                    Finition<span className="text-danger ml-1">*</span>
                  </label>

                  {state.brandModel.isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <select
                        className="form-control"
                        id="finish"
                        name="finish"
                        value={catalogData.finish}
                        onChange={inputChange}
                      >
                        <option value="">...</option>
                        {catalogData.brandModel &&
                          brandModelData.length > 0 &&
                          brandModelData
                            .find(
                              (brandModel, k) =>
                                brandModel.id == catalogData.brandModel
                            )
                            .finishes.map((finish, key) => (
                              <option key={"finish" + key} value={finish._id}>
                                {finish.name}
                              </option>
                            ))}
                      </select>
                      <small className="form-text text-danger">
                        {state.error.finish}
                      </small>
                    </>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="finish">
                    Carburant<span className="text-danger ml-1">*</span>
                  </label>
                  {state.brandModel.isLoading ? (
                    <Loader />
                  ) : (
                    <select
                      className="form-control"
                      id="finish"
                      name="finish"
                      disabled="disabled"
                    >
                      <option value="">
                        {catalogData.brandModel &&
                        catalogData.finish != "" &&
                        brandModelData.length > 0 &&
                        constantData.FUEL_TYPES.find(
                          (ft) =>
                            brandModelData
                              .find(
                                (brandModel, k) =>
                                  brandModel.id == catalogData.brandModel
                              )
                              .finishes.find(
                                (finish, key) =>
                                  finish._id == catalogData.finish
                              ).fuelType == ft.id
                        )
                          ? constantData.FUEL_TYPES.find(
                              (ft) =>
                                brandModelData
                                  .find(
                                    (brandModel, k) =>
                                      brandModel.id == catalogData.brandModel
                                  )
                                  .finishes.find(
                                    (finish, key) =>
                                      finish._id == catalogData.finish
                                  ).fuelType == ft.id
                            ).name
                          : "Erreur, type de carburant non trouvé"}
                      </option>
                    </select>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="finish">
                    Type<span className="text-danger ml-1">*</span>
                  </label>
                  {constantData.VEHICLE_TYPES.map((type, k) => (
                    <div className="form-check" key={"typev-" + k}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        id={"type" + type.id}
                        value={type.id}
                        onChange={inputChange}
                        checked={
                          catalogData.type == type.id ? "checked" : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"type" + type.id}
                      >
                        {type.name}
                      </label>
                    </div>
                  ))}
                  <small className="form-text text-danger">
                    {state.error.type}
                  </small>
                </div>

                <Control
                  name="tire"
                  type="select"
                  value={catalogData.tire}
                  datas={tireData}
                  change={inputChange}
                  required={true}
                  error={state.error}
                  label="Pneus"
                />
              </div>
            </div>

            <div
              className={
                "col-8 " +
                (catalogData.brand != "" &&
                catalogData.brandModel != "" &&
                catalogData.finish != ""
                  ? ""
                  : "d-none")
              }
            >
              <div className="card p-4">
                <h2>Prix</h2>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="priceHT">
                        Prix public HT{" "}
                        <span className="text-muted">
                          (Hors CG / BoMa / MAR)
                        </span>
                        <span className="text-danger ml-1">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="priceHT"
                          name="priceHT"
                          value={catalogData.priceHT}
                          onChange={inputChange}
                        />
                        <small className="form-text text-danger">
                          {state.error.priceHT}
                        </small>
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="priceTTC">
                        Prix public TTC{" "}
                        <span className="text-muted">
                          (Hors CG / BoMa / MAR)
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="priceTTC"
                        name="priceTTC"
                        value={catalogData.priceTTC}
                        onChange={inputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <OptionsAndLayoutsNotIncluded
                      vehicleData={catalogData}
                      addOptionNotIncluded={addOptionNotIncluded}
                      changeOptionNotIncluded={changeOptionNotIncluded}
                      deleteOptionNotIncluded={deleteOptionNotIncluded}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">Remise HT</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="discountPct"
                          id="discountPct"
                          value={catalogData.discountPct}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">%</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          name="discount"
                          id="discount"
                          value={catalogData.discount}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ HT</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">Remise TTC</label>
                      <div className="input-group">
                        {/* <input
                          type="number"
                          className="form-control"
                          name="discountPctTTC"
                          id="discountPctTTC"
                          value={catalogData.discountPctTTC}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">%</span>
                        </div> */}
                        <input
                          type="number"
                          className="form-control"
                          name="discountTTC"
                          id="discountTTC"
                          value={catalogData.discountTTC}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ TTC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="inroadCost">Mise à la route HT</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="inroadCost"
                          id="inroadCost"
                          value={catalogData.inroadCost}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ HT</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="inroadCostTTC">Mise à la route TTC</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="inroadCostTTC"
                          id="inroadCostTTC"
                          value={catalogData.inroadCostTTC}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ TTC</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="greyCardCost">
                        Carte grise + Carburant
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="greyCardCost"
                          id="greyCardCost"
                          value={catalogData.greyCardCost}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="malusBonusEco">
                        Bonus/Malus écologique
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="malusBonusEco"
                          id="malusBonusEco"
                          value={catalogData.malusBonusEco}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="co2">Taux d'émission de CO2</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="co2"
                          id="co2"
                          value={catalogData.co2}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">gr/km</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 offset-6">Prix d'achat HT</div>
                  <div className="col-3 font-weight-bold">
                    {tools.beautiFyNumber(Math.round(priceTotalHT * 100) / 100)}{" "}
                    €
                  </div>
                  <div className="col-3 offset-6">Prix d'achat TTC</div>
                  <div className="col-3 font-weight-bold">
                    {tools.beautiFyNumber(
                      Math.round(priceTotalTTC * 100) / 100
                    )}{" "}
                    €
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          {state.catalog.isLoading ? (
            <ClipLoader />
          ) : props.match.params.id == "create" || props.modal ? (
            <button
              type="button"
              className="btn btn-success mr-2"
              onClick={() => v_actions.createCatalog(catalogData)}
            >
              Créer {props.modal && "et choisir"}
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-success mr-2"
                onClick={() => v_actions.updateCatalog(catalogData)}
              >
                Enregistrer
              </button>
              <button
                type="button"
                className="btn btn-dark mr-2"
                onClick={() => v_actions.createCatalog(catalogData, true)}
              >
                Dupliquer
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CatalogDetail);
