import React from "react";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import tools from "../../../helpers/tools";
import { constantActions } from "../../../context/actions/constantActions";
import { CONSTANT_TYPES } from "../../../context/reducers/constantReducers";
import DynamicList from "../../common/DynamicList";

const ConstantDynamic = () => {
  const [state, dispatch] = useStore();
  var constantData = state.constant.items;
  var actions = constantActions(state, dispatch);

  function inputChange(e) {
    tools.inputChange(e, CONSTANT_TYPES.UPDATE_CONSTANT_DATA, dispatch);
  }

  function addFin() {
    var financiers = constantData.FINANCIERS;
    var index = financiers.length > 0 ? financiers[financiers.length - 1].id + 1 : 1;
    financiers.push({
      id: index,
      name: "",
      envelop: ""
    });
    dispatch({
      type: CONSTANT_TYPES.UPDATE_CONSTANT_DATA,
      payload: { key: "FINANCIERS", value: financiers }
    });
  }

  function changeFin(e, k) {
    var financiers = constantData.FINANCIERS;
    console.log(e, k);
    financiers[k][e.target.name] = e.target.value;
    dispatch({
      type: CONSTANT_TYPES.UPDATE_CONSTANT_DATA,
      payload: { key: "FINANCIERS", value: financiers }
    });
  }

  let constants = [
    {
      name: "FINANCE_RATE_DEFAULT",
      symbol: "%",
      label: "Taux de financement par défaut"
    },
    {
      name: "FINANCE_RATE_MIN",
      symbol: "%",
      label: "Taux de financement minimum"
    },
    { name: "APPLICATION_FEES", symbol: "%", label: "Frais de dossier" },
    {
      name: "MARGIN_RATE_DEFAULT",
      symbol: "%",
      label: "Taux de marge par défaut"
    },
    { name: "MARGIN_RATE_MIN", symbol: "%", label: "Taux de marge minimum" },
    { name: "INSURANCE_RATE", symbol: "%", label: "Taux d'assurance" },
    {
      name: "INSURANCE_COST_DAY",
      symbol: "€",
      label: "Coût de l'assurance par jour"
    },
    {
      name: "INSURANCE_COST_VEHICLE",
      symbol: "€",
      label: "Coût de l'assurance par véhicule"
    },
    {
      name: "INSURANCE_RATE_FINANCIAL_LOSS",
      symbol: "%",
      label: "Taux d'assurance perte financière"
    },
    {
      name: "REPLACEMENT_VEHICULE_COST_DEFAULT",
      symbol: "€",
      label: "Valeur par défaut pour un véhicule de remplacement"
    }
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Constantes dynamiques</h1>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="FINANCIERS">Financeurs</label>
            {constantData.FINANCIERS.map((fin, k) => (
              <>
                <div key={"fin-" + k} className="form-inline mb-2">
                  <span className="mr-2">{fin.id}</span>
                  <input
                    placeholder="Nom du financeur"
                    type="text"
                    name="name"
                    className="form-control mr-2"
                    value={fin.name}
                    onChange={e => changeFin(e, k)}
                  />
                  <input
                    placeholder="Enveloppe"
                    type="number"
                    name="envelop"
                    className="form-control mr-2"
                    value={fin.envelop}
                    onChange={e => changeFin(e, k)}
                  />
                </div>
              </>
            ))}
            <button className="btn btn-default btn-sm" onClick={addFin}>
              <i className="fa fa-plus mr-2" /> Ajouter un financeur
            </button>
          </div>
          <div>
            <label>Mentions des devis</label>
            <DynamicList
              uniqueKey={"QUOTATION_MENTIONS"}
              items={constantData.QUOTATION_MENTIONS}
              change={inputChange}
              element="QUOTATION_MENTIONS"
              fields={[{ name: "name", type: "text", label: "Texte" }]}
              btnLabel="Ajouter une mention"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          {constants.map((c, k) => (
            <div className="form-group" key={k}>
              <label htmlFor={c.name}>{c.label}</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  id={c.name}
                  name={c.name}
                  value={constantData[c.name]}
                  onChange={inputChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{c.symbol}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className="btn btn-success"
            onClick={() => actions.updateConstant(constantData)}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConstantDynamic;
