import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";
import { vehicleActions } from "../../../context/actions/vehicleActions";
import { ClipLoader } from "react-spinners";
import { VEHICLE_TYPES } from "../../../context/reducers/vehicleReducers";
import tools from "../../../helpers/tools";
import vehicleTools from "../../../helpers/vehicleTools";
import { DateTime } from "luxon";
import { BRANDMODEL_TYPES } from "../../../context/reducers/brandModelReducers";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import OptionsAndLayoutsNotIncluded from "./OptionsAndLayoutsNotIncluded";
import EvolutionChart from "./EvolutionChart";
import UpkeepList from "../../common/UpkeepList";
import { CATALOG_TYPES } from "../../../context/reducers/catalogReducers";

const VehicleDetail = (props) => {
  const [state, dispatch] = useStore();
  const [hasLoaded, setHasLoaded] = useState(false);

  var vehicleData = state.vehicle.item;
  var brandModelData = state.brandModel.items;
  var brandData = state.brand.items;
  var constantData = state.constant.items;

  var b_actions = brandActions(state, dispatch, props);
  var bm_actions = brandModelActions(state, dispatch, props);
  var v_actions = vehicleActions(state, dispatch, props);

  useEffect(() => {
    b_actions.getAllBrands();
    dispatch({
      type: BRANDMODEL_TYPES.GET_BRANDMODELS,
      payload: {
        data: [],
      },
    });
    if (props.match.params.id != "create" && !props.modal) {
      v_actions.getVehicle(props.match.params.id);
    }
  }, []);

  var { priceTTC, priceTotalHT, priceTotalTTC, discountPct } =
    vehicleTools.calcVehiclePrices(vehicleData);

  if (
    brandModelData.length == 0 &&
    vehicleData.brand != "" &&
    !state.brandModel.isLoading &&
    vehicleData.brand &&
    !hasLoaded
  ) {
    setHasLoaded(true);
    bm_actions.getBrandModelByBrand(vehicleData.brand);
  }

  function inputChange(e) {
    if (e.target.name == "brand" && e.target.value != "") {
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "brandModel",
          value: "",
        },
      });
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "finish",
          value: "",
        },
      });
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "fuelType",
          value: "",
        },
      });
      bm_actions.getBrandModelByBrand(e.target.value);
    }
    if (e.target.name == "discount") {
      var discountPct =
        Math.round((parseFloat(e.target.value) / priceTTC) * 100 * 100) / 100;
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "discountPct",
          value: discountPct,
        },
      });
    }
    if (e.target.name == "discountPct") {
      var discount =
        Math.round(((parseFloat(e.target.value) * priceTTC) / 100) * 100) / 100;
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "discount",
          value: discount,
        },
      });
    }
    if (e.target.name == "priceHT") {
      var cat = { ...vehicleData, priceHT: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat);
      var discount =
        Math.round(
          ((parseFloat(vehicleData.discountPct) * priceTTC) / 100) * 100
        ) / 100;

      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "discount",
          value: discount,
        },
      });
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
    }
    if (e.target.name == "priceTTC") {
      var cat = { ...vehicleData, priceTTC: e.target.value };
      var priceHT = vehicleTools.calcHTFromTTC(cat);
      var discount =
        Math.round(
          ((parseFloat(vehicleData.discountPct) * parseFloat(e.target.value)) /
            100) *
            100
        ) / 100;

      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "discount",
          value: discount,
        },
      });
      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "priceHT",
          value: priceHT,
        },
      });
    }
    if (e.target.name == "type") {
      var cat = { ...vehicleData, type: e.target.value };
      var priceTTC = vehicleTools.calcTTCFromHT(cat);
      if (e.target.value == 1) {
        var opts = JSON.parse(JSON.stringify(vehicleData.optionsNotIncluded));
        opts = opts.map((op) => {
          op.isInclTVA = true;
          return op;
        });
        console.log("opts", opts);
        dispatch({
          type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
          payload: {
            key: "optionsNotIncluded",
            value: opts,
          },
        });
      }

      dispatch({
        type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
        payload: {
          key: "priceTTC",
          value: priceTTC,
        },
      });
    }

    tools.inputChange(e, VEHICLE_TYPES.UPDATE_VEHICLE_DATA, dispatch);
  }
  function addOptionNotIncluded() {
    var { optionsNotIncluded } = vehicleData;
    optionsNotIncluded.push({
      name: "",
      priceHT: 0,
      isInclVR: false,
      isInclTVA: true,
    });
    dispatch({
      type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }

  function changeOptionNotIncluded(e, k) {
    var opt = JSON.parse(JSON.stringify(vehicleData.optionsNotIncluded));
    if (e.target.type == "checkbox") {
      opt[k][e.target.name.split("-")[1]] = e.target.checked ? true : false;
    } else {
      opt[k][e.target.name.split("-")[1]] = e.target.value;
    }
    dispatch({
      type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: opt,
      },
    });
  }
  function deleteOptionNotIncluded(k) {
    var { optionsNotIncluded } = vehicleData;

    optionsNotIncluded = optionsNotIncluded.filter((option, key) => key !== k);
    dispatch({
      type: VEHICLE_TYPES.UPDATE_VEHICLE_DATA,
      payload: {
        key: "optionsNotIncluded",
        value: optionsNotIncluded,
      },
    });
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {!props.modal && <h1>Edition d'un véhicule de la flotte</h1>}
          <div className="row">
            <div className="col-4">
              <div className="card p-4">
                <h2>Identification</h2>
                <Control
                  label="Affectation"
                  type="select"
                  name="status"
                  value={vehicleData.status}
                  change={inputChange}
                  error={state.error}
                  datas={constantData.VEHICLE_ASSIGNMENTS}
                />
                <Control
                  label="Immatriculation"
                  type="text"
                  name="immat"
                  value={vehicleData.immat}
                  change={inputChange}
                  error={state.error}
                />
                <Control
                  label="Numéro de série"
                  type="text"
                  name="serialNumber"
                  value={vehicleData.serialNumber || ""}
                  change={inputChange}
                  error={state.error}
                />
                <Control
                  label="Date de mise en circulation"
                  type="date"
                  name="inroadDate"
                  value={DateTime.fromJSDate(
                    new Date(vehicleData.inroadDate)
                  ).toFormat("yyyy-MM-dd")}
                  change={inputChange}
                  error={state.error}
                />

                {state.brand.isLoading ? (
                  <Loader />
                ) : (
                  <Control
                    label="Marque"
                    type="select"
                    name="brand"
                    value={vehicleData.brand}
                    change={inputChange}
                    error={state.error}
                    datas={brandData}
                    disabled={
                      state.brand.isLoading || state.brandModel.isLoading
                        ? "disabled"
                        : false
                    }
                  />
                )}

                {state.brandModel.isLoading ? (
                  <Loader />
                ) : (
                  <Control
                    label="Modèles"
                    type="select"
                    name="brandModel"
                    value={vehicleData.brandModel}
                    change={inputChange}
                    error={state.error}
                    datas={brandModelData}
                  />
                )}

                <div className="form-group">
                  <label htmlFor="finish">Finition</label>

                  {state.brandModel.isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <select
                        className="form-control"
                        id="finish"
                        name="finish"
                        value={vehicleData.finish}
                        onChange={inputChange}
                      >
                        <option value="">...</option>
                        {vehicleData.brandModel &&
                          brandModelData.length > 0 &&
                          brandModelData
                            .find(
                              (brandModel, k) =>
                                brandModel.id == vehicleData.brandModel
                            )
                            .finishes.map((finish, key) => (
                              <option key={"finish" + key} value={finish._id}>
                                {finish.name}
                              </option>
                            ))}
                      </select>
                      <small className="form-text text-danger">
                        {state.error.finish}
                      </small>
                    </>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="finish">Carburant</label>
                  {state.brandModel.isLoading ? (
                    <Loader />
                  ) : (
                    <select
                      className="form-control"
                      id="finish"
                      name="finish"
                      disabled="disabled"
                    >
                      <option value="">
                        {vehicleData.brandModel &&
                        vehicleData.finish != "" &&
                        brandModelData.length > 0 &&
                        brandModelData
                          .find(
                            (brandModel, k) =>
                              brandModel.id == vehicleData.brandModel
                          )
                          .finishes.find(
                            (finish, key) => finish._id == vehicleData.finish
                          ) &&
                        constantData.FUEL_TYPES.find(
                          (ft) =>
                            brandModelData
                              .find(
                                (brandModel, k) =>
                                  brandModel.id == vehicleData.brandModel
                              )
                              .finishes.find(
                                (finish, key) =>
                                  finish._id == vehicleData.finish
                              ).fuelType == ft.id
                        )
                          ? constantData.FUEL_TYPES.find(
                              (ft) =>
                                brandModelData
                                  .find(
                                    (brandModel, k) =>
                                      brandModel.id == vehicleData.brandModel
                                  )
                                  .finishes.find(
                                    (finish, key) =>
                                      finish._id == vehicleData.finish
                                  ).fuelType == ft.id
                            ).name
                          : "Aucun carburant trouvé"}
                      </option>
                    </select>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="finish">Type</label>
                  {constantData.VEHICLE_TYPES.map((type, k) => (
                    <div className="form-check" key={"typev-" + k}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        id={"type" + type.id}
                        value={type.id}
                        onChange={inputChange}
                        checked={
                          vehicleData.type == type.id ? "checked" : false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"type" + type.id}
                      >
                        {type.name}
                      </label>
                    </div>
                  ))}
                  <small className="form-text text-danger">
                    {state.error.type}
                  </small>
                </div>
              </div>
            </div>

            <div className="col-8">
              <div className="card p-4">
                <h2>Prix</h2>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="priceHT">
                        Prix d'achat HT{" "}
                        <span className="text-muted">
                          (Hors CG / BoMa / MAR)
                        </span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="priceHT"
                          name="priceHT"
                          value={vehicleData.priceHT}
                          onChange={inputChange}
                        />
                        <small className="form-text text-danger">
                          {state.error.priceHT}
                        </small>
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="priceTTC">
                        Prix d'achat TTC{" "}
                        <span className="text-muted">
                          (Hors CG / BoMa / MAR)
                        </span>
                      </label>
                      <div>
                        {tools.beautiFyNumber(Math.round(priceTTC * 100) / 100)}{" "}
                        €
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <OptionsAndLayoutsNotIncluded
                      vehicleData={vehicleData}
                      addOptionNotIncluded={addOptionNotIncluded}
                      changeOptionNotIncluded={changeOptionNotIncluded}
                      deleteOptionNotIncluded={deleteOptionNotIncluded}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="discount">Remise</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="discountPct"
                          id="discountPct"
                          value={vehicleData.discountPct}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">%</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          name="discount"
                          id="discount"
                          value={vehicleData.discount}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ HT</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="inroadCost">Mise à la route HT</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="inroadCost"
                          id="inroadCost"
                          value={vehicleData.inroadCost}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="greyCardCost">
                        Carte grise + Carburant
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="greyCardCost"
                          id="greyCardCost"
                          value={vehicleData.greyCardCost}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€ HT</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="malusBonusEco">
                        Bonus/Malus écologique
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          name="malusBonusEco"
                          id="malusBonusEco"
                          value={vehicleData.malusBonusEco}
                          onChange={inputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">€</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 offset-6">Prix d'achat HT</div>
                  <div className="col-3 font-weight-bold">
                    {tools.beautiFyNumber(Math.round(priceTotalHT * 100) / 100)}{" "}
                    €
                  </div>
                  <div className="col-3 offset-6">Prix d'achat TTC</div>
                  <div className="col-3 font-weight-bold">
                    {tools.beautiFyNumber(
                      Math.round(priceTotalTTC * 100) / 100
                    )}{" "}
                    €
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.match.params.id != "create" && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="card p-4">
                  <UpkeepList vehicle={props.match.params.id} />
                </div>
              </div>
            </div>
          )}
          {!props.modal && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="card p-4">
                  <h2>Évolution</h2>
                  {/* <EvolutionChart /> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          {state.vehicle.isLoading ? (
            <ClipLoader />
          ) : props.match.params.id == "create" || props.modal ? (
            <button
              type="button"
              className="btn btn-success mr-2"
              onClick={() => v_actions.createVehicle(vehicleData)}
            >
              Créer {props.modal && "et choisir"}
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-success mr-2"
                onClick={() => v_actions.updateVehicle(vehicleData)}
              >
                Enregistrer
              </button>
              <button
                type="button"
                className="btn btn-dark mr-2"
                onClick={() => v_actions.createVehicle(vehicleData, true)}
              >
                Dupliquer
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(VehicleDetail);
