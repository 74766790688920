import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { clientActions } from "../../../context/actions/clientActions";
import Loader from "../../common/Loader";
import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import Axios from "axios";
import { api_url } from "../../../config/config";

const ClientList = props => {
  const [state, dispatch] = useStore();

  var actions = clientActions(state, dispatch);

  function removeClient(id) {
    if (window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?")) {

      console.log(id)
      Axios.delete(api_url + "clients/" + id)
        .then(res => {
          actions.getClients()
        })
        .catch(err => {
          if (err.response && err.response.data) {
            alert(err.response.data.other)
          }
        })
    }
  }

  return (
    <CustomList getAction={actions.getClients}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!props.modal && <h1>Clients</h1>}
              {state.client.isLoading ? (
                <Loader />
              ) : (
                  <Link className="btn btn-primary" to="/clients/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un client
                  </Link>
                )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Email</th>
                    <th>Téléphone</th>
                    <th>Ville</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.client.items.map((client, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>{client.name}</td>
                      <td>{client.email}</td>
                      <td>{client.phone}</td>
                      <td>{client.city}</td>
                      <td>
                        {!props.modal ? (
                          <>
                            <Link
                              className="btn btn-secondary"
                              to={"/clients/" + client.id}
                            >
                              Modifier
                          </Link>
                            {state.auth.user.role == "SUPER_ADMIN" &&
                              <button title="Supprimer ce client"
                                className="btn btn-danger ml-2  btn-sm"
                                onClick={() => removeClient(client.id)}>
                                <i className="fa fa-times" />
                              </button>
                            }
                          </>
                        ) : <>

                            <a
                              href="#"
                              className="btn btn-secondary"
                              onClick={() => props.selectClient(client)}
                            >
                              Choisir
                            </a>
                          </>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.client.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />


            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default ClientList;
