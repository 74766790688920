import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { financeActions } from "../../../context/actions/financeActions";
import { quotationActions } from "../../../context/actions/quotationActions";
import { FINANCE_TYPES } from "../../../context/reducers/financeReducers";
import financeTools from "../../../helpers/financeTools";
import Control from "../../common/Control";
import tools from "../../../helpers/tools";
import ValidQuotationsList from "./ValidQuotationsList";
import FinanceTab from "./FinanceTab";
import Loader from "../../common/Loader";
import PaymentTable from "./PaymentTable";
import { DateTime } from "luxon";

const FinanceDetail = (props) => {
  const [state, dispatch] = useStore();
  var [selectedTab, setSelectedTab] = useState(0);

  var f_actions = financeActions(state, dispatch, props);
  var q_actions = quotationActions(state, dispatch, props);
  var financeData = state.finance.item;
  var quotationData = state.quotation.items;
  var constantData = state.constant.items;

  useEffect(() => {
    q_actions.getValidQuotations();
    if (props.match.params.id != "create") {
      f_actions.getFinance(props.match.params.id);
    }
  }, []);

  function inputChange(e) {
    tools.inputChange(e, FINANCE_TYPES.UPDATE_FINANCE_DATA, dispatch);
  }
  function changeQuotation(e, q) {
    var newQuot = {
      quotation: q._id,
      reference: q.reference,
      periods: [
        {
          num: 1,
          dBegin: new Date(),
          dEnd: DateTime.fromJSDate(new Date())
            .plus({ months: q.dur })
            .toFormat("yyyy-MM-dd"),
          duration: q.dur,
          monthPayment: q.financeRent,
          monthPaymentReal: 0,
          vr: q.repurchaseValueFinal,
        },
      ],
      residualValue: 0,
    };

    var { quotations } = financeData;
    if (e.target.checked) {
      quotations.push(newQuot);
    } else {
      quotations = quotations.filter((qs) => qs.quotation !== q._id);
    }
    var totalEnvelop = financeTools.calcEnvelop(quotations, quotationData);

    dispatch({
      type: FINANCE_TYPES.UPDATE_FINANCE_DATA,
      payload: {
        key: "totalEnvelop",
        value: totalEnvelop,
      },
    });
    dispatch({
      type: FINANCE_TYPES.UPDATE_FINANCE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function addPeriod(index) {
    var { quotations } = financeData;
    if (
      quotations[index].periods[quotations[index].periods.length - 1]
        .duration == ""
    ) {
      alert("Veuillez d'abord renseigner une durée pour la période précédente");
      return false;
    }
    var num =
      quotations[index].periods[quotations[index].periods.length - 1].num + 1;

    quotations[index].periods.push({
      num,
      dBegin:
        quotations[index].periods[quotations[index].periods.length - 1].dEnd,
      dEnd: "",
      duration: "",
      monthPayment: "",
      monthPaymentReal: "",
      residualValue: "0",
      vr: 0,
    });

    dispatch({
      type: FINANCE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function removePeriod(index, k) {
    var { quotations } = financeData;

    quotations[index].periods = quotations[index].periods.filter(
      (p, pk) => pk !== k
    );
    var c = 1;
    quotations[index].periods = quotations[index].periods.map((p) => {
      p.num = c;
      c++;
      return p;
    });

    dispatch({
      type: FINANCE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function inputPeriodChange(e, index, k) {
    var { quotations } = financeData;
    quotations[index].periods[k][e.target.name] = e.target.value;

    if (e.target.name == "dBegin" || e.target.name == "duration") {
      quotations[index].periods[k]["dEnd"] = financeTools.dateEndFromStart(
        quotations[index].periods[k].dBegin,
        quotations[index].periods[k].duration
      );

      var i = k == 0 ? 1 : k;
      while (quotations[index].periods[k + i]) {
        quotations[index].periods[k + i].dBegin =
          quotations[index].periods[i - 1 >= 0 ? i - 1 : 0]["dEnd"];
        quotations[index].periods[k + i][
          "dEnd"
        ] = financeTools.dateEndFromStart(
          quotations[index].periods[k + i].dBegin,
          quotations[index].periods[k + i].duration
        );
        i++;
      }
    }

    quotations = financeTools.recalcPeriods(quotations, quotationData);

    dispatch({
      type: FINANCE_TYPES.UPDATE_REPURCHASE_DATA,
      payload: {
        key: "quotations",
        value: quotations,
      },
    });
  }
  function setStatusValid() {
    if (
      window.confirm(
        "Êtes-vous certain de vouloir passer ce financement en validé ?"
      )
    ) {
      var fin = state.finance.item;
      fin.status = 2;
      f_actions.updateFinance(fin);
    }
  }

  return (
    <>
      <ValidQuotationsList
        changeQuotation={changeQuotation}
        quotations={financeData.quotations}
        quotationData={quotationData}
        disabled={financeData.status == 2}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Édition d'un financement</h1>
          </div>
          <div className="col-12 mb-4">
            <div className="form-group">
              <label>Référence</label>
              <div>{financeData.reference}</div>
            </div>
            <div className="form-group">
              <label>Etat</label>
              <span
                className={
                  "badge badge-" +
                  constantData.FINANCE_STATUS.find(
                    (f) => f.id == financeData.status
                  ).css
                }
              >
                {
                  constantData.FINANCE_STATUS.find(
                    (f) => f.id == financeData.status
                  ).name
                }
              </span>

              {financeData.id && financeData.status == 1 && (
                <button
                  className=" ml-2 btn btn-sm btn-success"
                  onClick={setStatusValid}
                >
                  Passer en état validé
                </button>
              )}
            </div>

            <div className="form-group">
              <label>
                Financeur (total investissement = {financeData.totalEnvelop} €){" "}
                {financeData.financier &&
                financeData.financier != "" &&
                financeData.status != 2 &&
                financeData.totalEnvelop >
                  constantData.FINANCIERS.find(
                    (f) => f.id == financeData.financier
                  ).envelop ? (
                  <span>
                    <i
                      className="fa fa-exclamation-triangle mx-2"
                      style={{ color: "red" }}
                    ></i>
                    Supérieur à l'enveloppe disponible pour ce financeur
                  </span>
                ) : (
                  ""
                )}
              </label>
              <select
                className="form-control"
                name="financier"
                onChange={inputChange}
                value={financeData.financier}
                disabled={financeData.status == 2}
              >
                {constantData.FINANCIERS.map((fin, k) => (
                  <option
                    key={"fin" + k}
                    value={fin.id}
                    style={{
                      color:
                        financeData.totalEnvelop > fin.envelop ||
                        fin.envelop <= 0
                          ? "red"
                          : "green",
                    }}
                  >
                    {fin.name} - {fin.envelop} €
                  </option>
                ))}
              </select>
              <small className="form-text text-danger">
                {state.error.financier}
              </small>
            </div>
            <Control
              label="Type de crédit"
              type="select"
              name="financeType"
              value={financeData.financeType}
              change={inputChange}
              datas={constantData.FINANCE_TYPES}
              error={state.error}
              disabled={financeData.status == 2}
            />
          </div>
          {quotationData.length > 0 && (
            <div className="col-12 mb-4">
              <ul className="nav nav-tabs">
                {financeData.quotations.map((q, k) => (
                  <li className="nav-item" key={"tab" + k}>
                    <a
                      className={
                        "nav-link " + (k == selectedTab ? "active" : "")
                      }
                      id={"tab" + q.quotation}
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls={q.quotation}
                      aria-selected="true"
                      onClick={() => setSelectedTab(k)}
                    >
                      {financeTools.getQuotationObj(
                        q.quotation,
                        quotationData
                      ) ? (
                        financeTools.getQuotationObj(q.quotation, quotationData)
                          .reference
                      ) : (
                        <span className="text-white">
                          Erreur : Le devis n'a pas été trouvé !
                        </span>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
              <div
                className="tab-content p-3"
                style={{
                  background: "white",
                  border: "1px solid #dee2e6",
                  borderTop: 0,
                }}
              >
                {financeData.quotations.map((q, k) => (
                  <FinanceTab
                    quotation={q}
                    key={"tq" + q._id}
                    selectedTab={selectedTab}
                    index={k}
                    inputPeriodChange={inputPeriodChange}
                    addPeriod={addPeriod}
                    removePeriod={removePeriod}
                    disabled={financeData.status == 2}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="col-12 mb-4">
            <PaymentTable quotations={quotationData} finances={financeData} />
          </div>
          <div className="col-12">
            {state.quotation.isLoading ? (
              <Loader />
            ) : props.match.params.id == "create" ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => f_actions.createFinance(state.finance.item)}
              >
                Créer
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => f_actions.updateFinance(state.finance.item)}
              >
                Enregistrer
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(FinanceDetail);
