import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Pagination from "react-js-pagination";

const CustomList = (props) => {
  const { getAction } = props;
  const [activePage, setActivePage] = useState(
    parseInt(queryString.parse(props.location.search).page) || 1
  );
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    handlePageParamsChange(true);
  }, []);

  function handlePageParamsChange(params, isFirstLoad = false) {
    var searchObj = queryString.parse(props.location.search);

    console.log("params", params);
    let pageNumber = params.page;
    let newSearch = params.search;
    let isTimer = false;

    if (!params.page) pageNumber = searchObj.page;

    if (newSearch || newSearch === "") {
      searchObj.search = newSearch;
      isTimer = isFirstLoad ? false : true;
      pageNumber = 1;
    } else {
      if (!searchObj.search) {
        delete searchObj.search;
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
        newSearch = "";
      } else {
        newSearch = searchObj.search;
      }
    }

    searchObj.page = pageNumber;

    const query = "?" + queryString.stringify(searchObj);
    props.history.push(query);
    setActivePage(pageNumber);
    setSearch(newSearch);

    if (isTimer) {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(setTimeout(getAction, 1200, searchObj));
    } else {
      getAction(searchObj);
    }
  }

  return props.children(activePage, handlePageParamsChange, search);
};

export default withRouter(CustomList);
