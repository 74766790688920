import React, { useEffect } from "react";
import useStore from "../../context/useStore";
import { maintenanceActions } from "../../context/actions/maintenanceActions";
import vehicleTools from "../../helpers/vehicleTools";
import Control from "./Control";
import InputGroupWithResetValue from "./InputGroupWithResetValue";
import tools from "../../helpers/tools";

const CalcMaintenanceBox = ({ quotation, rlChange, num }) => {
  const [state, dispatch] = useStore();

  var {
    vehicle,
    dur,
    km,
    discountMaintenance,
    maintenance,
    maintenanceData,
    rateMaintenance,
    maintenanceValueFinal,
  } = quotation;

  var actions = maintenanceActions(state, dispatch);


  useEffect(() => {
    if (maintenanceData.length == 0 && !state.maintenance.isLoading) {

      actions.getMaintenancesFromVehicle(
        "?brand=" +
        vehicle.brand.id +
        "&model=" +
        vehicle.brandModel.id +
        "&fueltype=" +
        vehicle.brandModel.finishes.find((f) => f.id == vehicle.finish.id)
          .fuelType
      );
    }
  }, [vehicle.id]);

  var maintenanceValueFinalCalc = "0";
  var rateMaintenanceCalc = "0";
  var rateMaintenanceInfo = "";

  if (
    maintenanceData.length == 0 &&
    !state.maintenance.isLoading &&
    state.maintenance.items.find(
      (r) =>
        r.brand == vehicle.brand.id &&
        r.brandModels.find((b) => b == vehicle.brandModel.id)
    )
  ) {

    rlChange({
      target: {
        name: "maintenanceData",
        value: JSON.parse(JSON.stringify(state.maintenance.items)),
      },
    });
  }

  if (maintenance) {
    var rmr = vehicleTools.getRateMaintenance(
      dur,
      km,
      maintenanceData.find((r) => r.id === maintenance).tableValues,
      num
    );
    rateMaintenanceCalc = rmr.value;
    rateMaintenanceInfo = rmr.info;
  } else {
    if (maintenanceData.length > 0 && maintenanceData[0]) {
      rlChange({
        target: { name: "maintenance", value: maintenanceData[0].id },
      });
    }
  }
  if (rateMaintenance > 0) {

    maintenanceValueFinalCalc =
      Math.round(
        (parseFloat(rateMaintenance) -
          tools.getPercentOf(rateMaintenance, discountMaintenance)) *
        100
      ) / 100;
  }

  if (maintenance && rateMaintenance === "0") {
    rlChange({
      target: { name: "rateMaintenance", value: rateMaintenanceCalc },
    });
  }

  if (maintenance && maintenanceValueFinal === "-1" && maintenanceValueFinalCalc != "0") {

    rlChange({
      target: {
        name: "maintenanceValueFinal",
        value: maintenanceValueFinalCalc,
      },
    });
  }

  return (
    <>
      <Control
        label="Maintenance sélectionnée"
        type="select"
        name="maintenance"
        value={maintenance}
        change={rlChange}
        datas={maintenanceData}
      />
      <p className="text-info" style={{ fontSize: 12 }}>
        {rateMaintenanceInfo}
      </p>
      <>
        <InputGroupWithResetValue
          props={{
            name: "rateMaintenance",
            symbol: "€/m",
            label: "Prix de la maintenance",
            defaultValue: rateMaintenanceCalc,
          }}
          data={{ rateMaintenance }}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value,
              },
            })
          }
          inputChange={rlChange}
        />
        <Control
          label="Remise"
          name="discountMaintenance"
          value={discountMaintenance}
          change={rlChange}
          suffix="%"
        />

        <InputGroupWithResetValue
          props={{
            name: "maintenanceValueFinal",
            symbol: "€",
            label: "Prix maintenance retenu",
            defaultValue: maintenanceValueFinalCalc,
          }}
          data={{ maintenanceValueFinal }}
          resetDefault={(name, value) =>
            rlChange({
              target: {
                name,
                value,
              },
            })
          }
          inputChange={rlChange}
        />
      </>
    </>
  );
};

export default CalcMaintenanceBox;
