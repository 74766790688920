export const REPURCHASE_TYPES = {
  LOAD_REPURCHASE: "LOAD_REPURCHASE",
  GET_REPURCHASES: "GET_REPURCHASES",
  GET_REPURCHASES_VEHICLE: "GET_REPURCHASES_VEHICLE",
  GET_REPURCHASE: "GET_REPURCHASE",
  UPDATE_REPURCHASE_DATA: "UPDATE_REPURCHASE_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    name: "",
    brand: "",
    brandModels: [],
    validFrom: "",
    validTo: "",
    kmPrice: "",
    tableValues: {
      km: [],
      dur: [],
      values: {}
    },
    fuelTypes: [],
    isArchived: false
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REPURCHASE_TYPES.LOAD_REPURCHASE:
      return {
        ...state,
        isLoading: action.payload
      };
    case REPURCHASE_TYPES.GET_REPURCHASES:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.count,
        item: initialState.item,
        isLoading: false
      };
    case REPURCHASE_TYPES.GET_REPURCHASES_VEHICLE:
      return {
        ...state,
        items: action.payload,
        item: initialState.item,
        isLoading: false
      };

    case REPURCHASE_TYPES.GET_REPURCHASE:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };

    case REPURCHASE_TYPES.UPDATE_REPURCHASE_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
