import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { maintenanceActions } from "../../../context/actions/maintenanceActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";

const MaintenanceList = (props) => {
  const [state, dispatch] = useStore();

  var maintenanceData = state.maintenance.items;
  var constantData = state.constant.items;

  var actions = maintenanceActions(state, dispatch, props);

  return (
    <CustomList getAction={actions.getMaintenances}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Tableau d'entretien</h1>
              {state.maintenance.isLoading ? (
                <Loader />
              ) : (
                  <Link className="btn btn-primary" to="/maintenances/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un tableau
                  </Link>
                )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Marque</th>
                    <th>Modèles</th>
                    <th>Carburants</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {maintenanceData.map((maintenance, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>{maintenance.brand && maintenance.brand.name}</td>
                      <td>
                        {maintenance.brandModels.map(
                          (bm, k) =>
                            bm.name +
                            (k == maintenance.brandModels.length - 1
                              ? ""
                              : ", ")
                        )}
                      </td>
                      <td>
                        {maintenance.fuelTypes.map(
                          (ft, k) =>
                            constantData.FUEL_TYPES.find((f) => f.id == ft)
                              .name +
                            (k == maintenance.fuelTypes.length - 1 ? "" : ", ")
                        )}
                      </td>
                      <td>
                        <Link
                          className="btn btn-secondary mr-2"
                          to={"/maintenances/" + maintenance.id}
                        >
                          Modifier
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <CustomPagination
                total={state.maintenance.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />


            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default MaintenanceList;
