import axios from "axios";
import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import { CATALOG_TYPES } from "../context/reducers/catalogReducers";

var messageBuffer = "";

var tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },

  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },

  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },
  beautiFyNumber: (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  },
  inputChange: (e, type, dispatch) => {
    var key = e.target.name;
    var value = e.target.value;
    if (e.target.type == "checkbox") value = e.target.checked ? true : false;
    dispatch({
      type,
      payload: {
        key,
        value,
      },
    });
  },

  filterError: (dispatch, error, loadToClose) => {
    dispatch({ type: loadToClose, payload: false });
    dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: error });

    if (typeof error === "string" && error === "Unauthorized") {
      window.location.href = "/login";
      // toastr.confirm("Votre session a expiré. Veuillez vous reconnecter", toastrConfirmOptions)
      toast.error("Veuillez vous reconnecter");
    } else if (error.other) {
      console.log(error);
      toast.error("Erreur " + error.other);
    }
  },

  filterAlert: (type, message) => {
    if (message != messageBuffer) {
      switch (type) {
        case "success":
          toast.success(message);
          break;
        case "info":
          toast.info(message);
          break;

        default:
          break;
      }
    }
    messageBuffer = message;
    setTimeout(() => {
      messageBuffer = "";
    }, 2000);
  },
  sortQuotations: (fields, dataArray) => {
    dataArray = dataArray.sort((a, b) => {
      if (fields.includes("brand")) {
        var brandA = a.vehicle.brand.id;
        var brandB = b.vehicle.brand.id;
        if (brandA < brandB) return -1;
        if (brandA > brandB) return 1;
      }
      if (fields.includes("brandModel")) {
        var brandModelA = a.vehicle.brandModel.id;
        var brandModelB = b.vehicle.brandModel.id;
        if (brandModelA < brandModelB) return -1;
        if (brandModelA > brandModelB) return 1;
      }
      if (fields.includes("finish")) {
        var finishA = a.vehicle.finish;
        var finishB = b.vehicle.finish;
        if (finishA < finishB) return -1;
        if (finishA > finishB) return 1;
      }
      if (fields.includes("rollingLaw")) {
        var rlA = a.dur + a.km;
        var rlB = b.dur + b.km;
        if (rlA < rlB) return -1;
        if (rlA > rlB) return 1;
      }
      return 0;
    });

    return dataArray;
  },
  filterQuotations: (filters, dataArray) => {
    var showKeys = [];
    dataArray.forEach((d, k) => {
      var incl = true;
      if (filters.brand != "") {
        if (d.vehicle.brand.name.toLowerCase().includes(filters.brand)) {
          incl = true;
        } else {
          incl = false;
        }
      }
      if (filters.brandModel != "") {
        if (
          d.vehicle.brandModel.name.toLowerCase().includes(filters.brandModel)
        ) {
          incl = incl != false ? true : false;
        } else {
          incl = false;
        }
      }
      if (filters.finish != "") {
        if (
          d.vehicle.brandModel.finishes
            .find((f) => f._id == d.vehicle.finish)
            .name.toLowerCase()
            .includes(filters.finish)
        ) {
          incl = incl != false ? true : false;
        } else {
          incl = false;
        }
      }
      if (filters.duration != "") {
        if (d.dur.toString().includes(filters.duration)) {
          incl = incl != false ? true : false;
        } else {
          incl = false;
        }
      }
      if (filters.km != "") {
        if (d.km.toString().includes(filters.km)) {
          incl = incl != false ? true : false;
        } else {
          incl = false;
        }
      }
      if (filters.status != "") {
        if (d.status.toString().includes(filters.status)) {
          incl = incl != false ? true : false;
        } else {
          incl = false;
        }
      }

      if (
        filters.km == "" &&
        filters.duration == "" &&
        filters.brand == "" &&
        filters.brandModel == "" &&
        filters.finish == "" &&
        filters.status == ""
      ) {
        incl = true;
      }
      if (incl) {
        showKeys.push(k);
      }
    });
    var hideKeys = [];
    dataArray.forEach((d, k) => {
      if (!showKeys.includes(k)) hideKeys.push(k);
    });

    return hideKeys;
  },
  getPercentOf(a, b) {
    var c = (parseFloat(a) * parseFloat(b)) / 100;
    return parseFloat(c);
  },

  recalculateDiscount(
    changedDiscount,
    value,
    dispatch,
    catalogData,
    rmOptions
  ) {
    var discountPct = 0;
    var discount = 0;
    var discountTTC = 0;
    value = parseFloat(value);
    if (changedDiscount == "discountPct") {
      discountPct = value;
      var discount =
        Math.round(
          ((parseFloat(discountPct) *
            (parseFloat(catalogData.priceHT) + rmOptions)) /
            100) *
            100
        ) / 100;
      var discountTTC =
        Math.round(
          (discount + discount * (catalogData.type == 1 ? 0.085 : 0)) * 100
        ) / 100;
    }
    if (changedDiscount == "discount") {
      discount = value;
      discountPct =
        Math.round(
          (parseFloat(discount) / (catalogData.priceTTC + rmOptions)) *
            100 *
            100
        ) / 100;

      discountTTC =
        Math.round(
          (parseFloat(discount) +
            parseFloat(discount) * (catalogData.type == 1 ? 0.085 : 0)) *
            100
        ) / 100;
    }
    if (changedDiscount == "discountTTC") {
      discountTTC = value;
      discount =
        Math.round(
          (parseFloat(discountTTC) / (catalogData.type == 1 ? 1.085 : 1)) * 100
        ) / 100;
      discountPct =
        Math.round(
          (discount / (parseFloat(catalogData.priceHT) + rmOptions)) * 100 * 100
        ) / 100;
    }
    if (changedDiscount == "priceHT") {
      discountPct = parseFloat(catalogData.discountPct);
      discount =
        Math.round(
          ((parseFloat(discountPct) * (value + rmOptions)) / 100) * 100
        ) / 100;
      discountTTC =
        Math.round(
          (parseFloat(discount) +
            parseFloat(discount) * (catalogData.type == 1 ? 0.085 : 0)) *
            100
        ) / 100;
    }
    if (changedDiscount == "priceTTC") {
      discountPct = parseFloat(catalogData.discountPct);

      var discount =
        Math.round(
          ((parseFloat(catalogData.discountPct) *
            (parseFloat(value) + rmOptions)) /
            100) *
            100
        ) / 100;
      discountTTC =
        Math.round(
          (parseFloat(discount) +
            parseFloat(discount) * (catalogData.type == 1 ? 0.085 : 0)) *
            100
        ) / 100;
    }

    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "discountPct",
        value: discountPct,
      },
    });
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "discount",
        value: discount,
      },
    });
    dispatch({
      type: CATALOG_TYPES.UPDATE_CATALOG_DATA,
      payload: {
        key: "discountTTC",
        value: discountTTC,
      },
    });
  },
};

export default tools;
