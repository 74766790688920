import React, { useEffect, useState } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Axios from "axios";
import { api_url } from "../../../config/config";
import Alerts from "./Alerts";

const Header = () => {
  const [state, dispatch] = useStore();
  const [alerts, setAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(false);
  var authData = state.auth;
  const actions = authActions(state, dispatch);

  const isAdmin =
    authData.user.role == "ADMIN" || authData.user.role == "SUPER_ADMIN";

  useEffect(() => {
    Axios.interceptors.response.use(
      function (response) {
        var config = response.config;
        // Do something before request is sent
        if (["post", "patch", "delete"].includes(config.method)) {
          var url = config.url;
          url = url.replace(api_url, "");
          if (
            (url.includes("brandmodels") ||
              url.includes("finances") ||
              url.includes("quotations")) &&
            !url.includes("search")
          ) {
            console.log("INTERCEPTOR", config);
            getAlerts();
          }
        }
        return response;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (state.auth.isAuthenticated) {
      getAlerts();
    }
  }, [state.auth.isAuthenticated]);

  function getAlerts() {
    if (alertsLoading) {
      return;
    }
    setAlertsLoading(true);
    console.log("getting alerts...");

    Axios.get(api_url + "alerts")
      .then((res) => {
        setAlerts(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        setAlertsLoading(false);
      });
  }

  return state.auth.isAuthenticated ? (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand" to="/">
        <img height="30" src="/logo.png" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink className="nav-link" exact to="/">
              Accueil
            </NavLink>
          </li>

          <li className="nav-item dropdown">
            <span
              className="nav-link dropdown-toggle"

              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Constantes
            </span>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink className="dropdown-item" to="/brands">
                Marques
              </NavLink>
              <NavLink className="dropdown-item" to="/brandmodels">
                Modèles et finitions
              </NavLink>
              <NavLink className="dropdown-item" to="/tires">
                Pneus
              </NavLink>
              {isAdmin && (
                <>
                  <NavLink className="dropdown-item" to="/repurchases">
                    Valeurs de rachat
                  </NavLink>
                  <NavLink className="dropdown-item" to="/maintenances">
                    Tableau d'entretien
                  </NavLink>
                  <NavLink className="dropdown-item" to="/constants">
                    Constantes dynamiques
                  </NavLink>
                </>
              )}
            </div>
          </li>

          <li className="nav-item dropdown">
            <span
              className="nav-link dropdown-toggle"
              style={{ cursor: "pointer" }}
              id="navbarDropdownV"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Véhicules
            </span>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownV">
              <NavLink to="/catalogs" className="dropdown-item">
                Catalogue
              </NavLink>
              <NavLink to="/vehicles" className="dropdown-item">
                Flotte
              </NavLink>
            </div>
          </li>

          <li className="nav-item">
            <NavLink to="/quotations" className="nav-link">
              Dossiers
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/contracts" className="nav-link">
              Contrats
            </NavLink>
          </li>
          {isAdmin && (
            <>
              <li className="nav-item">
                <NavLink to="/finances" className="nav-link">
                  Financements
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/users" className="nav-link">
                  Utilisateurs
                </NavLink>
              </li>
            </>
          )}
          <li className="nav-item">
            <NavLink to="/clients" className="nav-link">
              Clients
            </NavLink>
          </li>
        </ul>
        <div className="form-inline">
          {isAdmin && <Alerts alerts={alerts} />}
          <span className="text-muted mr-2">
            {authData.user.firstname + " " + authData.user.lastname}
          </span>
          <button className="btn btn-default" onClick={actions.logoutUser}>
            <i className="fa fa-power-off" />
          </button>
        </div>
      </div>
    </nav>
  ) : (
      <></>
    );
};

export default withRouter(Header);
