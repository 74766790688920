import React, { useState } from "react";

const SelectDateModal = props => {
  var [date, setDate] = useState(new Date());

  return (
    <div
      className="modal fade"
      id="selectDateModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Copier un tableau de valeurs depuis excel
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="periodDate">
                Entrez la date d'entrée en vigueur de la nouvelle période
              </label>
              <input
                type="date"
                className="form-control"
                name="periodDate"
                id="periodDate"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => props.createNewPeriod(date)}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDateModal;
