export const QUOTATION_TYPES = {
  LOAD_QUOTATION: "LOAD_QUOTATION",
  GET_QUOTATIONS: "GET_QUOTATIONS",
  GET_QUOTATION: "GET_QUOTATION",
  UPDATE_QUOTATION_DATA: "UPDATE_QUOTATION_DATA",
  RESET_QUOTATION: "RESET_QUOTATION",
  GET_VALID_QUOTATIONS: "GET_VALID_QUOTATIONS"
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    client: null,
    quotations: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case QUOTATION_TYPES.LOAD_QUOTATION:
      console.log("LOADING", action.payload);
      return {
        ...state,
        isLoading: action.payload
      };
    case QUOTATION_TYPES.GET_QUOTATIONS:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.count,
        item: initialState.item,
        isLoading: false
      };
    case QUOTATION_TYPES.GET_VALID_QUOTATIONS:
      return {
        ...state,
        items: action.payload,
        item: initialState.item,
        isLoading: false
      };

    case QUOTATION_TYPES.GET_QUOTATION:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };
    case QUOTATION_TYPES.RESET_QUOTATION:
      return {
        ...state,
        item: initialState.item,
        items: initialState.items
      };

    case QUOTATION_TYPES.UPDATE_QUOTATION_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value }
      };

    default:
      return state;
  }
}
