import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "@react-pdf/renderer";

const Alerts = ({ alerts }) => {
  var nbErrors = alerts.reduce((a, b) => a + b.alerts.length, 0);
  return (
    <li className="nav-item dropdown list-group">
      <a
        className={
          "nav-link dropdown-toggle position-relative " +
          (nbErrors > 0 ? "text-danger" : "text-black-50")
        }
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fa fa-bell" />
        <span
          style={{ top: 0, left: -10 }}
          className="badge badge-danger position-absolute"
        >
          {nbErrors}
        </span>
      </a>
      <div
        className="dropdown-menu p-2"
        style={{ width: 225 }}
        aria-labelledby="navbarDropdown"
      >
        {alerts.map((a, ak) => (
          <div key={"alert" + ak}>
            <span className="text-danger">{a.text}</span>
            {a.alerts.map((al, alk) => (
              <a
                key={"al" + ak + "" + alk}
                className="btn btn-link btn-sm w-100"
                href={al.url}
              >
                {al.name}
              </a>
            ))}
            <div className="dropdown-divider"></div>
          </div>
        ))}
      </div>
    </li>
  );
};

export default Alerts;
