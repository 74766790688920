import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { VEHICLE_TYPES } from "../reducers/vehicleReducers";

export const vehicleActions = (state, dispatch, props) => {
  function getVehicles(search = { search: null }) {
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      vehicle: {
        searchString: search.search,
        brand: search.brand,
        brandModel: search.brandModel,
        finish: search.finish,
        immat: search.immat,
      },
      page: search.page,
    };

    axios
      .post(api_url + "vehicles/search", search)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLES,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }
  function getVehicle(id) {
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "vehicles/" + id)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLE,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }
  function createVehicle(vehicleData, isDuplicate = false) {
    if (isDuplicate) {
      if (
        !window.confirm(
          "Êtes-vous certain(e) de vouloir dupliquer ce véhicule ?"
        )
      ) {
        return false;
      }
      delete vehicleData.id;
      vehicleData.immat = "Copie de " + vehicleData.immat;
    }
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "vehicles", vehicleData)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLE,
          payload: res.data,
        });
        tools.filterAlert(
          "success",
          vehicleData.immat + " a été correctement créé."
        );
        if (props.modal) {
          props.selectVehicle(res.data);
        } else {
          props.history.push("/vehicles/" + res.data.id);
        }
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }

  function updateVehicle(vehicleData) {
    dispatch({ type: VEHICLE_TYPES.LOAD_VEHICLE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "vehicles/" + vehicleData.id, vehicleData)
      .then((res) => {
        dispatch({
          type: VEHICLE_TYPES.GET_VEHICLE,
          payload: res.data,
        });
        tools.filterAlert(
          "success",
          vehicleData.immat + " a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          VEHICLE_TYPES.LOAD_VEHICLE
        );
      });
  }

  return {
    createVehicle,
    updateVehicle,
    getVehicles,
    getVehicle,
  };
};
