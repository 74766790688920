import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { ERROR_TYPES } from "../reducers/errorReducers";
import { CATALOG_TYPES } from "../reducers/catalogReducers";

export const catalogActions = (state, dispatch, props) => {
  function getCatalogs(search = { search: null }) {
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      catalog: {
        searchString: search.search,
        brand: search.brand,
        brandModel: search.brandModel,
        finish: search.finish,
      },
      page: search.page,
    };

    axios
      .post(api_url + "catalogs/search", search)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOGS,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }
  function getCatalog(id) {
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "catalogs/" + id)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOG,
          payload: res.data,
        });
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }
  function createCatalog(catalogData, isDuplicate = false) {
    if (isDuplicate) {
      if (
        !window.confirm(
          "Êtes-vous certain(e) de vouloir dupliquer ce véhicule ?"
        )
      ) {
        return false;
      }
      delete catalogData.id;
      catalogData.immat = "Copie de " + catalogData.immat;
    }
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "catalogs", catalogData)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOG,
          payload: res.data,
        });
        tools.filterAlert("success", "Ce véhicule a été correctement créé.");
        if (props.modal) {
          props.selectCatalog(res.data);
        } else {
          props.history.push("/catalogs/" + res.data.id);
        }
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }

  function updateCatalog(catalogData) {
    dispatch({ type: CATALOG_TYPES.LOAD_CATALOG, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "catalogs/" + catalogData.id, catalogData)
      .then((res) => {
        dispatch({
          type: CATALOG_TYPES.GET_CATALOG,
          payload: res.data,
        });
        tools.filterAlert(
          "success",
          "Ce véhicule a été correctement mis à jour."
        );
      })
      .catch((err) => {
        tools.filterError(
          dispatch,
          err.response.data,
          CATALOG_TYPES.LOAD_CATALOG
        );
      });
  }

  return {
    createCatalog,
    updateCatalog,
    getCatalogs,
    getCatalog,
  };
};
