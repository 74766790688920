import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { TIRE_TYPES } from "../reducers/tireReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const tireActions = (state, dispatch, props) => {
  function getTires(search = "") {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "tires" + search)
      .then(res => {
        dispatch({ type: TIRE_TYPES.GET_TIRES, payload: res.data });
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function getTire(id) {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "tires/" + id)
      .then(res => {
        dispatch({
          type: TIRE_TYPES.GET_TIRE,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function createTire(tireData) {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "tires", tireData)
      .then(res => {
        dispatch({
          type: TIRE_TYPES.GET_TIRE,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          tireData.name + " a été correctement créé."
        );
        getTires();
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function updateTire(tireData) {
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "tires/" + tireData.id, tireData)
      .then(res => {
        tools.filterAlert(
          "success",
          tireData.name + " a été correctement mis à jour."
        );
        getTires();
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }
  function deleteTire(id) {
    if (
      !window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?")
    )
      return false;
    dispatch({ type: TIRE_TYPES.LOAD_TIRE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .delete(api_url + "tires/" + id)
      .then(res => {
        getTires();
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, TIRE_TYPES.LOAD_TIRE);
      });
  }

  return {
    createTire,
    updateTire,
    getTires,
    getTire,
    deleteTire
  };
};
