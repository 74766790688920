import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { userActions } from "../../../context/actions/userActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination";

const UserList = (props) => {
  const [state, dispatch] = useStore();
  const actions = userActions(state, dispatch, props);

  var userData = state.user.items;
  var constantData = state.constant.items;

  useEffect(() => {
    actions.getUsers();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Utilisateurs</h1>
          {state.user.isLoading ? (
            <Loader />
          ) : (
            <Link className="btn btn-primary" to="/users/create">
              <i className="fa fa-plus mr-2" />
              Créer un utilisateur
            </Link>
          )}
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((user, k) => (
                <tr key={"user-" + k}>
                  <td>{user.lastname}</td>
                  <td>{user.firstname}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>
                    {
                      constantData.ROLES.find((role) => user.role == role.id)
                        .name
                    }
                  </td>
                  <td>
                    <Link
                      className="btn btn-secondary mr-2"
                      to={"/users/" + user.id}
                    >
                      Modifier
                    </Link>
                    <button
                      className="btn btn-danger"
                      onClick={() => actions.deleteUser(user.id)}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserList;
