import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { catalogActions } from "../../../context/actions/catalogActions";

import CustomList from "../../common/CustomList";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import Loader from "../../common/Loader";
import Axios from "axios";
import { api_url } from "../../../config/config";
import { toast } from "react-toastify";
import { CATALOG_TYPES } from "../../../context/reducers/catalogReducers";
import Control from "../../common/Control";
import { brandActions } from "../../../context/actions/brandActions";
import { brandModelActions } from "../../../context/actions/brandModelActions";

const CatalogList = (props) => {
  const [state, dispatch] = useStore();

  var actions = catalogActions(state, dispatch);
  var b_actions = brandActions(state, dispatch);
  var bm_actions = brandModelActions(state, dispatch);

  var constantData = state.constant.items;
  const [specialSearch, setSpecialSearch] = useState({
    brand: "",
    brandModel: "",
    finish: "",
  });

  function hideItem(item, isHidden) {
    var it = JSON.parse(JSON.stringify(item));
    it.isHidden = isHidden;

    Axios.patch(api_url + "catalogs/" + it.id + "/visibility", {
      isHidden: isHidden,
    })
      .then((res) => {
        var cat = JSON.parse(JSON.stringify(state.catalog.items));
        cat = cat.map((c) => {
          if (c.id == it.id) {
            c.isHidden = isHidden;
          }
          return c;
        });

        dispatch({
          type: CATALOG_TYPES.GET_CATALOGS,
          payload: { data: cat, count: state.catalog.total },
        });
        //actions.getCatalogs();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Une erreur est survenue");
        if (err.response && err.response.data) {
          toast.error(err.response.data);
        }
      });
  }

  function removeCatalog(id) {
    if (window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?"))
      Axios.delete(api_url + "catalogs/" + id)
        .then((res) => {
          actions.getCatalogs();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            alert(err.response.data.other);
          }
        });
  }

  function changeSearch(e) {
    var { name, value } = e.target;
    var sh = JSON.parse(JSON.stringify(specialSearch));
    sh[name] = value;
    console.log(sh);
    setSpecialSearch(sh);
  }

  useEffect(() => {
    b_actions.getAllBrands();
  }, []);

  useEffect(() => {
    if (specialSearch.brand != "") {
      bm_actions.getBrandModelByBrand(specialSearch.brand);
    }
  }, [specialSearch.brand]);

  return (
    <CustomList getAction={actions.getCatalogs}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              {!props.modal && <h1>Catalogue</h1>}

              {state.catalog.isLoading ? (
                <Loader />
              ) : (
                !props.modal && (
                  <Link className="btn btn-primary" to="/catalogs/create">
                    <i className="fa fa-plus mr-2" /> Ajouter un véhicule au
                    catalogue
                  </Link>
                )
              )}
              <div className="row my-3">
                <div className="col-12 col-md-4">
                  <Control
                    label="Marque"
                    type="select"
                    name="brand"
                    value={specialSearch.brand}
                    change={changeSearch}
                    datas={state.brand.items.sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Control
                    label="Modèle"
                    type="select"
                    name="brandModel"
                    value={specialSearch.brandModel}
                    change={changeSearch}
                    datas={state.brandModel.items.sort((a, b) => {
                      if (a.name > b.name) return 1;
                      if (a.name < b.name) return -1;
                      return 0;
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <Control
                    label="Finition"
                    type="select"
                    name="finish"
                    value={specialSearch.finish}
                    change={changeSearch}
                    datas={
                      state.brandModel.items.find(
                        (bm) => bm.id == specialSearch.brandModel
                      )
                        ? state.brandModel.items
                            .find((bm) => bm.id == specialSearch.brandModel)
                            .finishes.sort((a, b) => {
                              if (a.name > b.name) return 1;
                              if (a.name < b.name) return -1;
                              return 0;
                            })
                        : []
                    }
                    dataIndex="_id"
                    dataLabel="name"
                  />
                </div>
                <div className="col d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-secondary mb-3"
                    onClick={() => actions.getCatalogs(specialSearch)}
                  >
                    Rechercher
                  </button>
                </div>
              </div>

              {/* <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              /> */}

              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Type</th>
                    <th>Finition</th>
                    <th>Options</th>
                    <th>Prix public</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.catalog.items.map((catalog, k) => {
                    // var displayPriceHT =
                    //   catalog.priceHT +
                    //   catalog.optionsNotIncluded.reduce(
                    //     (a, b) => a + b.priceHT * (b.isInclTVA ? 1.085 : 1),
                    //     0
                    //   ) +
                    //   catalog.greyCardCost +
                    //   catalog.inroadCost +
                    //   catalog.malusBonusEco;

                    let displayPriceTTC = catalog.priceTTC;
                    let displayPriceOptions = catalog.optionsNotIncluded.reduce(
                      (a, b) => a + b.priceHT * (b.isInclTVA ? 1.085 : 1),
                      0
                    );
                    displayPriceOptions =
                      Math.round(displayPriceOptions * 100) / 100;
                    // catalog.priceTTC +
                    // catalog.optionsNotIncluded.reduce(
                    //   (a, b) => a + b.priceHT * (b.isInclTVA ? 1.085 : 1),
                    //   0
                    // ) +
                    // catalog.greyCardCost +
                    // (catalog.inroadCost + 0.085 * catalog.inroadCost) +
                    // catalog.malusBonusEco;

                    return props.modal &&
                      catalog.isHidden &&
                      !state.auth.user.role == "SUPER_ADMIN" ? (
                      <tr
                        key={"brand-row-" + k}
                        style={{ display: "none" }}
                      ></tr>
                    ) : (
                      <tr
                        key={"brand-row-" + k}
                        style={catalog.isHidden ? { opacity: "0.7" } : {}}
                      >
                        <td>
                          {catalog.brand.name}{" "}
                          {catalog.isHidden ? "(caché)" : ""}
                        </td>
                        <td>{catalog.brandModel.name}</td>
                        <td>
                          {
                            constantData.VEHICLE_TYPES.find(
                              (vt) => vt.id == catalog.type
                            ).name
                          }
                        </td>
                        <td>
                          {
                            catalog.brandModel.finishes.find(
                              (f) => f._id == catalog.finish
                            ).name
                          }
                        </td>
                        <td>
                          {catalog.optionsNotIncluded.map((o, k) => (
                            <span
                              key={"options_" + catalog.id + "_" + k}
                              className="mr-2 badge badge-primary"
                            >
                              {o.name}
                            </span>
                          ))}
                        </td>
                        <td>
                          {/* HT : {catalog.priceHT} €
                          <br /> TTC : {catalog.priceTTC} €<br /> */}
                          {/* HT : {Math.round(displayPriceHT * 100) / 100} €<br /> */}
                          {Math.round(displayPriceTTC * 100) / 100} € <br />
                          {catalog.optionsNotIncluded.length > 0 &&
                            "Options: " + displayPriceOptions + " €"}
                        </td>
                        <td>
                          {!props.modal ? (
                            <>
                              <Link
                                className="btn btn-secondary"
                                to={"/catalogs/" + catalog.id}
                              >
                                Modifier
                              </Link>
                              {state.auth.user.role == "SUPER_ADMIN" ? (
                                <>
                                  {catalog.isHidden ? (
                                    <button
                                      className="btn btn-default btn-sm ml-2"
                                      onClick={() => hideItem(catalog, false)}
                                    >
                                      Visible
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-default btn-sm ml-2"
                                      onClick={() => hideItem(catalog, true)}
                                    >
                                      Masquer
                                    </button>
                                  )}
                                  <button
                                    title="Supprimer ce véhicule"
                                    className="btn btn-danger ml-2  btn-sm"
                                    onClick={() => removeCatalog(catalog.id)}
                                  >
                                    <i className="fa fa-times" />
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <a
                              href="#"
                              className="btn btn-secondary"
                              onClick={() => props.selectCatalog(catalog)}
                            >
                              Choisir
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <CustomPagination
                total={state.catalog.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />
            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default CatalogList;
