export const TIRE_TYPES = {
  LOAD_TIRE: "LOAD_TIRE",
  GET_TIRES: "GET_TIRES",
  GET_TIRE: "GET_TIRE",
  UPDATE_TIRE_DATA: "UPDATE_TIRE_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  search: "",
  item: {
    name: "",
    price: ""
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TIRE_TYPES.LOAD_TIRE:
      return {
        ...state,
        isLoading: action.payload
      };
    case TIRE_TYPES.GET_TIRES:
      return {
        ...state,
        items: action.payload.tires,
        item: initialState.item,
        total: action.payload.total,
        isLoading: false
      };

    case TIRE_TYPES.GET_TIRE:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };

    case TIRE_TYPES.UPDATE_TIRE_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
