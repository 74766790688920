import React from "react";
import { DateTime } from "luxon";

const ItemList = ({ items, fields = [] }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          {fields.map((it, k) => (
            <th key={"tlit" + k}>{it.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, k) => {
          var disp = [];
          fields.map((f, kk) => {
            var d = "";
            var it = item[f.name];
            if (f.type == "Date") {
              d = DateTime.fromJSDate(new Date(it)).toFormat("dd/MM/yyyy");
            } else {
              d = item[f.name];
            }
            disp.push(<td>{d}</td>);
          });

          return <tr key={"lgi" + k}>{disp}</tr>;
        })}
      </tbody>
    </table>
  );
};

export default ItemList;
