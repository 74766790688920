import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { contractActions } from "../../../context/actions/contractActions";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import { CONTRACT_TYPES } from "../../../context/reducers/contractReducers";
import tools from "../../../helpers/tools";
import VehicleModal from "../../common/VehicleModal";
import ClientModal from "../../common/ClientModal";
import InputContract from "./InputContract";
import Control from "../../common/Control";
import { constants } from "fs";
import ContractFinalValues from "./ContractFinalValues";

const ContractDetail = props => {
  const [state, dispatch] = useStore();

  var contractData = state.contract.item;
  var constantData = state.constant.items;
  var actions = contractActions(state, dispatch, props);

  function inputChange(e) {
    tools.inputChange(e, CONTRACT_TYPES.UPDATE_CONTRACT_DATA, dispatch);
  }

  useEffect(() => {
    actions.getContract(props.match.params.id);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>Edition d'un contrat / devis</h1>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ContractDetail);
