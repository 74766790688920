import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { CONSTANT_TYPES } from "../reducers/constantReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const constantActions = (state, dispatch) => {
  function getConstants() {
    dispatch({ type: CONSTANT_TYPES.LOAD_CONSTANT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "constants")
      .then(res => {
        dispatch({
          type: CONSTANT_TYPES.GET_CONSTANTS,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(dispatch, err.response.data, CONSTANT_TYPES.LOAD_CONSTANT)
      });
  }
  function updateConstant(constantData) {
    dispatch({ type: CONSTANT_TYPES.LOAD_CONSTANT, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "constants", constantData)
      .then(res => {
        dispatch({
          type: CONSTANT_TYPES.GET_CONSTANT,
          payload: res.data
        });
        tools.filterAlert(
          "success","Les constantes ont été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(dispatch,err.response.data,CONSTANT_TYPES.LOAD_CONSTANT);
      });
  }

  return {
    getConstants,
    updateConstant
  };
};
