export const CLIENT_TYPES = {
  LOAD_CLIENT: "LOAD_CLIENT",
  GET_CLIENTS: "GET_CLIENTS",
  GET_CLIENT: "GET_CLIENT",
  UPDATE_CLIENT_DATA: "UPDATE_CLIENT_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  search: "",
  item: {
    legalForm: "",
    name: "",
    lastname: "",
    firstname: "",
    address: "",
    address2: "",
    address3: "",
    postalCode: "",
    city: "",
    siret: "",
    email: "",
    phone: "",
    comment: "",
    eulerHermes: "",
    eulerHermesDate: new Date
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLIENT_TYPES.LOAD_CLIENT:
      return {
        ...state,
        isLoading: action.payload
      };
    case CLIENT_TYPES.GET_CLIENTS:
      return {
        ...state,
        items: action.payload.data,
        item: initialState.item,
        total: action.payload.count,
        isLoading: false
      };

    case CLIENT_TYPES.GET_CLIENT:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };

    case CLIENT_TYPES.UPDATE_CLIENT_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
