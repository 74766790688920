export const BRANDMODEL_TYPES = {
  LOAD_BRANDMODEL: "LOAD_BRANDMODEL",
  GET_BRANDMODELS: "GET_BRANDMODELS",
  GET_BRANDMODEL: "GET_BRANDMODEL",
  UPDATE_BRANDMODEL_DATA: "UPDATE_BRANDMODEL_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  total: 0,
  item: {
    name: "",
    brand: "",
    finishes: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case BRANDMODEL_TYPES.LOAD_BRANDMODEL:
      return {
        ...state,
        isLoading: action.payload
      };
    case BRANDMODEL_TYPES.GET_BRANDMODELS:
      return {
        ...state,
        items: action.payload.data,
        total: action.payload.count,
        item: initialState.item,
        isLoading: false
      };

    case BRANDMODEL_TYPES.GET_BRANDMODEL:
      return {
        ...state,
        item: action.payload,
        items: initialState.items,
        isLoading: false
      };

    case BRANDMODEL_TYPES.UPDATE_BRANDMODEL_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };

    default:
      return state;
  }
}
