import React from "react";
import { withRouter, Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import { metas } from "../../../config/metas";

const BreadCrumb = props => {
  const [state, dispatch] = useStore();
  var authData = state.auth;
  const actions = authActions(state, dispatch);

  var tags = [];
  getMetas();
  function getMetas() {
    var routes = props.location.pathname.split("/");

    routes.forEach((route, k) => {
      if (metas.find(meta => meta.pathname == route)) {
        var rte = metas.find(meta => meta.pathname == route);
        if (k + 1 < routes.length) {
          tags.push(
            <li key={"bc-" + k} className="breadcrumb-item">
              <Link to={"/" + rte.pathname}>{rte.title}</Link>
            </li>
          );
        } else {
          tags.push(
            <li key={"bc-" + k} className="breadcrumb-item active">
              {rte.title}
            </li>
          );
        }
      } else {
        if (route != "") {
          var rte = metas.find(meta => meta.pathname == routes[k - 1]);
          if (rte) {
            tags.push(
              <li key={"bc-" + k} className="breadcrumb-item active">
                {rte.child}
              </li>
            );
          }
        }
      }
    });
  }

  return (
    state.auth.isAuthenticated && (
      <div className="container-fluid mt-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Accueil</Link>
            </li>

            {tags}
          </ol>
        </nav>
      </div>
    )
  );
};

export default withRouter(BreadCrumb);
