import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { quotationActions } from "../../../context/actions/quotationActions";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import CustomPagination from "../../common/CustomPagination";
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";
import { api_url } from "../../../config/config";
import Axios from "axios";

const QuotationList = (props) => {
  const [state, dispatch] = useStore();
  const [selectId, setSelectId] = useState(null);

  var quotationData = state.quotation.items;
  var constantData = state.constant.items;

  var actions = quotationActions(state, dispatch, props);

  function removeQuotation(id) {
    if (window.confirm("Êtes-vous certain de vouloir supprimer cette entrée ?")) {

      console.log(id)
      Axios.delete(api_url + "quotations/" + id)
        .then(res => {
          actions.getQuotations()
        })
        .catch(err => {
          if (err.response && err.response.data) {
            alert(err.response.data.other)
          }
        })
    }
  }

  return (
    <CustomList getAction={actions.getQuotations}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Dossiers</h1>
              {state.quotation.isLoading ? (
                <Loader />
              ) : (
                  <Link className="btn btn-primary" to="/quotations/create">
                    <i className="fa fa-plus mr-2" /> Créer un dossier
                  </Link>
                )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Référence</th>
                    <th>Véhicule</th>
                    <th>Client</th>
                    <th>Nb de lignes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {quotationData.map((quotation, k) => {
                    return (
                      <tr key={"brand-row-" + k}>
                        <td>{quotation.reference}</td>

                        <td>
                          {quotation.quotations.map((q, kk) => (
                            <div key={"qn" + kk}>
                              <span
                                className={
                                  "mr-2 badge badge-" +
                                  constantData.QUOTATION_STATUS.find(
                                    (cs) => cs.id == q.status
                                  ).css
                                }
                              >
                                {
                                  constantData.QUOTATION_STATUS.find(
                                    (cs) => cs.id == q.status
                                  ).name
                                }
                              </span>
                              {q.vehicle.brand.name} {q.vehicle.brandModel.name}
                            </div>
                          ))}
                        </td>
                        <td>{quotation.client.name}</td>
                        <td>{quotation.quotations.length}</td>
                        <td className="d-flex">
                          <Link
                            className="btn btn-secondary mr-1"
                            to={"/quotations/" + quotation.id}
                          >
                            Modifier
                          </Link>
                          {state.auth.user.role == "SUPER_ADMIN" &&
                            <button title="Supprimer ce véhicule" className="btn btn-danger  btn-sm" onClick={() => removeQuotation(quotation.id)}>
                              <i className="fa fa-times" />
                            </button>
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <CustomPagination
                total={state.quotation.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />


            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default QuotationList;
