import axios from "axios";
import { api_url } from "../../config/config";
import tools from "../../helpers/tools";
import { MAINTENANCE_TYPES } from "../reducers/maintenanceReducers";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const maintenanceActions = (state, dispatch, props) => {
  function getMaintenances(search = {search: null}) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    search = {
      maintenance: {
        searchString: search.search
      },
      page: search.page
    }

    axios
      .post(api_url + "maintenances/search", search)
      .then(res => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCES,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function getMaintenancesFromVehicle(search = "") {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "maintenances" + search)
      .then(res => {
        if (res.data.length == 0) {
          console.log("NULLL");
          tools.filterError(
            dispatch,
            { other: "Aucun tableau de maintenance pour ce véhicule" },
            MAINTENANCE_TYPES.LOAD_MAINTENANCE
          );
        } else {
          dispatch({
            type: MAINTENANCE_TYPES.GET_MAINTENANCES_VEHICLE,
            payload: res.data
          });
        }
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function getMaintenance(id) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .get(api_url + "maintenances/" + id)
      .then(res => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCE,
          payload: res.data
        });
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function createMaintenance(maintenanceData) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .post(api_url + "maintenances", maintenanceData)
      .then(res => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCE,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          maintenanceData.name + " a été correctement créé."
        );
        props.history.push("/maintenances/" + res.data.id);
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }
  function updateMaintenance(maintenanceData) {
    dispatch({ type: MAINTENANCE_TYPES.LOAD_MAINTENANCE, payload: true });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: true });

    axios
      .patch(api_url + "maintenances/" + maintenanceData.id, maintenanceData)
      .then(res => {
        dispatch({
          type: MAINTENANCE_TYPES.GET_MAINTENANCE,
          payload: res.data
        });
        tools.filterAlert(
          "success",
          maintenanceData.name + " a été correctement mis à jour."
        );
      })
      .catch(err => {
        tools.filterError(
          dispatch,
          err.response.data,
          MAINTENANCE_TYPES.LOAD_MAINTENANCE
        );
      });
  }

  return {
    createMaintenance,
    updateMaintenance,
    getMaintenances,
    getMaintenance,
    getMaintenancesFromVehicle
  };
};
