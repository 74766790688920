import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { brandActions } from "../../../context/actions/brandActions";
import Loader from "../../common/Loader";
import CustomPagination from "../../common/CustomPagination"
import CustomSearch from "../../common/CustomSearch";
import CustomList from "../../common/CustomList";

const BrandList = (props) => {
  const [state, dispatch] = useStore();
  var actions = brandActions(state, dispatch);

  return (
    <CustomList getAction={actions.getBrands}>
      {(activePage, handlePageParamsChange, search) => (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Marques</h1>
              {state.brand.isLoading ? (
                <Loader />
              ) : (
                  <Link className="btn btn-primary" to="/brands/create">
                    <i className="fa fa-plus mr-2" /> Ajouter une marque
                  </Link>
                )}
              <CustomSearch
                search={search}
                handlePageParamsChange={handlePageParamsChange}
              />

              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {state.brand.items.map((brand, k) => (
                    <tr key={"brand-row-" + k}>
                      <td>{brand.name}</td>
                      <td>
                        <Link
                          className="btn btn-secondary"
                          to={"/brands/" + brand.id}
                        >
                          Modifier
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <CustomPagination
                total={state.brand.total}
                activePage={activePage}
                handlePageParamsChange={handlePageParamsChange}
              />

            </div>
          </div>
        </div>
      )}
    </CustomList>
  );
};

export default BrandList
